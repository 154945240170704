@import '@styles/default.less';
@import '../planning/children/by-date/styles.less';

/*
* Стили модуля Эмулятор прописаны в одном файле в корневой папке - src\modules\emulator\styles.less
* Используется основная обёртка - класс - ".ils-emulator"
* Все остальные классы находятся внутри этой обёртки.
*/

.ils-emulator {
  &-wrapper {
    display: flex;

    .ils-title {
      font-size: 20px;
    }

    .plan-date {
      margin-top: 50px;
      width: 20vw;
      display: flex;
      align-items: center;
      position: relative;
      justify-content: normal;
      flex-direction: column;
    }
  }

  &-aside {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    flex-grow: 1;
    padding-top: 20px;
  }

  &-main-table {
    width: 100%;
    height: calc((100vh - 120px) * 0.7);
    text-align: center;
  }

  &-controls {
    padding-top: 30px;
    width: 100%;
    height: calc((100vh - 120px) * 0.3);
    text-align: center;

    .ils-emulator-status {
      display: inline-block;
      width: 250px;
      margin-right: 10px;
    }
  }

  &-emulation-speed-select {
    width: 120px;
  }

  &-dates-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &-date-time {
    max-width: 70px;
  }

  &-top-wrapper {
    width: 80vw;
    height: calc((100vh - 120px) * 0.7);
    min-height: 500px;
    background-color: white;
    padding: 15px 15px 0;

    .wrapper-header {
      display: flex;
      flex-flow: row wrap;
    }

    .ils-title {
      width: 100%;
    }

    div.table-header {
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-between;
    }

    .table-config {
      width: 100%;

      .config .config-container {
        height: 100%;
      }
    }
  }
}

.ils-emulator-table-top-layout {
  .ant-table-tbody {
    .row {
      .ant-table-cell > .ant-empty {
        transform: translate(40%);
        width: 100%;
        min-width: 40vw;
      }
    }
  }
}

@primary-color: #4C91FF;