@import '@styles/default.less';

.ils-search-autocomplete {
  &.ant-select {
    .ant-select-selector {
      .ant-select-selection-placeholder {
        padding-left: 15px;
      }
    }
  }
}

@primary-color: #4C91FF;