@import '@styles/default.less';

.file-name {
  z-index: 2;
  left: 5px;
  font-size: 18px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
}

.back-button {
  display: block;
  margin: 15px auto 0;
}

.right-scroll-block {
  height: 650px;
}

@primary-color: #4C91FF;