@import '@styles/default.less';
@import '../../styles.less';

/*
* Стили подмодуля "Детализация событий" прописаны в одном файле в корневой папке
*  - src\modules\monitoring\children\event-details\styles.less 
* Используется основные обёртки - классы - ".ils-monitoring-details",
* ".ils-monitoring-main-wrapper", ".waypoint-icon", ".cluster-waypoint", ".route-info"
* Все остальные классы находятся внутри этих обёрток.
*/

@tab-name-color: #dcdcdc;
@marker-success-color: #008000;
@marker-wait-color: #c4c4c4;
@table-header-height: 75px;
@table-bottom-height: 20px;
@table-bottom-pagination-height: 16px;

.ils-monitoring-details {
  &-main-table {
    height: 100%;
    .catalog-item {
      overflow: visible;
    }
    .ant-spin-container {
      position: absolute !important;
    }
    .ant-pagination {
      flex-wrap: nowrap;
      position: sticky;
    }
    section.ils-table div.table-header {
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &-main-map {
    overflow-y: auto;
  }

  &-detail-table {
    height: 100%;

    .catalog-item {
      overflow: visible;
    }
    section.ils-table div.table-header {
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-between;
    }
    .ant-table-tbody {
      background-color: white;
    }
  }

  &-top-layout,
  &-gutter-horizontal {
    z-index: 1;

    .Dark {
      background-color: transparent !important;
    }
    .Dark:hover {
      background-color: @color-scroll !important;
    }
    height: calc(100vh - 115px);
    .ils-monitoring-map-layout{
      .ils-map-toolbar{
        
        z-index: 997 !important;
        position: absolute;
        top: 0;
        right: 5px;

        .map-btn-menu{
          background: #e8edf3;
          position: absolute;
          right: 0;
          z-index: 997 !important;
          min-width: 200px;
        }

      }
    }
  }


  &-gutter-vertical {
    z-index: 1;

    .Dark {
      background-color: transparent !important;
    }
    .Dark:hover {
      background-color: @color-scroll !important;
    }
  }

  &-top-layout {
    .config .config-container {
      position: relative;
      min-height: 250px;
      height: 50vh;
      margin-top: 10px;
    }
    .__dbk__child-wrapper {
      z-index: 1;
      &.main-table{
        z-index: 3;
      }
    }
  }

  &-tabs {
    width: 100%;
    background: @color-white;
    height: 280px;
  }

  &-tab {
    height: 98%;
    border-top: 1px solid @color-normal;

    .ant-tabs-nav {
      margin: 0 0 3px 0;
    }
    .ant-tabs-nav-wrap {
      padding-left: 10px;
    }
    .ant-tabs-tab {
      color: @tab-name-color;
    }
    .ant-tabs-content {
      height: 100%;

      .table-config {
        height: 100%;
      }
      .config .config-container {
        height: 100%;
        position: relative;
        min-height: 250px;
      }
    }
  }
}
// .ils-monitoring-main-wrapper .ils-monitoring-table-wrapper .ils-monitoring-details-main-table .ils-table  div.ant-table-wrapper.editable-table div.ant-spin-nested-loading div.ant-spin-container div.ant-table.ant-table-small.ant-table-bordered.ant-table-fixed-header.ant-table-has-fix-right div.ant-table-container

// html body div#root section.ant-layout.ant-layout-has-sider section.ant-layout.ils-wrapper main.ant-layout-content.ils-content div.ils-monitoring-details-top-layout.position-relative div.__dbk__container.Horizontal div.__dbk__child-wrapper div.ils-monitoring-main-wrapper div.ils-monitoring-table-wrapper div.__dbk__container.Vertical div.__dbk__child-wrapper.main-table section.table-config div.position-relative.h-100.catalog-item div.ils-monitoring-details-main-table.position-relative section.ils-table div.position-relative.table-resize-area div.ant-table-wrapper.editable-table div.ant-spin-nested-loading div.ant-spin-container ul.ant-pagination.mini.ant-table-pagination.ant-table-pagination-right.ils-pagination
.ils-monitoring-main-wrapper {
  height: 100%;

  .table-config {
    height: 100%;
  }
  .ils-monitoring-table-wrapper {
    height: calc(100% - 145px);
    padding-bottom: 25px;

    div.react-customscroll-frame {
      height: 100%;
      overflow-y: auto;

      .table-config {
        height: 100%;
      }
    }
  }

  .table-config {
    width: 100%;
    min-width: unset;
    position: relative;

    .ils-table {
      width: 100%;
      min-width: unset;
      padding: 0.5rem;
      height: calc(100% - @table-header-height);
      .table-resize-area {
        min-height: 30px;
        height: calc(100% - @table-bottom-height);
        .ils-pagination.ant-pagination{
          margin: @table-header-height 0 10px;
        }
      }
      .editable-table {
        height: 100%;

        .table-header {
          padding-bottom: 0.5rem;
        }
        .ant-spin-nested-loading {
          height: 100%;
          .ant-spin-container {
            height: 100%;
            width: 100%;
            .ant-table {
              height: 100%;
              .ant-table-container {
                height: calc(100% - @table-bottom-pagination-height);

                .ant-table-body{
                  min-height: unset;
                }
                .ant-table-header {
                  position: sticky;
                  top: 0;
                  z-index: 5;
                }
              }
            }
          }
        }
      }
    }
  }
}

.waypoint-icon {
  span {
    position: absolute;
    left: 0;
    top: -5px;
    border-radius: 25px;
    text-shadow: 0 0 5px white;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.inTime {
    svg {
      fill: @marker-success-color;
    }
    span {
      background-color: @marker-success-color;
    }
  }
  &.wait {
    svg {
      fill: @marker-wait-color;
      filter: drop-shadow(#000 1px 1px 2px);
    }
    span {
      background-color: @marker-wait-color;
    }
  }

  &.late {
    svg {
      fill: @color-warning;
    }
    span {
      background-color: @color-warning;
    }
  }

  &.undelivered {
    svg {
      fill: @color-error;
    }
    span {
      background-color: @color-error;
    }
  }
}
.cluster-waypoint {
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &.inTime {
    background-color: @marker-success-color;
    svg {
      fill: @marker-success-color;
    }
    span {
      background-color: @marker-success-color;
    }
  }
  &.wait {
    background-color:  @marker-wait-color;

    svg {
      fill: @marker-wait-color;
      filter: drop-shadow(#000 1px 1px 2px);
    }
    span {
      background-color: @marker-wait-color;
    }
  }

  &.late {
    background-color: @color-warning;

    svg {
      fill: @color-warning;
    }
    span {
      background-color: @color-warning;
    }
  }

  &.undelivered {
    background-color: @color-error;

    svg {
      fill: @color-error;
    }
    span {
      background-color: @color-error;
    }
  }
}

.route-info {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;

  li {
    span {
      margin-left: 5px;
    }
  }
}

@primary-color: #4C91FF;