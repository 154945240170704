@import '@styles/default.less';

.multiple-datepicker {
  .flex-layout-horizontal(start);
  .ils-input();

  &-input {
    .reset-full();
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    list-style: none;
    position: relative;
    display: inline-flex;
    align-items: center;
    background: transparent;
    border: none;
    outline: none;

    &:focus {
      outline: none;
      box-shadow: none;
      border: none;
    }

  }
}

@primary-color: #4C91FF;