@import '@styles/default.less';

@widget-footer-height: 30px;
@resizer-size: 5px;
@resizer-bg: rgba(1, 1, 1, 0.2);
@selected-item-color: #e8edf3;

.analytics-widget-resizer() {
  background: @resizer-bg;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  position: absolute;
}
.analytics-widget-resizer-side(@left) {
  width: @resizer-size;
  top: 50%;
  transform: translate(0, -50%);
  height: 20px;
  cursor: col-resize;
  left: @left;
}

.analytics-widget-resizer-vertical(@top) {
  height: @resizer-size;
  left: 50%;
  transform: translate(-50%, 0);
  width: 20px;
  cursor: row-resize;
  top: @top;
}

.analytics {
  &-under-construct {
    margin-top: 100px;

    p {
      font-size: 12pt;
    }
  }
  &-scroll {
    scrollbar-color: @color-scroll @color-white;

    &::-webkit-scrollbar {
      width: 6px;
      height: 8px;
      background-color: @color-white;
    }

    &::-webkit-scrollbar-thumb {
      background: @color-scroll;
    }
  }

  &-top-layout,
  &-gutter-horizontal {
    .Dark {
      background-color: @color-normal;
      opacity: 0.5;
    }

    .Dark:hover {
      background-color: @color-normal;
    }

    height: calc(85vh - 100px) !important;
  }

  &-widget-board {
    &.droppable {
      background: #e0ecff;
      border-radius: 2px;
    }
  }

  &-widget-border {
    border: 1px solid #e8edf3;
    border-radius: 2px;
  }
  &-widget-filter {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    label {
      padding-top: 7px;
    }
    .ant-select {
      min-width: 150px;
      padding-bottom: 2px;
    }
  }

  &-board-date {
    &-select {
      display: flex;
      align-items: center;
      gap: 20px;

      & .ant-select {
        width: 160px;
      }
      & .ant-picker  {
        width: 240px;
      }
    }
  }

  &-table-summary {
    background: #f8f9fa;
    color: #232323;

    &-td {
      width: 100%;
    }

    & .chart-tooltip {
      padding: 10px 5px;
      .date {
        font-size: 14px;
        font-weight: bold;
      }

      .value {
        margin-top: 15px;
        color: #4c91ff;

        font-size: 14px;
      }
    }

    td {
      border: none !important;
      padding: 0 13px !important;

      &:first-child {
        text-align: end;
      }
    }

    .diagram-td {
      padding: 20px 5px !important;
      border: 1px solid #f0f0f0 !important;
      border-right: none !important;
      overflow-x: scroll;

      span {
        display: block;
        text-align: center;
      }

      &:nth-last-child(-n + 2) {
        border-right: 1px solid #f0f0f0 !important;
      }

      &-range {
        margin-top: 10px;
      }
    }
  }

  &-list {
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
      padding-right: 10px;

      .edit {
        width: 24px;
        height: 24px;

        background: #ffffff;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
        border-radius: 2px;

        .svg-container {
          width: 14px;
          height: 14px;
          fill: #4c91ff;
        }
      }
    }

    &-item {
      &:first-child {
        border-top: 1px solid @color-normal;
      }

      &:hover {
        cursor: pointer;
        background: @color-normal;
      }

      &:not(:last-child) {
        border-bottom: 1px solid @color-normal;
      }

      &-value {
        padding: 0 5px;
      }
    }
  }

  &-metrics-widget {
    display: flex;
    background: #ffffff;
    border: 1px solid #e8edf3;
    border-radius: 2px;
    padding: 8px;
    width: fit-content;

    &-info {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding: 8px 8px 0 8px;
      position: relative;

      &:not(:last-child) {
        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          width: 1px;
          background: #e8edf3;
        }
      }

      &-value {
        color: #232323;
        font-size: 16px;
        font-weight: bold;
        margin: 0;

        &.active {
          color: #4c91ff;
        }
      }

      &-text {
        color: #bdbdbd;
        font-size: 12px;
      }
    }
  }

  &-selected-item {
    background: @selected-item-color;
  }

  &-window {
    top: 20px;

    & .ant-modal-body {
      padding: 4px !important;
    }
  }

  &-chart {
    padding: 0 20px 20px 20px;
    border: 1px solid #e8edf3;
    border-radius: 2px;
  }

  &-window-wrapper {
    height: calc(100vh - 50px);
    width: calc(100vw - 250px);
    left: 250px;
    overflow: hidden;
  }

  &-board-loading {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-board-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    display: inline;
    border-radius: 2px;
    padding: 8px;
  }

  &-board-header {
    padding: 10px 2px 10px 2px;
  }

  &-board-set-title {
    font-weight: 600;
    font-size: @button-icon-width;
    line-height: 40px;
  }

  &-board-subtitle {
    margin-left: 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  &-list {
    &-item {
      &:first-child {
        border-top: 1px solid @color-normal;
      }

      &:hover {
        cursor: pointer;
        background: @color-normal;
      }

      &:not(:last-child) {
        border-bottom: 1px solid @color-normal;
      }

      &-value {
        padding: 0 5px;
      }
    }
  }

  &-empty-chart {
    &-nodata {
      padding: 0 20px 20px 20px;
    }
  }

  &-board-sets {
    overflow-y: auto;
    max-height: 400px;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;

      .edit {
        width: 24px;
        height: 24px;

        background: #ffffff;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
        border-radius: 2px;

        .svg-container {
          width: 14px;
          height: 14px;
          fill: #4c91ff;
        }
      }
    }
  }

  &-form-data-widget {
    background: #fff;
    padding: 0 5px 0 0;

    &-input {
      margin-bottom: 16px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      label {
        font-size: 14px;
        font-weight: 500;
      }

      &-hidden {
        max-width: 200px;
      }

      input {
        padding: 8px 16px;
        border: 1px solid #e8edf3;
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        font-size: 14px;
        transition: all 0.1s;

        &:hover {
          border-color: #75afff;
          border-right-width: 1px;
        }

        &:focus {
          border-color: #75afff;
          box-shadow: 0 0 0 2px rgba(76, 145, 255, 0.2);
          border-right-width: 1px;
          outline: 0;
        }
      }
    }

    &-textfield {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      .label {
        color: #232323;
        font-size: 14px;
        font-weight: 500;
      }

      & .value {
        padding: 8px 16px;
        border: 1px solid #e8edf3;
        border-radius: 2px;
        font-size: 14px;
        min-height: 2.857em;
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }

  &-info-widget {
    background: #ffffff;
    border: 1px solid #e8edf3;
    border-radius: 2px;
    padding: 16px;
    transition: all 0.1s;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 3px;
    cursor: pointer;
    max-width: 170px;

    &-inactive {
      pointer-events: none;
    }

    .value {
      color: #4c91ff;
      font-size: 16px;
    }

    .title {
      color: #232323;
      font-size: 14px;
      text-align: center;
    }

    &:hover {
      background: #e8edf3;
    }
  }

  &-info-array-widget {
    display: flex;
    flex-direction: row;
    overflow: auto;

    &-item {
      margin-right: 10px;
    }
  }

  &-widget {
    &-drag {
      display: block !important;
      height: 100%;
      width: 100%;
    }

    &-wrapper {
      overflow: auto;
      position: absolute;

      &-stretchable {
        overflow: visible !important;
      }

      &-loading {
        overflow: hidden !important;
      }
    }

    &-layout {
      &-container {
        height: 100%;
        width: 100%;
        border: dotted 1px;
        overflow: hidden;
      }

      &-text {
        height: 100%;
        width: 100%;
        border: dotted 1px;
        overflow: hidden;
        background-color: @color-normal;
        display: flex;
        justify-content: space-between;
        padding: 0 3px 0 3px;
        align-items: center;
      }

      &-body {
        background-color: @color-normal;
        height: calc(100% - @widget-footer-height);
        width: 100%;
        display: block;
      }

      &-plus {
        width: 30px;
        height: 30px;
        background-color: @color-white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &-footer {
        padding: 3px;
        height: @widget-footer-height;
        width: 100%;
        overflow: hidden;
        background-color: @color-white;
      }
    }

    &-intersect {
      background-color: @color-error;
    }

    &-board {
      height: calc(100vh - 205px);
      overflow: hidden;
      position: relative;
      background-color: @color-white;

      &-stretchable {
        overflow-y: scroll;
      }
    }

    &-edit {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: @color-white;
      border: 1px dotted;
    }

    &-edit {
      &-body {
        width: 100%;
        height: 100%;
        position: relative;
        background-color: @color-white;
      }

      &-component {
        padding: 5px;
        height: 100%;
        width: 100%;
      }

      &-component:hover {
        cursor: move;
      }
    }

    &-delete {
      background: #e8edf3;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      position: absolute;
      right: 10px;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      cursor: pointer;
    }

    &-right-resizer {
      .analytics-widget-resizer();
      .analytics-widget-resizer-side(calc(100% - @resizer-size));
    }
    &-left-resizer {
      .analytics-widget-resizer();
      .analytics-widget-resizer-side(0);
    }
    &-top-resizer {
      .analytics-widget-resizer();
      .analytics-widget-resizer-vertical(0);
    }
    &-bottom-resizer {
      .analytics-widget-resizer();
      .analytics-widget-resizer-vertical(calc(100% - @resizer-size));
    }
  }

  &-date {
    &-select {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 20px;
      margin-top: 10px;

      &-picker {
        width: 100%;
      }

      &-buttons {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        gap: 8px;

        &:first-child {
          order: 0;
        }
      }

      & .ant-picker .ant-picker-suffix {
        left: 8px;
        right: 0;
        top: 8px;
      }
    }
  }

  &-sets-board-edit {
    &-offset {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: #414d55;
      opacity: 0.6;
      z-index: 1000;
    }

    &-dialog {
      .list,
      .copy-list {
        max-height: 30vh;
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 3px;
      }

      .copy-list {
        max-height: calc(40vh - 150px);
      }

      .board {
        position: relative;
        background: #fff;
        width: 350px;
        min-height: 100px;
        z-index: 9999;

        &.sets {
          padding: 0 16px;
          margin-bottom: 10px;
        }

        &.new-set {
          padding: 0 16px;
          margin-bottom: 10px;
        }

        &.copy-set {
          padding: 0 16px;
        }
      }

      &.disabled {
        z-index: 1;
      }

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        padding-top: 10px;

        .close {
          background: none;
          margin: 0;
          padding: 0;
          outline: none;
          border: none;
          color: #232323;
          font-size: 20px;
          cursor: pointer;
        }
      }

      .list-item {
        display: flex;
        align-items: center;
        position: relative;

        &:not(:first-child) {
          border-top: 1px solid #f0f0f0;
        }

        padding: 12px 0;

        &.droppable::before {
          position: absolute;

          content: '';
          left: 0;
          right: 0;
          top: 0;
          height: 2px;
          background: #4c91ff;
        }

        &.dropping {
          background: #4c91ff3c;
        }

        .name {
          margin-left: 8px;

          width: auto;
          text-overflow: ellipsis;

          white-space: nowrap;
          overflow: hidden;

          &.disabled {
            opacity: 0.6;
          }
        }

        .edit-name-input {
          margin: 0 10px;
          padding: 0;
        }

        .btn-choose {
          margin-left: auto;
          background: #e8edf3;
          border-radius: 2px;
          padding: 0 6px;
          font-size: 12px;
          border: none;
          cursor: pointer;

          &.copy:focus {
            color: inherit !important;
            border: none;
          }

          &.primary {
            background: #4c91ff;
            color: #fff;
          }
        }

        .actions {
          margin-left: auto;
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .btn-action {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #e8edf3;
          border-radius: 2px;
          cursor: pointer;

          &.active {
            border: 1px solid #4c91ff;
          }

          &.disabled {
            background: transparent;
            border: 1px solid #e8edf3;

            svg {
              opacity: 0.5;
            }
          }

          &.move {
            cursor: move;
          }

          svg {
            width: 14px;
            height: 14px;
            fill: #4c91ff;
          }
        }

        .plus {
          width: 24px;
          height: 24px;
          background: #e8edf3;
          border-radius: 2px;
          color: #4c91ff;
          cursor: pointer;

          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          margin-right: 8px;
        }

        .arrow-right {
          border: solid #232323;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 3px;
          cursor: pointer;

          transform: rotate(-45deg);
          margin-left: auto;
          margin-right: 3px;
          transition: all 0.1s;

          &.active {
            transform: rotate(45deg);
          }
        }
      }
    }
  }

  &-cell {
    .error-icon,
    .icon-cell {
      width: 18px;
      height: 18px;
      margin: 0 auto;

      div,
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  &-table {
    &-container {
      overflow: auto;

      .ils-table .ant-table-body {
        min-height: fit-content;
      }
    }

    &-subheader {
      border-radius: 2px;
      max-width: 470px !important;
      width: 100%;
      padding: 8px 8px 0 16px;
      margin-bottom: 0;

      &-warning {
        background: #f6bc0c8d;
        color: #414d55;
        border: 1px solid #232323;
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
      }

      &-error {
        background: #fff;
        color: #d24747;
        border: 1px solid #d24747;
      }
    }
  }

  &-widget-catalog {
    position: fixed;
    left: 0;
    top: 0;
    width: 273px;
    bottom: 0;
    background: #fff;
    z-index: 2000;
    padding: 16px;
    animation: from-left 0.3s;

    &-spinner {
      margin: 20px auto 0;
      width: 100%;
    }

    &-tabs {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #e8edf3;
      border-bottom: 1px solid #e8edf3;
      padding: 8px 0;
      margin: 8px 0;

      &-item {
        background: #ffffff;
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        width: 70px;
        height: 40px;

        .svg-container {
          width: 16px;
          height: 16px;
          fill: #232323;
        }

        &.active {
          .svg-container {
            fill: #4c91ff;
          }
        }
      }
    }

    &-title {
      color: #232323;
      font-size: 20px;
      position: relative;
    }

    &-list {
      overflow: auto;
      height: 100%;
      padding-bottom: 145px;

      &-item {
        &:not(:first-child) {
          padding-top: 8px;
        }

        user-select: none;

        &:active {
          cursor: grabbing;
        }

        &:not(:last-child) {
          border-bottom: 1px solid #e8edf3;
          padding-bottom: 6px;
        }

        &-img {
          width: 100%;
          height: 150px;
          user-select: none;
          background: #e8edf3;
          padding: 10px;
          position: relative;
          cursor: grab;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            background: #fff;
            opacity: 0;
          }

          .preview {
            width: 100%;
            height: 100%;
          }
        }

        &-name {
          color: #232323;
          font-size: 16px;
          margin-top: 8px;
        }
      }
    }
  }
  &-pdf-modal {
    max-height: 70vh;
    overflow-y: scroll;
  }
}

@keyframes from-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
