// --- RESET ---
.margin-center(@margin) {
  margin: @margin auto;
}

.margin-reset() {
  margin: 0;
}

.reset-full() {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
}

// MAIN
.content-section-padding() {
  padding: @size-around-content 0;
}

.overflow-ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// --- GRID ---
.grid(@gap: @gap-em) {
  display: grid;
  gap: @gap;
}

.grid-fill_4_columns(@gap: @gap-em) {
  .grid(@gap);
  grid-template-columns: max-content max-content max-content auto;
}

// --- FLEX ---
.flex-horizontal(@gap: 0) {
  display: flex;
  gap: @gap;
}

.flex-vertical(@gap: 0) {
  .flex-horizontal(@gap);
  flex-direction: column;
}

.justify(@justify: center) {
  justify-content: @justify;
}

.flex-justify-align(@justify: center, @align: center, @gap:0) {
  .flex-horizontal(@gap);
  .justify(@justify);
  align-items: @align;
}

.flex-layout-horizontal(@justify: center, @align: center, @gap: @gap-primary) {
  .flex-horizontal(@gap);
  .flex-justify-align(@justify, @align, @gap);
}

.flex-layout-vertical(@justify: center, @align: center, @gap: @gap-primary) {
  .flex-vertical(@gap);
  .flex-justify-align(@justify, @align, @gap);
}

.flex-justify-align(@justify, @align) {
  display: flex;
  justify-content: @justify;
  align-items: @align;
}

.absolute-center() {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}

// --- FORM ---
.disabled-form-info(@input-width: 100%) {
  input,
  .ant-input,
  .ant-picker,
  .ant-select,
  .ant-select-selector,
  .ant-input-number,
  .ant-radio-button-wrapper-disabled,
  .ant-checkbox + span,
  .ant-checkbox .ant-checkbox-input,
  .ant-select .ant-select-selector,
  .ant-mentions > textarea,
  .ant-radio-button-wrapper,
  .ant-select-disabled.ant-select-multiple .ant-select-selection-item,
  .ant-select-multiple .ant-select-selection-item-content {
    width: @input-width;
    background: @color-white;

    span,
    &:disabled,
    &-disabled,
    &[disabled],
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-select-selector,
    div.ant-select-selector,
    .ant-select-show-search,
    .ant-radio-group,
    .ant-select-disabled.ant-select:not(.ant-select-customize-input),
    .ant-select:not(.ant-select-customize-input),
    .ant-input-group-addon,
    &-disabled.ant-select-multiple .ant-select-selection-item {
      color: @color-black !important;
      cursor: default;
      background: @bg-input-disabled-info !important;

      &[checked],
      &-checked,
      &.ant-radio-button-wrapper-checked {
        background: @bg-primary !important;
      }
    }
  }
  .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
    background: @bg-primary !important;
  }
}

.fieldset() {
  border: 1px solid @border-color-gray;
  padding: 10px;
  border-radius: 5px;
}

.ils-input() {
  background: @color-white;
  padding: 4px;
  border: 1px solid @border-color-input;
  border-radius: 2px;
  outline: none;
  &:focus {
    box-shadow: 0 0 0 2px rgb(76 145 255 / 20%);
  }
}

// --- MAP ---
.leaflet-control() {
  position: absolute;
  bottom: 0;
  padding: 0 5px;
  font-size: 11px;
  color: #333;
  line-height: 16.5px;
  font-style: normal;
  background: rgba(255, 255, 255, 0.7);
}

// --- ILS STUFF ---
.counter() {
  color: @color-primary;
  font-size: @fz-big;
  font-weight: @fw-500;
}

.ils-title() {
  .margin-reset();
  padding: 0;
  width: fit-content;
  font-weight: @fw-500;
}

.ils-tooltip() {
  background-color: @bg-component;
  color: @color-black;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}

// --- SCROLL ---
.ils-scroll-styles() {
  scrollbar-color: @color-scroll @color-white;

  &::-webkit-scrollbar {
    width: 6px;
    height: 8px;
    background-color: @color-white;
  }

  &::-webkit-scrollbar-thumb {
    background: @color-scroll;
  }
}

.ils-content-scroll(@x: scroll, @y: hidden) {
  overflow-x: @x;
  overflow-y: @y;
  scrollbar-width: thin;
  scrollbar-color: @color-scroll @color-white;
  margin: 0;
  cursor: pointer;
  &::-webkit-scrollbar {
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    background-color: @color-white;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px @color-scroll;
  }
}

// ICONS
.icon-square(@size: 20px) {
  width: @size;
  height: @size;
}

// for responsive table with full-screen and scroll inside body
//NOTE: применять внутри к стилю конфига,
// переданного как configClassName совместно с ограничением размера контейнера, в котором лежит таблица
// не подходит для таблиц с пагинацией в чистом виде
.responsive-table-with-scrolling() {
  &.table-config {
    height: 100%;
    overflow: hidden;

    & > div {
      height: 100%;
    }
  }

  .ils-table,
  .ant-table-wrapper,
  .ant-spin-nested-loading,
  .ant-spin-container,
  .ant-table,
  .ant-table-container {
    height: 100%;
  }

  .position-relative.table-resize-area {
    height: calc(100% - @table-header-height);
  }

  .ant-table-container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .ant-table-body {
    overflow: auto scroll;
    min-height: fit-content;
    max-height: calc(100% - 50 - 71) !important;
  }
}

