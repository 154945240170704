@import '@styles/default.less';

.config {
  width: fit-content !important;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 500px);
  min-height: 250px;

  &-buttons {
    .flex-layout-vertical();
  }

  .ddcontainer {
    display: block !important;
  }

  .grabber {
    width: 24px;
    height: 24px;
    border: none;
    background: transparent;
    opacity: 0.5;
  }

}

@primary-color: #4C91FF;