@import '@styles/default.less';

.auth-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  &__link {
    background: @color-white;
    border: @border-primary;
    .ant-page-header-ghost {
      padding: 4px 16px;
    }
    span {
      margin: 0;
    }
    &_active {
      span {
        color: @color-primary;
      }
    }
  }
}


@primary-color: #4C91FF;