@import '@styles/default.less';

.disabled-btn, .disabled-btn:hover, .disabled-btn:focus, .disabled-btn:active {
  color: rgba(0, 0, 0, 0.25) !important;
  border-color: #d9d9d9 !important;
  background: #f5f5f5 !important;
  text-shadow: none !important;
  box-shadow: none !important;
}

.disabled-btn {
  cursor: not-allowed !important;
}

.ils-button {
  &.ant-btn {
    &-icon-only {
      padding: @paddings-general;
      min-width: @button-icon-width;
    }
  }
}
@primary-color: #4C91FF;