@import '@styles/default.less';

.payment-container {
  .current-balance {
    .flex-horizontal(@gap-primary);
    align-items: center;
  }
  h1.current-balance-title {
    margin: 0;
    line-height: 16px;
    font-size: 14px;
    font-weight: @fw-500;
  }
}
.invoice {
  &-viewer-container {
    min-height: 80vh;
    width: 60vw;
    padding-right: 34px;

    &-spinner {
      width: 100%;
    }
  }
  &-form-action-buttons {
    .flex-horizontal(@gap-em);
    justify-content: flex-end;
  }
}

.payment-balance-history {
  .flex-vertical();
  align-items: stretch;

  &-rows {
    .flex-vertical();
    border: 1px solid @color-lightgrey;
  }

  &-header,
  &-row {
    .flex-horizontal();
  }
  &-row {
    border-bottom: 1px solid @color-lightgrey;
    &:last-of-type {
      border-bottom: 0;
    }
  }
  &-header {
    background: @bg-body;
    color: @color-darkgrey;
    font-weight: 600;
    border-left: 1px solid @color-lightgrey;
    border-right: 1px solid @color-lightgrey;
    border-top: 1px solid @color-lightgrey;
  }
  &-cell {
    min-width: 230px;
    padding: @gap-primary @gap-em;
    border-right: 1px solid @color-lightgrey;
    &:last-of-type {
      border-right: 0;
    }
    &_fill-account,
    &_write-off,
    &_number {
      font-weight: 500;
    }
    &_fill-account {
      color: #339439;
    }
    &_write-off {
      color: @color-error;
    }
  }
}


@primary-color: #4C91FF;