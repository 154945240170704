.buttons-container {
  position: absolute;
  top: 0;
  right: -50px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.modal-body {
  min-width: unset;
}

.button {
  height: 40px;
  width: 40px;
  padding: 14px;
  background: #fff;
  cursor: pointer;

  & div {
    line-height: 0;

    & svg polygon {
      fill: #000c17;
    }
  }
}

@primary-color: #4C91FF;