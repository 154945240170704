@import '@styles/default.less';

.container {
  max-width: 300px;
  width: 300px;

  .no-data {
    text-align: center;
    margin: 10px 0;
  }

  .current-contractor {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 0;
    border-bottom: 1px solid #cecece;
    padding-bottom: 15px;
  }

  .contractors-group {
    gap: 10px;
  }

  .search-contractor {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    gap: 15px;

    & * {
      margin: 0;
    }

    &__input {
      width: 100%;
    }

    .contractor-search {
      width: 40px;
      svg {
        width: 20px;
        height: 24px;
        fill: @color-primary;
      }
    }
  }

  .contracts-list {
    max-height: 240px;
    overflow: scroll;
    margin-bottom: 15px;

    .ant-list-item {
      .contracts__name {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

.select-item {
  width: 100% !important;
}

@primary-color: #4C91FF;