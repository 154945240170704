.buttons-container {
  position: absolute;
  top: 0;
  right: -50px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.modal-body {
  min-width: unset;
  height: 100%;
  max-height: 85vh;
}

.form-container {
  position: absolute;
  top: 0;
  width: calc(15vw - 10px);
  left: -15vw;
  padding: 16px;
  background: #ffffff;
}

.button {
  height: 40px;
  width: 40px;
  padding: 14px;
  background: #fff;
  cursor: pointer;

  & div {
    line-height: 0;

    & svg polygon {
      fill: #000c17;
    }
  }
}

.no-linked-doc {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  height: 50vh;
}

.view-container {
  max-height: 90vh;
  overflow: hidden;
}

.select-container {
  position: absolute;
  top: 0;
  width: calc(15vw - 10px);
  left: -15vw;
  background: #ffffff;
}

.select-container > * {
  cursor: pointer;
  padding: 8px;
  font-weight: 500;
}

.doc {
  &.current {
    background: #e0ecff;
  }
}

@primary-color: #4C91FF;