@import '@styles/default.less';
@import '../../styles.less';

/*
* Стили подмодуля "Дашбоард" прописаны в одном файле в корневой папке 
* - src\modules\monitoring\children\dashboard\styles.less 
* Используется основные обёртки - классы - ".ils-monitoring-dashboard",
* ".ils-monitoring",".monitoring-dashboard-progress"
* Все остальные классы находятся внутри этих обёрток.
*/
@table-overflow: ~'(max-height: 920px)';

.ils-monitoring-dashboard {
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &-sub-menu {
    z-index: calc(@z-index-plan-context-menu-under-drop-container + 1);
  }

  .ils-monitoring-table {
    overflow-y: visible;
    min-height: 350px;

    .table {
      height: 100%;
      padding: 10px;
      display: flex;
      flex-direction: column;

      .ant-table-wrapper {
        width: 100%;

        tr {
          min-height: 40px;
          height: 40px;
          max-height: 40px;
        }
      }

      .ils-monitoring-chart,
      .ils-monitoring-graph {
        height: 200px;
      }

      .ils-monitoring-dashboard-table-wrapper {
        position: relative;
        background-color: @color-white;
        overflow: visible;

        &.small {
          @media @table-overflow {
            overflow-y: scroll;
          }
        }

        section.table-config {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          section.ils-table {
            height: calc(100%);
            display: flex;
            flex-direction: column;
            overflow: auto;

            div.table-header {
              .table-header-buttons {
                flex-wrap: nowrap;
              }

              .table-header-top {
                .ils-monitoring-viewed {
                  &-switcher {
                    width: 110px;

                    button {
                      min-height: unset;
                      height: 24px;

                      .ant-switch-handle {
                        width: 18px;
                        height: 18px;
                      }
                    }
                  }
                }
              }

              flex-wrap: nowrap;
              flex-direction: row;
              justify-content: space-between;
            }

            .table-resize-area {
              overflow-y: hidden;

              .ant-table-thead {
                top: 1px;
                outline: 1px solid #f0f0f0;
              }
            }
          }
          .ils-monitoring-table-top-layout.served,
          .ils-monitoring-table-top-layout.ontime {
            height: 100%;
            section.ils-table {
              height: calc(100% - 5px);
            }
          }
        }

        .config .config-container {
          height: 100%;
          position: relative;
          min-height: 300px;
        }
      }

      .ils-monitoring-dashboard-table-statuses {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .ils-delivery-status {
      &-cell {
        display: flex;
        align-items: center;
        gap: 24px;
        overflow: hidden;
      }

      &-info {
        display: flex;

        gap: 8px;
      }

      &-square {
        width: 20px;
        cursor: pointer;
        height: 20px;
        border-radius: 2px;
        justify-content: center;
        align-items: center;
        display: flex;
      }
    }
  }

  .ant-progress {
    width: 50%;
  }

  .delivery {
    max-width: 40%;
    position: relative;
    width: 40%;

    .scroll {
      height: 40px;
      width: 100%;
      background-color: @bg-body;
      position: sticky;
      top: 0;
      z-index: @z-index-scroll;
      padding: 13px 10px 13px 0;

      .scroll-area {
        overflow-x: scroll;
        scrollbar-width: thin;
        scrollbar-color: @color-scroll @color-normal;

        > div {
          width: 100%;
          height: 2px;
        }
      }

      .scroll-area::-webkit-scrollbar-thumb {
        background: @color-scroll;
      }

      .scroll-area::-webkit-scrollbar {
        width: 0;
        height: 8px;
        background: @color-white;
      }
    }

    .deliveries {
      padding-right: 10px;
      overflow-x: scroll;
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      box-sizing: content-box;
      scrollbar-color: transparent transparent;

      .blocks {
        width: max-content;
      }
    }

    .deliveries::-webkit-scrollbar-thumb {
      background: transparent;
    }

    .deliveries::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    .row {
      height: 40px;
      min-height: 40px;

      span {
        margin: 10px;
      }
    }
  }

  .chart-container {
    .chart-inner {
      max-height: 25vh;
      overflow-y: auto;
    }

    .chart-row {
      background-color: @color-white;

      .chart-label {
        width: 180px;
        text-align: center;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
        padding-top: 15px;
      }

      .chart-area {
        width: calc(100% - 180px);

        .over-layer {
          width: calc(100% - 180px);
          position: absolute;
          display: block;
          top: 0;
        }

        .transparent {
          opacity: 0.5;
        }
      }
    }

    .vertical-line {
      width: 2px;
      display: block;
      position: absolute;
      top: 0;
      border: 2px solid @color-black;
    }

    .annotation {
      width: 160px;
    }
  }

  .chart-header .ticks {
    padding-left: 180px;
  }

  &-started-vehicles {
    font-size: 24px;
    color: #232323;
    font-weight: 600;
  }
}

.ils-monitoring {
  &-tabs {
    width: 665px;

    background: #fff;
    border-radius: 2px;

    display: flex;
    padding: 8px;
  }

  &-tab {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
    transition: all 0.1s;
    cursor: pointer;

    &:hover {
      border: 1px solid #4c91ff;
      border-radius: 2px;
    }

    &:first-child {
      width: 150px;
    }

    &:not(:last-child) {
      &::after {
        content: '';

        position: absolute;

        top: 0;
        bottom: 0;
        right: 0;
        width: 1px;

        background: #ececec;
        border-radius: 2px;
      }
    }

    & .header {
      font-size: 16px;
      color: #232323;
      font-weight: 600;
    }

    & .sub {
      font-size: 10px;
      font-weight: 500;
      color: #bdbdbd;
      text-align: center;
    }
  }

  &-chart-context-menu {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
}

.ils-monitoring {
  &-metrics {
    &-tabs {
      max-width: 710px;
      width: 100%;

      background: #fff;
      border-radius: 2px;

      display: grid;
      grid-template-columns: 1.5fr 1fr 1fr 1fr 1.5fr 0.9fr 1fr;

      padding: 8px 10px;
      margin-bottom: 10px;
    }

    &-tab {
      width: auto;

      display: flex;
      flex-direction: column;
      gap: 4px;

      align-items: center;
      transition: all 0.1s;
      cursor: pointer;
      position: relative;

      &:hover {
        background: #f0f0f08b;
      }

      &:not(:last-child) {
        &::after {
          & {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            top: 0;
            width: 1px;

            background: #ececec;
          }
        }
      }

      &.active {
        &::before {
          content: '';

          position: absolute;
          top: -3px;
          bottom: -3px;
          left: 4px;
          right: 4px;

          border-radius: 2px;
          border: 1px solid #4c91ff;
        }

        &:hover {
          background: #fff;
        }
      }

      .header {
        font-size: 16px;
        font-weight: 600;
        color: #414d55;

        display: flex;
        align-items: center;

        &:first-child {
          &-index {
            display: none;
          }
        }

        &-index {
          color: #4c91ff;
          font-weight: 400;
          font-size: 9px;
        }
      }

      .sub {
        text-align: center;
        font-size: 11px;
        color: #bdbdbd;
        line-height: 10px;
        font-weight: 400;

        overflow-wrap: break-word;
      }
    }
  }

  &-date-select {
    min-width: 150px;
    max-width: 710px;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: 10px;
    margin-top: 10px;

    .title-box {
      width: 100%;
      min-width: 150px;
      display: flex;
      justify-content: start;
    }

    .title {
      color: #414d55;
      font-weight: 600;
      font-size: 20px;
      line-height: 40px;
    }

    .date-box {
      width: 100%;
      min-width: 150px;
      display: flex;
      justify-content: space-between;
    }

    & .date-select {
      display: flex;
      flex-wrap: wrap;
      min-width: 150px;
      align-items: center;
      gap: 10px;

      & .range-picker .ant-picker-suffix {
        left: 8px;
        right: 0;
        top: 8px;
      }
    }
  }

  &-delivery-status {
    display: flex;
    align-items: center;
    gap: 24px;

    &-info {
      display: flex;

      gap: 8px;
    }

    &-square {
      width: 20px;
      height: 20px;
      border-radius: 2px;
    }
  }

  &-bdd-metrics {
    height: 150px;

    &-tabs {
      max-width: 710px;
      width: 100%;

      background: #fff;
      border-radius: 2px;

      display: grid;
      grid-template-columns: repeat(4, auto);

      padding: 8px 10px;
      margin-bottom: 30px;
    }

    &-tab {
      width: auto;

      display: flex;
      flex-direction: column;
      gap: 4px;

      align-items: center;
      transition: all 0.1s;
      position: relative;

      &:hover {
        background: #f0f0f08b;
      }

      &:not(:last-child) {
        &::after {
          & {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            top: 0;
            width: 1px;

            background: #ececec;
          }
        }
      }

      &.active {
        &::before {
          content: '';

          position: absolute;
          top: -3px;
          bottom: -3px;
          left: 4px;
          right: 4px;

          border-radius: 2px;
          border: 1px solid #4c91ff;
        }

        &:hover {
          background: #fff;
        }
      }

      .header {
        font-size: 16px;
        font-weight: 600;
        color: #414d55;

        display: flex;
        align-items: center;

        &:first-child {
          &-index {
            display: none;
          }
        }

        &-index {
          color: #4c91ff;
          font-weight: 400;
          font-size: 9px;
        }
      }

      .sub {
        text-align: center;
        font-size: 11px;
        color: #bdbdbd;
        line-height: 10px;
        font-weight: 400;

        overflow-wrap: break-word;
      }
    }
  }
}

.ils-monitoring {
  &-waypoint-tooltip {
    background: white;
    color: #333;
    padding: 0 10px;
  }
}

.monitoring-dashboard-progress {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  &-container {
    display: flex;
  }

  &-label {
    flex-basis: 25%;
    flex-shrink: 2;
    margin-right: 10px;
    min-width: 120px;
  }
}


@primary-color: #4C91FF;