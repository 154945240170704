@import '@styles/default.less';

.ant-layout-sider.ant-layout-sider-collapsed {
  flex: 0 0 100px !important;
  max-width: 100px !important;
  min-width: 100px !important;
  width: 100px !important;
}

.ant-layout-sider-has-trigger:not(.ant-layout-sider-collapsed) {
  .ils-sidebar {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: @color-white;
    width: 240px;
    z-index: @z-index-menu;
  }
}

.ant-layout-sider-has-trigger {
  height: 100vh;
}

.ant-layout-sider-trigger {
  display: none;
}

.ils-sidebar {
  .ant-btn-primary,
  .ant-btn-primary:hover {
    background-color: transparent;
    border-color: transparent;
    width: 100px;
    height: 65px;
  }

  > button {
    box-shadow: none;
  }

  .menu {
    height: calc(100vh - 105px);

    .ant-menu {
      background-color: inherit;
      border-right: none;
      width: 240px;
    }

    ul > li {
      margin: 0 0 4px !important;
      min-height: 60px;
    }

    li.ant-menu-submenu {
      ul > li {
        padding-left: 35px !important;
        min-height: 40px;
      }
    }

    li:hover,
    li.ant-menu-item-selected,
    li.ant-menu-submenu-selected {
      background: @bg-primary;

      svg path {
        fill: @color-primary;
      }

      > span,
      .ant-menu-submenu-title > span {
        color: @color-primary;
      }
    }

    .ant-menu-submenu-title {
      height: 60px !important;
      margin: 0;

      .ant-menu-item-icon {
        display: flex;
        align-items: center;
      }
    }

    .ant-menu-item-selected a,
    .ant-menu-item-selected a:hover,
    .ant-menu-item a:hover,
    .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
    .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
      color: @color-primary;
    }

    .ant-menu .ant-menu-item::after,
    .ant-menu .ant-menu-submenu::after {
      left: 0;
      right: auto;
      border-right: 5px solid @color-primary;
    }

    .ant-menu-submenu-selected,
    .ant-menu-item:hover,
    .ant-menu-item-active,
    .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
    .ant-menu-submenu-active,
    .ant-menu-submenu-title:hover {
      color: inherit;
    }

    .ant-menu-item-icon {
      width: 24px;
      height: 100%;
      display: flex !important;
      align-items: center !important;

      div,
      svg {
        width: 24px;
        height: 24px;
      }
    }

    .ant-menu.ant-menu-inline-collapsed {
      width: 100px;

      li {
        display: flex;
        align-items: center;
        padding: 0 calc(50% - 12px);
      }

      li.ant-menu-submenu {
        padding: 0;

        .ant-menu-submenu-title {
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }

      li.ant-menu-submenu:not(.ant-menu-submenu-selected) {
        span {
          display: none;
        }
      }

      li.ant-menu-item-selected {
        flex-direction: column;
      }

      li.ant-menu-item-selected,
      li.ant-menu-submenu-selected {
        .ant-menu .ant-menu-item::after {
          left: 0;
          right: auto;
          border-right: 5px solid @color-primary;
        }

        span.ant-menu-title-content {
          opacity: 1;
          width: 95px;
          text-align: center;
          font-size: 13px;
          height: 30px;
          line-height: 14px;
          display: flex;
          flex-wrap: wrap;
          white-space: normal;
          justify-content: center;
          align-items: center;

          a {
            white-space: normal;
          }
        }

        .ant-menu-item-icon {
          height: 30px;
        }
      }

      li.ant-menu-submenu-selected {
        left: 0;
        right: auto;
        border-left: 5px solid @color-primary;
      }

      li.ant-menu-item-selected:after,
      li.ant-menu-submenu-selected:after {
        transform: scaleY(1);
        opacity: 1;
        transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }
  }
}

.ant-menu.ant-menu-sub.ant-menu-vertical {
  margin-left: -5px;
}

.ils-sidebar-version {
  width: 100%;
  text-align: center;
  color: #808080 !important;
  height: 40px;
  line-height: 40px;
}

.head-menu-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
}

.menu-link {
  z-index: @z-index-primary;
}

.sub-menu-title {
  border-bottom: 1px solid @bg-component;

  h3 {
    color: @color-darkgrey;
    cursor: default;
  }
}

.ant-menu-submenu,
.ant-menu-submenu-popup {
  z-index: @z-index-menu;
}

@primary-color: #4C91FF;