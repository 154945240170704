@import '@styles/default.less';

/* 
* Стили модуля "Заявки и рейсы" прописаны в одном файле в корневой папке 
* - src\modules\demand\styles.less 
* Используется основная обёртка - класс - ".ils-demand"
* Все остальные классы находятся внутри этой обёртки.
*/

.ils-demand {
  &-list-wrapper {
    height: calc(100vh - 300px);
    margin-top: 10px;
  }

  @media (max-width: 1270px) {
    &-list-wrapper {
      height: calc(100vh - 450px);
    }
  }

  &.ils-project-wrapper {
    margin: 0 0 10px 5px;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
  }

  &-list-item {
    height: 65px;
    background-color: @color-normal;
    box-shadow: -1px 2px 2px 1px lightgray;
    border-radius: 5px;
    margin-bottom: 5px;
  }

  &-list-item-wrapper {
    display: flex;
    flex-direction: column;
  }

  &-list-item-project-wrapper {
    display: flex;
    margin: 5px 0 0 15px;
  }

  &-project-background {
    display: block;
    width: 100px;
    height: 20px;
    background-color: lightgreen;
    text-align: center;
  }

  &-order-background {
    display: block;
    width: 100px;
    height: 20px;
    background-color: @border-color-primary;
    text-align: center;
  }

  &-project-created,
  &-project-name,
  &-project-id {
    display: block;
    margin-left: 20px;
    height: 20px;
    white-space: nowrap;
  }

  &-project-name {
    width: calc(100vw - 30%);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-plan-name {
    display: block;
    height: 20px;
    margin: 15px 0 0 15px;
  }

  &-project-trip {
    display: flex;
    flex-direction: column;
  }

  &-project-trip-wrapper {
    display: grid;
    grid-template-columns: 0.06fr 0.5fr 9fr;

    width: calc(100% - 40px);
    height: 25px;
    margin-left: 40px;
    border: 2px solid @border-color-gray;
    background-color: @color-white;
    padding-left: 5px;
  }

  &-trip-background,
  &-trip-id {
    margin-left: 20px;
  }

  &-project-trip-info {
    display: grid;
    grid-template-columns: 1fr 3fr 9fr;

    width: calc(100% - 60px);
    height: 25px;
    margin-left: 60px;
    border: 2px solid @border-color-gray;
    border-top: 0;
    background-color: @color-white;
    padding-left: 15px;
  }

  &-project-trip-driver-info {
    display: grid;
    grid-template-columns: 1fr 3fr 9fr;

    width: calc(100% - 60px);
    height: 25px;
    margin-left: 60px;
    border: 2px solid @border-color-gray;
    border-top: 0;
    border-bottom: 0;
    background-color: @color-white;
    padding-left: 15px;
  }

  &-project-trip-driver-info-last {
    border-bottom: 2px solid @border-color-gray;
  }

  &-trip-show-more-button-wrapper {
    display: flex;
    justify-content: end;
    margin-top: 5px;
  }

  &-trip-show-more-button {
    width: calc(100% - 40px);
    display: flex;
    justify-content: space-between;
  }

  &-trip-show-more-button-not-visible {
    display: none;
  }

  & .svg-container {
    width: 40px;
  }

  &-list-item-order-lot-wrapper {
    display: flex;
    margin-top: 15px;
  }

  &-list-item-order-lot-info {
    display: block;
    margin-left: 15px;
    height: 20px;
  }

  &-order-lot-trip {
    display: flex;
    flex-direction: column;
  }

  &-order-lot-trip-wrapper {
    display: grid;
    grid-template-columns: 0.06fr 2fr 4.5fr 4.5fr 5fr;

    width: calc(100% - 40px);
    height: 65px;
    margin-left: 40px;
    border: 2px solid @border-color-gray;
    background-color: @color-white;
    padding-left: 5px;
  }

  &-order-lot-trip-background,
  &-order-lot-trip-id {
    margin-left: 20px;
  }

  &-header {
    display: flex;

    &-settings-group {
      height: 200px;
      min-width: 500px;
      margin-right: auto;
      display: flex;
      flex-wrap: wrap;

      .ils-demand {
        &-tabs {
          height: 50px;
          display: flex;

          .ant-tabs-nav-wrap {
            padding-left: 10px;

            .ant-tabs-tab {
              color: @color-black;
              font-size: 1.15em;
              justify-content: center;
              width: 100px;
              height: 50px;
              margin-right: 50px;
            }

            .ant-tabs-tab-active {
              color: @color-primary;
            }
          }

          &-hint {
            position: absolute;
            display: flex;
            justify-content: center;
            top: 10px;
            right: -45px;
            min-width: 40px;
            height: 25px;
            background-color: @color-primary;
            color: @color-white;
            border-radius: 5px;
          }
        }

        &-filters {
          height: 150px;
          width: 100%;
          position: relative;
          margin: 5px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          row-gap: 10px;

          .filter-input {
            width: 150px;
            margin: 0 5px;
            border-radius: 5px;
          }

          .ant-picker .ant-picker-suffix {
            left: 8px;
            top: 8px;
          }
        }
      }

      button.reload-button {
        margin-top: 13px;
        height: 30px;
        width: 30px;
        vertical-align: -5px;
      }
    }

    &-buttons {
      height: 120px;
      padding: 10px;
      width: 520px;

      .create-buttons-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        .button-annotation {
          width: 80px;
          height: 100%;
          text-align: center;
        }

        .ils-demand-create-button {
          height: 100px;
          width: 125px;
          white-space: normal;
          margin: 10px;
          flex-shrink: 0;
        }
      }
    }
  }
}

@primary-color: #4C91FF;