@import '@styles/default.less';
@import '../../styles.less';

.ils-chart-table {
  background-color: #fff;
  padding: 15px 25px 50px 20px;
  overflow: hidden;

  .chart-label {
    width: 100px;
    min-width: 100px;
    color: @color-black;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 10px;
  }

  .chart-header {
    text-align: right;
    height: 32px;
    color: #bbb;
    margin-right: -5px;
    font-size: 13px;

    .ticks {
      width: calc(100% - 90px);
      min-width: calc(100% - 90px);
      float: right;
    }

    .time {
      text-align: center;
      line-height: 16px;
      width: 40px;

      div {
        white-space: nowrap;
        text-align: center;
      }
    }
  }

  .chart-area {
    width: calc(100% - 100px);
    min-width: calc(100% - 100px);
    display: flex;

    .chart:first-child {
      margin-right: -8px;
    }
  }

  .chart-row {
    border-bottom: 1px solid @color-normal;
    min-height: 62px;
  }

  .annotation {
    position: absolute;
    height: 105%;
    width: 5px;
    top: 0 !important;
    border: 1px solid;
    background-color: @color-white;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .text {
      position: relative;
      top: 21px;
      white-space: nowrap;
    }
  }

  canvas {
    display: block !important;
  }

  .choose-interval {
    > div {
      border: 1px solid @color-normal;
    }

    > div.active {
      border-color: @color-primary;
    }
  }
}

@primary-color: #4C91FF;