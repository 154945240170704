@import '@styles/default.less';

.react-tel-input.phone-input {
  .phone-input {
    width: 100%;

    &-number.form-control {
      width: 100%;
      background-color: @color-white;

      &:disabled, &[disabled] {
        width: 100%;
        background: @bg-input-disabled;
      }
    }

    &-search {
      &:disabled, &[disabled] {
        background-color: @bg-primary;
      }
    }

    &-region.flag-dropdown {
      background-color: @color-white;

      &.disabled {
        background-color: @bg-input-disabled;
      }

      &-list {
        background-color: @color-white;
        width: fit-content;
        //text-align: left;
      }
    }
  }
}


@primary-color: #4C91FF;