@import '@styles/default.less';

.documents-upload-container.ant-upload.ant-upload-drag {
  height: 75vh;
  background: @color-white;

  .hint {
    color: @link-color;
  }
}
@primary-color: #4C91FF;