.set-child-item {
  margin-left: 20px;
  position: relative;
}

.set-child-item::before {
  position: absolute;
  content: '';
  display: flex;
  top: 10px;
  width: 8px;
  left: -20px;
  border: 1px solid #000;
  border-radius: 10px;
}
@primary-color: #4C91FF;