@import '@styles/default.less';

@tab-header-height: 40px;

.scheme-editor-blue() {
  border: 1px solid @color-primary;
  color: @color-primary;
  background: transparent;
}

.scheme-editor-scheme() {
  padding: @gap-primary;
  .flex-layout-vertical(start, start);
  align-self: stretch;
  min-width: 33%;
  max-width: 50%;
}

.scheme-editor {
  .content-section-padding();

  &-menu {
    .reset-full();
    .flex-layout-horizontal();
    padding-right: 8px;
    flex-wrap: wrap;

    &-item {
      list-style: none;
    }
  }

  &-options {
    .scheme-editor-scheme();
    .option {
      .scheme-editor-blue();
      padding-right: 0;
      .scheme-editor-intro-list-item-option {
        border-radius: 0;
        border-style: none;
      }
    }
  }

  &-intro {
    padding: 2rem;
    border-radius: 0.3rem;
    background-color: @color-lightgrey;

    .planner-upload-drag-n-drop {
      background-color: @color-lightgrey;

      .ant-typography {
        .flex-layout-vertical(space-between, center, @gap-em);

        .ils-svg, svg {
          .icon-square(40px);
        }
      }
    }

    &-list {
      .reset-full();
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: @gap-em;

      &-item {
        .reset-full();

        &-option {
          font-size: 1rem;
        }
      }
    }
  }

  &-tab {
    height: calc(@main-height - @tab-header-height);
  }

  &-scheme {
    padding: @gap-primary;
    .flex-layout-horizontal(space-between, start);

    span.droptarget.highlighted {
      .scheme-editor-scheme-input,
      fieldset {
        border: 2px dashed @color-primary;
        background: rgba(76, 145, 255, 0.1);
      }
    }

    &-max,
    &-current,
    &-info {
      .scheme-editor-scheme();

      div {
        width: 100%;

        &.ddcontainerghost {
          width: 25%;
        }
      }
    }

    &-empty-list {
      height: 100%;

      .ddcontainer,
      .ddcontainersource {
        height: 100%;
      }
    }

    &-title {
      .flex-layout-horizontal(start, center);

      div {
        width: fit-content;

        &.ils-svg {
          margin: 0 auto;
        }
      }
      button.descend {
        transform: rotate(180deg);
        fill: @color-primary;
        box-shadow: 0 0 2px @color-primary;
      }
    }

    .ant-transfer {
      min-width: calc(66% - @padding-primary);
      width: calc(100% - @padding-primary);

      .ant-transfer-list {
        width: 100%;
        min-width: 47%;
        border: none;
        height: fit-content;
        max-height: 100vh;

        &-header {
          height: fit-content;
          max-height: 25vh;
        }
      }
    }

    &-input {
      .scheme-editor-blue();
      .flex-layout-horizontal(start, center);
      padding: 0 @gap-primary;

      &-select.ant-select {
        max-width: fit-content;

      }

      .ant-row.ant-form-item {
        max-width: fit-content;
      }

      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        max-width: fit-content;
        border-style: none;
      }

      &-title {
        color: @color-primary;
      }

      .ant-row {
        margin: 0;
      }
    }

    &-search-form {
      input {
        height: 27px;
      }
      .ant-row {
        margin-bottom: 0;
      }
    }
  }
}

@primary-color: #4C91FF;