@import '@styles/default.less';

@tooltip-status-width: 900px;
@validation-container-height: 36px;
@table-header-height: 75px;
@planner-planning-info-height: 93vh;
@planner-planning-menu-and-validation-height: 115px;
@planner-plan-table-height: calc(@planner-planning-info-height - @planner-planning-menu-and-validation-height);
@planner-context-menu-width: 300px;
@planner-order-table-min-height: 500px;

.planner {
  &-form {
    &-create-plan {
    }
  }
}

.error-box {
  max-height: 400px;
  height: 400px;

  .ant-table-body {
    min-height: 32px;
  }
}

.chart-modal {
  height: 700px !important;
  width: 70vw !important;

  .ant-modal-body {
    padding-top: 10px;
  }
}

.ils-planning-process {
  .chart {
    min-height: 300px;
    max-width: 100%;
    padding: 15px 15px 0 15px;
    background: #fff;
  }

  .chart-zone {
    //width: calc(100% - 260px);
    width: 100%;
  }

  .legend {
    cursor: pointer;
    width: 250px;

    .color {
      width: 3px;
      height: 25px;
    }

    span {
      display: block;
      width: 100%;
    }

    .inactive {
      color: @color-darkgrey;
    }
  }

  .time-count {
    min-height: 77px;

    .time-spin {
      width: 100%;
    }
  }
}

.ils-planning {
  height: 100%;
  padding: 0 0 10px 0;

  .table-config {
    width: 100%;

    .config-container {
      max-height: 30vh;
    }
  }

  .table-header-buttons-action {
    overflow: visible;
  }

  //noinspection ALL
  .__dbk__gutter.Horizontal {
    height: @planner-planning-info-height !important;
  }

  .plan-table {
    max-width: 100%;

    .main-collapse {
      width: 100%;

      .ant-collapse-item {
        margin: 0;
      }
    }

    .ils-table {
      .position-relative.table-resize-area {
        height: 100%;
      }
    }
  }

  .blur-map {
    #ils-map {
      filter: blur(2px);
      opacity: 0.8;
    }
  }

  .map-container {
    position: relative;
    height: 100% !important;

    .ils-map {
      height: 100vh;
    }

    .map-center-loader {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(-50%, -50%);
      z-index: @z-index-button;
    }
    .map-buttons {
      .flex-layout-vertical(flex-start, flex-end, @gap-primary);
      position: absolute;
      top: 1.5em;
      right: 0.5em;
      z-index: @z-index-button;

      .map-buttons-with-number {
        .flex-layout-horizontal();
        flex-direction: row-reverse;
        .reset-full();
        padding-left: 5px;
      }

      div,
      svg {
        min-width: 24px;
        min-height: 24px;
        fill: black;
      }

      .map-btn-menu {
        display: flex;
        flex-direction: column;
        gap: @gap-em;
        padding: @gap-em;
        margin: 0;
        position: absolute;
        top: 0;
        right: 5px;
        min-width: 200px;
        min-height: 200px;
        background: @bg-component;
        z-index: @z-index-context-menu;

        .react-customscroll,
        .react-customscroll-holder,
        .react-customscroll-frame {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;

          .ant-checkbox-wrapper {
            margin-left: 8px;
          }
        }

        .menu-buttons {
          padding: 0;
          margin: 0;
          display: flex;
          justify-content: space-around;
        }
      }
    }

    .matrix {
      .leaflet-control();
      top: 0;
      bottom: auto;
      right: 0;
      height: 16.5px;
    }
  }
  &-map {
    &.fullTable {
      display: none;
    }

    height: 100%;

    .leaflet-top.leaflet-right {
      top: 167px;
      display: none;

      .leaflet-control {
        margin-right: 3px;
      }
    }

    .leaflet-top.leaflet-right {
      top: 167px;
      display: none;

      .leaflet-control {
        margin-right: 3px;
      }
    }
  }
  //TODO потом разделить стили
  &-result,
  &-map {
    h2 {
      line-height: 22px;
      height: 42px;
      min-width: 80px;
      .flex-justify-align(flex-start, center);
    }

    .ils-table tr.active-row td {
      background: rgb(138, 182, 253) !important;

      .cell-checkbox span {
        background: white !important;

        input:checked + span {
          background: @color-primary !important;
        }
      }

      span {
        color: #fff !important;
        background: transparent !important;

        &.ant-select-clear {
          svg {
            color: rgb(58, 56, 56) !important;
          }
        }

        button {
          color: #faad14 !important;
        }
      }
      .good {
        span {
          color: @color-processing !important;
        }
      }
    }
    .plan-table-current-plan .ils-table tr.active-row td .good {
      span {
        color: @color-black !important;
      }
    }

    .drag-container {
      position: absolute !important;
      z-index: @z-index-drag-container;
      width: 20px;
      height: 20px;
    }

    .ddcontainer {
      width: 100%;
      height: 100%;
    }

    .ddcontainerghost .container {
      width: 1px;
      height: 1px;

      .drag {
        display: block;
        min-width: 16px;
        min-height: 16px;
        border: 4px solid @color-error;
        border-radius: 50%;
        margin: 15px 0 0 15px;
      }
    }

    .drag-container .container.selected {
      .drag {
        border-color: @color-warning;
      }
    }

    //noinspection ALL
    .__dbk__dragger.Horizontal,
    .map-container {
      height: 100%;
      z-index: @z-index-resizer;
    }

    .ant-table .ant-table {
      margin: 0 !important;
    }

    .ant-table.ant-table-small tr {
      min-height: 30px;
    }

    .ant-table.ant-table-small .ant-table-tbody > tr > td {
      padding: 0 6px;

      > img {
        margin-left: 5px;
      }
    }

    tr.ant-table-expanded-row {
      > td.ant-table-cell {
        padding: 0 !important;
      }

      .ant-table-wrapper.oa-table {
        padding-left: 47px;
      }
    }

    .change-table {
      top: 40px;
    }

    .ant-col.ant-col-6.p-3.bg-body {
      padding: 10px !important;
    }

    .config-container {
      .ant-collapse-content > .ant-collapse-content-box {
        padding: 0 0 10px 10px;
      }
    }

    .ant-col.ant-col-24.pr-3 {
      padding-right: 0 !important;
    }

    .ils-map-table {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: @z-index-table-under-map;
      overflow: visible;
      height: fit-content;
      .flex-layout-vertical(space-between, center, @no-gap);
      flex-direction: column-reverse;

      .table-config-planner-vehicle {
        display: flex;
        order: 1;
        max-height: 50vh;
        height: inherit;

        .ant-table-pagination.ant-pagination {
          margin: 0;
        }
      }

      .table-config-planner-order {
        max-height: 50vh;
        .ant-table-pagination.ant-pagination {
          margin: 0;
        }
      }

      .table-config-planner-order-100h {
        min-height: @planner-order-table-min-height;
        height: calc(100vh - 65px);

        .ils-table,
        .position-relative,
        .unresolved-table,
        .ant-spin-nested-loading,
        .ant-spin-container,
        .ant-table,
        .ant-table-container {
          //height: 100%;
        }

        .ant-table-footer {
          position: sticky;
          bottom: 0;
        }
      }

      .ils-table {
        max-width: 100%;

        .add-order-btn {
          width: 45px;
          height: 40px;
          align-self: flex-start;
          border: none;
          box-shadow: none;

          .svg-container {
            width: 40px;
            height: 40px;
          }
        }

        .table-header {
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;

          &-buttons {
            flex-wrap: nowrap;
            align-items: center;
          }
        }

        .action {
          flex-wrap: inherit;
        }

        .table-header-buttons-action {
          display: flex;
          flex-direction: row;
          min-width: 150px;
        }
      }
    }

    .result-table {
      //.ant-spin-nested-loading > .ant-spin-container > .ant-table > .ant-table-container {
      //  > .ant-table-body {
      //    max-height: calc(100vh - 770px) !important;
      //    min-height: 40px;
      //  }
      //}
      .ant-table-small .ant-table-thead > tr > th {
        > * {
          max-height: 32px;
          line-height: 16px;
        }

        .sorter-part {
          top: -6px;
        }
      }
    }

    .result-table.plan {
      .ant-spin-nested-loading > .ant-spin-container > .ant-table > .ant-table-container {
        > .ant-table-body {
          min-height: 40px;
        }
      }

      .ant-table-content {
        //Если планов 8, 350px максимум того, что помещается
        min-height: 350px;
        max-height: 350px;
        overflow-y: scroll;
        overflow-x: hidden;
        scrollbar-width: thin;
        scrollbar-color: #bbb #ffffff;

        &::-webkit-scrollbar {
          height: 7px;
          width: 7px;
        }

        &::-webkit-scrollbar-track {
          background-color: rgb(255, 255, 255);
        }

        &::-webkit-scrollbar-thumb {
          box-shadow: inset 0 0 6px #bbb;
          border-radius: 5px;
        }
      }

      .ant-table-thead {
        z-index: @z-index-scroll;
      }

    }
  }
}

.current-month-only {
  td.ant-picker-cell:not(.ant-picker-cell-in-view) {
    visibility: hidden;
  }
}

.status-tip {
  .flex-vertical(@gap-primary);
  max-height: 80vh;
  flex-wrap: wrap;

  li {
    .flex-horizontal(@gap-em);
    flex-direction: row !important;
    line-height: 14px;
    width: fit-content;

    img {
      width: 20px;
      height: 20px;
    }
  }
}

@keyframes fadeIn {
  0% {
    transform: translateY(-25%);
  }
  50% {
    transform: translateY(4%);
  }

  80% {
    transform: translateY(2%);
  }

  100% {
    transform: translateY(0%);
  }
}

.ils-planning-result {
  &.fullMap {
    display: none;
  }

  .__dbk__container {
    position: absolute;
  }

  &-settings {
    .flex-layout-horizontal(flex-start, center, @no-gap);
    height: fit-content;

    .ant-form-item {
      margin: 0;

      .input-header {
        border: none;
        background: transparent;
        font-weight: 500;
        font-size: 22px;
        text-overflow: ellipsis;
        overflow: hidden;
        width: fit-content;
        white-space: nowrap;
      }
    }
  }

  &-info {
    //.flex-layout-vertical(flex-start, flex-start, @no-gap);
    //height: fit-content;
    background: @color-white;
    margin: @size-around-content 0;
    height: calc(100% - 51px);
  }

  &-matrix {
    height: 100%;

    .ant-alert.ils-infobox {
      height: 100% !important;
    }
  }

  .button-show-form {
    width: fit-content !important;
  }

  .selected-project {
    text-overflow: ellipsis;
    overflow: hidden;
    width: fit-content;
    white-space: nowrap;
  }
}

.planning-sub-menu {
  z-index: calc(@z-index-plan-context-menu-under-drop-container + 1);
}

.plan-context-menu {
  animation-name: fadeIn;
  animation-duration: 0.4s;
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  left: 0;
  list-style-type: none;
  margin: 0;
  outline: none;
  padding: 0;
  position: absolute;
  text-align: left;
  top: 0;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: @z-index-plan-context-menu-under-drop-container;
  min-width: @planner-context-menu-width;

  &-button-link {
    color: @color-black;
    padding: @no-gap;
  }

  .plan-add-form {
    padding: @gap-em;
  }

  .menu-title {
    border-bottom: 1px solid @color-normal;
    color: @color-primary;
    font-weight: 500;
    line-height: 20px;
  }

  .menu:not(:last-child) {
    border-bottom: 1px solid @color-normal;
  }

  .menu {
    margin: 0 5px;
    padding: 5px 5px;
    border-right: none;

    li {
      margin: 0 !important;
      height: 30px;
      line-height: 30px;
      padding: 0 7px !important;

      &.ant-menu-submenu {
        position: relative;
        &:hover::after {
          content: attr(data-title);
          position: absolute;
          bottom: 100%;
          z-index: 1300; /* Отображаем подсказку поверх других элементов */
          background: rgba(39, 39, 39, 0.8);
          padding: 0 7px;
          font-size: 11px;
          color: #ffffff;
        }
      }

      .ant-menu-submenu-title {
        margin: 0 !important;
        padding: 0 !important;
        height: 30px;
        line-height: 30px;
      }
    }

    li.inactive {
      pointer-events: none;
      color: @color-inactive;
    }
  }
}

.repeated-element {
  color: red;
}

.plan-date {
  .flex-layout-horizontal(space-between);
  position: relative;
  min-width: unset;
  min-height: 32px;
  padding: @size-around-content 0 0 0;

  > .ant-picker {
    min-width: 150px;
  }

  > .ant-picker.ant-picker-focused {
    min-width: 150px;
  }

  > .ant-spin.ant-spin-spinning {
    position: absolute;
    top: 2px;
    right: 30px;

    .ant-spin-dot {
      font-size: 20px;
    }
  }
}

.notification-window {
  img {
    width: 20px;
    height: 20px;
  }
}

.penalty-window {
  min-width: 600px;

  .row {
    min-width: 600px;

    > div {
      display: inline-block;
    }

    .result {
      width: 150px;
    }

    .coef {
      width: 100px;
    }

    .value {
      width: 100px;
    }

    .desc {
      width: 300px;
    }
  }
}

.efficiency-window {
  min-width: 600px;

  .row {
    min-width: 100%;

    > div {
      display: inline-block;
      text-align: center;
    }

    .label {
      width: 130px;
      text-align: left;
    }

    .item {
      width: 80px;
    }

    .peak {
      width: 100px;
    }

    .allow-over {
      width: 120px;
    }

    .profile {
      width: 100px;
    }

    .load {
      width: 110px;
    }

    .use {
      width: 130px;
    }
  }
}

.ant-modal {
  min-width: fit-content;

  .efficiency-window {
    width: 800px;
    min-width: 800px;

    .eff-table {
      padding-left: 5px;
      position: relative;

      .eff-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: @z-index-content;
        opacity: 0.3;
      }
    }
  }
}

.ant-message {
  &-error {
    display: flex;
    align-items: center;
  }
}

.table-control-btn {
  padding: 6px 0 !important;
}

.table-control-btn.active {
  border-color: @color-primary !important;
}

.table-control-btn,
.map-control-btn {
  span {
    font-size: 12px;
    position: relative;
    top: -2px;
  }
}

.map-restriction-btn.active,
.table-restriction-btn.active {
  min-width: 35px;
}

.table-btn-menu {
  .reset-full();
  .flex-layout-vertical(space-between, center, @gap-em);

  &.with-scroll {
    .react-customscroll {
      width: 100%;

      &-holder,
      &-frame {
        display: block;
      }
    }
  }

  &-list {
    .flex-layout-vertical(start, start);
    max-height: calc(@planner-context-menu-width - @gap-em);
  }

  &-buttons-restriction {
    .reset-full();
    .flex-layout-horizontal(space-between, center);
    width: 100%;
  }
}

.config-list {
  height: 110px;
}

@budget-form-width: 480px;
.budget-form {
  .flex-layout-vertical(center, center, @gap-em);
  width: @budget-form-width;

  &-spinner {
    transform: translateX(calc(@budget-form-width / 2));
  }

  &-item {
    width: 100%;
  }
}

.edit-svg-icon {
  position: absolute;
}

.ant-popover {
  .planning-matrix-buttons {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    gap: @gap-rem;
  }
}

.validation-summary {
  .flex-layout-horizontal(flex-start, center, @gap-em);
  .reset-full();
  padding: 0 @gap-em;
  width: 100%;
  background: @color-white;
  cursor: pointer;
  border-bottom: @border-primary;

  &-modal {
    .flex-layout-horizontal(start, start, @no-gap);
  }

  &-info {
    .flex-layout-horizontal(flex-start, center, @gap-primary);
    .reset-full();
    padding: 0;

    .ant-alert {
      .reset-full();
      padding: @paddings-general;
      gap: @paddings-general;
      min-width: 70px;
      min-height: 35px;

      &-icon {
        .reset-full();
        svg {
          width: 20px;
          height: 20px;
        }
      }

      .anticon {
        .reset-full();
      }
    }

    &-alert {
      .reset-full();
      min-width: 20px;
      min-height: 20px;
    }
  }

}

.planning-validation-container {
  .ant-modal-content {
    max-width: 80vw;
  }

  .validation-summary {
    padding: @gap-em;
    cursor: default;
  }

  th {
    .sorter-cell {
      span {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .svg-container {
          margin: 0 7px;
        }
      }
    }
  }

  .resizer {
    height: 0;
  }
}

.error-planning-table {
  width: min-content;
  table {
    max-width: max-content;
    thead.ant-table-thead {
      position: static !important;
    }

    tr {
      td {
        &:first-child {
          cursor: default;
        }
      }

      cursor: pointer;

      &:last-child {
        cursor: default;
        font-weight: bold;

        .error-container {
          &:hover {
            background: #fff;
            border: 1px solid #fff;
          }

          .error-square {
            width: 10px;
            height: 10px;
            margin-right: 0;

            &-error {
              background: rgb(255, 255, 255);
            }

            &-warning {
              background: rgb(255, 255, 255);
            }

            &-info {
              background: rgb(255, 255, 255);
            }

            &-bad {
              background: rgb(255, 255, 255);
            }
          }
        }
      }
    }
  }
}

.trip-tender-platform-modal {
  width: 700px !important;
}

.full-screen-table-map {
  height: @main-height;
  background-color: @color-white !important;
}

.planning-export {
  .ant-select {
    margin-bottom: 10px;
  }
}

