@import '@styles/default.less';

.excel-export-select-items {
  display: inline-block;
  color: @color-primary !important;
  cursor: pointer;

  margin-bottom: 10px;
}


@primary-color: #4C91FF;