@import '@styles/default.less';

.road-graph-content {
  width: calc(100vw - 220px);
  height: calc(100vh - 170px);

  .map-control-btn {
    position: relative !important;
    margin-bottom: 7px;
    display: block;
    top: 0;
    left: 0;
    right: 0;
  }

  //.map-depots-btn{
  //  top: 55px;
  //}
  .map-left-buttons,
  .map-right-buttons {
    position: absolute;
    z-index: @z-index-button;
  }

  .map-left-buttons {
    top: 83px;
    left: 10px;
  }

  .map-right-buttons {
    top: 100px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .map-set-marker-btn {
    padding: 2px !important;

    div, svg {
      width: 27px;
      height: 27px;
    }

    span {
      position: absolute;
      font-weight: 600;
      color: #555;
      font-size: 18px;
      left: 1px;
      top: -2px;
      right: 0;
    }
  }

  .zones-menu, .summary-info {
    z-index: @z-index-context-menu;
    background: @bg-body;
    width: 250px;
    height: 300px;
    position: relative;

    button.close {
      position: absolute;
      right: 0;
      top: 0;
      border: none;
      background: inherit;
      padding: 8px;

      div, svg {
        width: 16px;
        height: 16px;
      }
    }

    h3 {
      border-bottom: 1px solid @color-normal;
    }
  }

  .summary-info {
    position: absolute;
    right: 20px;
    bottom: 20px;
    height: auto;
    max-height: 500px;

    .speeds {
      white-space: normal;
      width: 100%;
      overflow: hidden;
      word-break: break-word;
      max-height: 70px;
    }
  }

  .marker-helper {
    position: absolute;
    z-index: @z-index-button;
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;
    pointer-events: none;

    span {
      top: -1px;
      left: 0;
    }
  }
}

.map-control-btn.map-depots-btn {
  top: 55px;
  right: 20px;
}
@primary-color: #4C91FF;