@import '@styles/default.less';

.ils-title-line-series {
  &-slider {
    .ant-slider-handle {
      display: none;
    }

    .ant-slider-track {
      background-color: @color-primary;
    }
  }
}

@primary-color: #4C91FF;