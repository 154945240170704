@import '@styles/default.less';

@card-padding: 4px;
@card-border-radius: 2px;

.document-download-card {
  width: 100%;
  .flex-horizontal();
  justify-content: space-between;
  align-items: center;
  padding: @card-padding;
  border-radius: @card-border-radius;
  border: @border-primary;
  &__group {
    .flex-horizontal(@gap-em);
    align-items: center;
  }
  &__extension,
  &__name {
    margin: 0;
  }
  &__extension {
    min-width: 36px;
    font-size: 12px;
    font-weight: @fw-600;
    text-transform: uppercase;
    color: @color-primary;
    pointer-events: none;
  }
  &__name {
    .overflow-ellipsis();
  }
  &__link {
    padding: @card-padding;
    border-radius: @card-padding;
    border: @border-primary;
    &.disabled {
      pointer-events: none;
      background-color: @border-color-primary;
    }
  }
}


@primary-color: #4C91FF;