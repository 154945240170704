@import '@styles/default.less';

.filter-cancel {
  color: #bdbdbd;
  background: none;
  border: none;
  cursor: pointer;
}

.template-modal-block {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 65vh;
  overflow-y: auto;
  padding-right: 10px;

  scrollbar-color: @color-scroll @color-white;

  &::-webkit-scrollbar {
    width: 6px;
    height: 8px;
    background-color: @color-white;
  }

  &::-webkit-scrollbar-thumb {
    background: @color-scroll;
  }
}

.not-found {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  &-message {
    max-width: 520px;
    text-align: center;
  }
}

.success-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;

  .buttons {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
}

.form-row {
  gap: 21px;
}

.document-table-cell {
  overflow: hidden;
}

.ils-documents-table-wrap {
  width: 100%;
}

.documents-side-panel {
  overflow-y: auto;
  height: calc(100vh - 200px);
  padding: 5px;
}
.documents {
  thead {
    .ils-svg {
      padding-left: 2px;
      text-align: left;
      svg {
        height: 12px;
      }
    }
  }
}


@primary-color: #4C91FF;