@import 'leaflet/dist/leaflet.css';
@import 'leaflet-draw/dist/leaflet.draw.css';
@import 'react-leaflet-markercluster/dist/styles.min.css';
@import '@styles/default.less';
@import './controls/ruler/leaflet-ruler.less';

.leaflet-draw-toolbar {
  .leaflet-bar {
    transform: scale(1.2);
  }

  .leaflet-bar.leaflet-draw-toolbar-top {
    margin: 10px 0 25px 0;
  }

  .leaflet-bottom.leaflet-right {
    display: none;
  }
}

.tile-layers-list {
  span {
    color: @color-processing;
    padding: 2px 5px;
    cursor: pointer;
  }

  span.active {
    color: @color-primary;
  }
}

.marker-color {
  width: 20px;
  height: 20px;
  .absolute-center();
}

.marker-order {
  text-indent: -99999px;
  left: -5px;
  width: 16px;
  height: 16px;
  border: 4px solid @color-error;
  border-radius: 50%;
  position: absolute;
  .absolute-center();
}

.marker-order.selected {
  border-color: @color-warning !important;
}

.marker-order.inactive {
  border-color: @color-darkgrey;
}

.marker-point {
  width: 24px;
  height: 24px;
  .absolute-center();
  left: -6px;

  span {
    .absolute-center();
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    left: -3px;

    svg {
      width: 14px;
      height: 14px;
    }
  }
}

.marker-point.square {
  border: 3px solid @color-error;
  background-color: #fff;
}

.marker-point.circle {
  border-radius: 50%;
  border: 3px solid @color-error;
  background-color: #fff;
}

.marker-point.triangle {
  width: 28px;
  height: 28px;
  left: 0 !important; //Не сдвигать иконки маркеров, относительно их контейнера!

  //top: -5px;
  svg {
    width: 28px;
    height: 28px;
  }

  span {
    left: 0;
    line-height: 30px;
    height: 28px;
    width: 28px;
  }
}

.marker-point.selected:not(.triangle):not(.marker-custom) {
  border-width: 5px;
  width: 28px;
  height: 28px;
  background-color: #fff;

  span {
    width: 28px;
    height: 28px;
    line-height: 28px;
  }
}

.marker-custom {
  width: @button-icon-width;
  height: @button-icon-width;

  span {
    width: 16px;
    height: 16px;
    text-align: center;
    .absolute-center();
    top: -8px;
    //left: -2.5px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.custom-marker {
  width: 28px;
  height: 35px;
  position: relative;
  .absolute-center();

  svg {
    margin-top: -2px;
    margin-left: -2px;
    width: @button-icon-width;
    height: 39px;
    object-fit: cover;
  }

  .icon {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 6px;
    left: 6px;
  }

  .icon.home {
    width: 16px;
    height: 16px;
    top: 5px;
    left: 6px;
  }
}

.custom-marker.text {
  span {
    position: absolute;
    left: 2px;
    top: 1px;
    right: 0;
    bottom: 0;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: @color-primary;
  }

  svg {
    margin-top: -3px;
    margin-left: -3px;
    width: 35px;
    height: 42px;
    object-fit: cover;
  }
}

.map-control-btn {
  top: 50px;
  position: absolute !important;
  right: 3px;
  width: 35px !important;
  height: 35px !important;
  padding: 6px !important;
  z-index: @z-index-button;
  border-radius: 4px !important;

  div,
  svg {
    width: 21px;
    height: 21px;
  }
}

.map-control-btn.active {
  border-color: @color-primary !important;
}

.leaflet-touch .leaflet-bar,
.map-control-btn {
  border: 2px solid rgba(0, 0, 0, 0.2) !important;
  background-color: #ffffff;
  border-radius: 4px;
}

.map-convex-btn,
.map-btn-top-3 {
  top: 92px;
}

.map-restriction-btn,
.map-btn-top-4 {
  top: 134px;

  .svg-container div svg {
    fill: #555;
  }
}

.map-lasso-btn {
  top: 176px;
}

.map-ruler-btn {
  top: 218px;
}

.map-summary {
  position: absolute;
  bottom: 0;
  right: 40px;
  width: 400px;
  height: 350px;
  background: @bg-body;
  z-index: @z-index-context-menu;
  padding: 2px 5px;

  .table-head {
    padding: 3px 5px;
    background: @color-primary;
    color: @color-white;
    font-size: 16px;
  }

  .close-btn {
    width: 24px !important;
    height: 24px !important;
    padding: 3px !important;
    top: 5px;
    right: 8px;
  }

  .row {
    border-bottom: 1px solid @color-normal;

    > div {
      display: inline-block;
      padding: 3px 5px;
      height: 100%;
    }

    .label {
      width: 35%;
      line-height: 15px;
    }

    .out-of-trip {
      width: 20%;
    }

    .in-trip {
      width: 20%;
    }

    .total {
      width: 25%;
    }

    .sum {
      width: 65%;
      text-align: center;
    }
  }

  .row:not(.head) {
    > div {
      border-right: 1px solid @color-normal;
    }
  }
}
.waypoint-popup-container {
  .leaflet-popup-content {
    margin: 5px 10px;
    .waypoint-popup-wrapper {
      display: flex;
      flex-direction: column;
      .ils-show-order-btn {
        margin-left: auto;
      }
    }
  }
}
.point-tip {
  min-width: 300px;

  .title {
    font-weight: 600;
    text-align: center;
    font-size: 12px;
  }
  // кастомный 2-строчный ellipsis для всплывающего окна на карте
  div.ellipsis-value-two-line {
    overflow: hidden;
    height: 32px;
    line-height: 16px;
    margin: 0;
    p {
      margin: 0;
    }

    &:before {
      content: '';
      float: left;
      width: 5px;
      height: 32px;
    }

    & > *:first-child {
      float: right;
      width: 100%;
      margin-left: -5px;
    }

    &:after {
      content: '\02026';

      box-sizing: content-box;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;

      float: right;
      position: relative;
      top: -16px;
      left: 100%;
      width: 3em;
      margin-left: -3em;
      padding-right: 5px;

      text-align: right;

      background-size: 100% 100%;
      /* 512x1 image,gradient for IE9. Transparent at 0% -> white at 50% -> white at 100%.*/
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAgAAAAABCAMAAACfZeZEAAAABGdBTUEAALGPC/xhBQAAAwBQTFRF////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////AAAA////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////wDWRdwAAAP90Uk5TgsRjMZXhS30YrvDUP3Emow1YibnM9+ggOZxrBtpRRo94gxItwLOoX/vsHdA2yGgL8+TdKUK8VFufmHSGgAQWJNc9tk+rb5KMCA8aM0iwpWV6dwP9+fXuFerm3yMs0jDOysY8wr5FTldeoWKabgEJ8RATG+IeIdsn2NUqLjQ3OgBDumC3SbRMsVKsValZplydZpZpbJOQco2KdYeEe36BDAL8/vgHBfr2CvTyDu8R7esU6RcZ5ecc4+Af3iLcJSjZ1ivT0S/PMs3LNck4x8U7wz7Bv0G9RLtHuEq1TbJQr1OtVqqnWqRdoqBhnmSbZ5mXapRtcJGOc4t2eYiFfH9AS7qYlgAAARlJREFUKM9jqK9fEGS7VNrDI2+F/nyB1Z4Fa5UKN4TbbeLY7FW0Tatkp3jp7mj7vXzl+4yrDsYoVx+JYz7mXXNSp/a0RN25JMcLPP8umzRcTZW77tNyk63tdprzXdmO+2ZdD9MFe56Y9z3LUG96mcX02n/CW71JH6Qmf8px/cw77ZvVzB+BCj8D5vxhn/vXZh6D4uzf1rN+Cc347j79q/zUL25TPrJMfG/5LvuNZP8rixeZz/mf+vU+Vut+5NL5gPOeb/sd1dZbTs03hBuvmV5JuaRyMfk849nEM7qnEk6IHI8/qn049hB35QGHiv0yZXuMdkXtYC3ebrglcqvYxoj1muvC1nDlrzJYGbpcdHHIMo2FwYv+j3QAAOBSfkZYITwUAAAAAElFTkSuQmCC);
      background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(white), color-stop(50%, white));
      background: -moz-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
      background: -o-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
      background: -ms-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
      background: linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
    }
  }

  .row {
    border-bottom: 1px solid @bg-component;
    .flex-justify-align(flex-start, center);

    > div {
      display: inline-block;
    }

    .label {
      width: 220px;
      font-weight: 600;
    }

    .label.short {
      width: 140px;
    }

    .value {
      width: 160px;
      margin: 0;
    }

    .value.first {
      width: 80px;
    }

    .value.last {
      width: 190px;
    }
  }

  .head {
    > div {
      font-weight: 600;
    }
  }
}

.map-container {
  &.custom-draw-toolbar {
    div div.leaflet-control-container div div.map-control-center-bottom {
      margin-bottom: 20px;
      box-sizing: border-box;
      display: block;
      margin-left: -50%;

      .leaflet-draw-section div.leaflet-draw-toolbar {
        display: none;
      }

      .leaflet-draw-section ul.leaflet-draw-actions {
        position: relative;
      }
    }
  }

  .ils-map-toolbar {
    position: absolute;
    top: 0;
    right: 5px;

    .map-draw-btn .leaflet-draw-toolbar {
      position: relative;
      bottom: 4px;
      right: 4px;
      margin: 0;
      padding: 0;

      .leaflet-draw-toolbar .ils-toolbar-draw-icon {
        position: absolute !important;
        bottom: 4px !important;
        right: 4px !important;
      }
    }
  }

  .drag-container {
    position: absolute !important;
    z-index: @z-index-drag-container;
    width: 20px;
    height: 20px;
  }

  .ddcontainer {
    width: 100%;
    height: 100%;
  }

  .ddcontainerghost .container {
    width: 1px;
    height: 1px;

    .drag {
      display: block;
      min-width: 16px;
      min-height: 16px;
      border: 4px solid @color-error;
      border-radius: 50%;
      margin: 15px 0 0 15px;
    }
  }

  .drag-container .container.selected {
    .drag {
      border-color: @color-warning;
    }
  }

  .ddcontainerghost .point {
    .drag {
      border-color: @color-warning;
    }
  }

  .ddcontainerghost .point.triangle {
    .drag {
      border-radius: 0;
      transform: scale(var(--ggs, 1));
      width: 24px;
      height: 17px;
      border-left: 4px solid transparent;
      border-bottom: 4px solid @color-warning;
      border-top-width: 0;
      border-right-width: 0;
    }

    .drag,
    .drag::before {
      display: block;
      box-sizing: border-box;
      border-right: 3px solid transparent;
    }

    .drag::before {
      content: '';
      position: absolute;
      width: 22px;
      height: 22px;
      border-left: 4px solid @color-warning;
      border-top: 4px solid @color-warning;
      border-bottom: 4px solid transparent;
      transform: rotate(45deg) skew(10deg, 10deg);
      left: -2px;
      bottom: -13px;
    }
  }

  .ddcontainerghost .point.square {
    .drag {
      border-radius: 0;
      background: #fff;
    }
  }

  .ddcontainerghost .point.circle {
    .drag {
      background: #fff;
    }
  }

  .ddcontainerghost .order-cluster {
    .drag {
      border: none;
      background: rgba(245, 82, 82, 0.4);
      width: 22px;
      height: 22px;
    }

    .drag:after {
      content: '';
      width: 18px;
      height: 18px;
      background: rgba(245, 82, 82, 0.6);
      display: block;
      border-radius: 50%;
      margin: 2px;
      position: relative;
      top: 2px;
    }
  }

  .ddcontainerghost .order-cluster.selected {
    .drag {
      background: rgba(255, 166, 5, 0.9);
    }

    .drag:after {
      background: rgba(255, 166, 5, 0.6);
    }
  }
}

.marker-dot {
  width: 10px;
  height: 10px;
  .absolute-center();
}

.track-marker {
  width: 35px;
  height: 35px;
  position: relative;

  .marker {
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    bottom: 100%;
    font-size: 12px;
    line-height: 16px;
    width: auto;
    padding: 3px;
    box-shadow: 0 0 2px rgb(0 0 0 / 30%);
    border-radius: 4px;
    top: 7px;
    left: 0;
    height: 21px;

    .title {
      border-bottom: 1px solid #ddd;
      white-space: nowrap;
    }

    .data {
      //display: inline-block;
      white-space: nowrap;
    }
  }

  .arrow {
    width: 8px;
    height: 8px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: @z-index-primary;
  }

  .arrow::after {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 2px rgb(0 0 0 / 30%);
    margin-top: -70%;
  }
}

.track-marker.big {
  height: 63px;

  .marker {
    min-width: 100px;
    height: 57px;
    top: 0;
    padding: 5px;
  }
}

.car-icon {
  width: 50px;
  height: 50px;
  padding: 5px;
  background: @color-primary;
  border-radius: 50%;

  svg {
    width: 100%;
    height: 100%;
  }
}

.truck-icon {
  width: 50px;
  height: 50px;
  padding: 5px;
  background: transparent;
  border-radius: 50%;

  svg {
    width: 100%;
    height: 100%;
  }
}

.leaflet-overlay-pane {
  z-index: @z-index-leaflet-overlay-pane;

  svg {
    filter: drop-shadow(rgb(0, 0, 0, 0.75) 1px 1px 1px);
  }
}

.marker-cluster {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(114, 145, 255, 0.4);
  span {
    line-height: normal;
    text-shadow: 0 0 5px black, 0 0 3px black, 0 0 1px black;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: -2px 0 0 0;
  }
}

.marker-cluster div {
  background-color: rgba(114, 145, 255, 0.6);
}

.marker-cluster.ils-marker-orders {
  background-color: unset;
}

.marker-cluster.ils-marker-orders div {
  background-color: unset;
}

.marker-cluster.ils-marker-orders.selected {
  border-radius: 50px;
}

.marker-cluster.ils-marker-orders.selected div {
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  border-radius: 50px;
  background-color: rgba(255, 166, 5, 0.6);
}

.marker-cluster.ils-marker-clients {
  background-color: rgba(27, 100, 209, 0.4);
}

.marker-cluster.ils-marker-clients div {
  background-color: rgba(27, 100, 209, 0.6);
}

.marker-cluster.ils-marker-storages {
  background-color: rgba(136, 27, 209, 0.4);
}

.marker-cluster.ils-marker-storages div {
  background-color: rgba(136, 27, 209, 0.6);
}

.map-container.dragging {
  path.leaflet-interactive:not(.active):not([stroke-width='4']):not([stroke-width='5']):not([stroke-width='6']) {
    stroke-width: 3;
  }
}

.marker-dot {
  width: 10px;
  height: 10px;
  .absolute-center();
}

.leaflet-control-container .leaflet-ruler-clicked {
  height: 35px;
  width: 35px;
  background-repeat: no-repeat;
  background-position: center;
  /*background-image: url("../dist/icon-colored.png");*/
  border-color: @color-primary !important;
}

.leaflet-bar {
  background-color: #ffffff;
}

.leaflet-control {
  cursor: pointer;
}

.result-tooltip {
  font-size: 14px;
  background-color: white;
  border: 1px solid @color-normal;
}

.moving-tooltip {
  background-color: rgba(255, 255, 255, 0.7);
  background-clip: padding-box;
  opacity: 0.5;
  border: 1px solid @color-normal;
  font-size: 14px;
}

.plus-length {
  color: @color-darkgrey;
}

.generate-rotate-icons-rules(@n, @i: 0) when (@i =< @n) {
  .icon-rotate-@{i} {
    transform: rotate(unit(@i, deg));
  }

  .generate-rotate-icons-rules(@n, (@i + 1));
}

.generate-rotate-icons-rules(359);

.leaflet-draw-toolbar.leaflet-bar {
  transform: scale(1.2);
}

.leaflet-draw-toolbar.leaflet-bar.leaflet-draw-toolbar-top {
  margin: 10px 0 25px 0;
}

.arrow-icon {
  text-align: center;

  svg {
    filter: drop-shadow(rgb(0, 0, 0, 0.75) 1px 1px 1px);
  }
}

.event-icon {
  svg {
    border: 2px solid;
    border-radius: 25px;
    padding: 2px 2px 2px 4px;
    background-color: white;
    width: 25px;
    height: 25px;
  }

  &.turn {
    svg {
      fill: orange !important;
      border-color: orange;
    }
  }

  &.acc {
    svg {
      fill: orange !important;
      border-color: orange;
    }
  }

  &.braking {
    svg {
      fill: orange !important;
      border-color: orange;
    }
  }
}

.div-custom-marker-relative {
  position: relative;

  .span-custom-marker-abs {
    position: absolute;
    top: -2px;
    right: 16px;
  }

  svg {
    margin-top: -2px;
    margin-left: -2px;
    object-fit: cover;
  }

  .icon {
    position: absolute;
  }

  .home {
    top: 11px;
    width: 20px;
    height: 20px;
    left: 11px;
  }
}


@primary-color: #4C91FF;