@import '@styles/default.less';

.templates-container {
  height: 40vh;
  margin-bottom: 20px;
}

.dependencies-form {
  .flex-layout-vertical(start, start);

  &-button {
    width: 100%;
    height: fit-content;

    span {
      white-space: break-spaces;
    }
  }
}

.spinner-container {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.5);
}

.m-t-a {
  margin-top: auto;
}

.template-viewer-placeholder {
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.template-block {
  border: 1px solid @link-color;
  cursor: pointer;
  height: 35px;
  line-height: 35px;
  padding: 0 10px;
  .flex-layout-horizontal(start);

  &.active {
    background-color: @color-primary;
    color: @color-white;
    border-color: @color-primary;
  }

  &.hover {
    border-color: @color-primary;
  }

  &:not(:last-child) {
    border-bottom: none;
  }
}

@primary-color: #4C91FF;