@import '@styles/default.less';

@demand-button-radius: 6px;
@order-select: #fffadd;

.demand-button() {
  button {
    border-radius: @demand-button-radius;
  }
}

.demand-form() {
  width: 100%;
  padding: 1em;

  .flex-layout-vertical(start, center, @gap-em);
  flex-wrap: wrap;

  box-shadow: 0 2px 16px #1314161f;
  background: @color-white;

  border-radius: @demand-button-radius;
}

.border-lightgrey(@radius: 1px) {
  border: @radius solid @color-lightgrey;
}

.orders-and-trips {
  width: 100%;
  height: calc(100vh - @header-height);
  padding: 10px 0 10px 0;
  .flex-horizontal();

  .flex-wrap {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  h2 {
    margin-top: 15px;
    margin-bottom: 20px;
  }

  .flex-horizontal {
    .flex-horizontal();
  }

  .between-center {
    justify-content: space-between;
    align-items: center;
  }

  .ant-tabs-tab {
    &.ant-tabs-tab-active {
      border-radius: @demand-button-radius;
      background: @color-primary;
      color: @color-white;

      & .ant-tabs-tab-btn {
        color: @color-white;
      }
    }
  }

  .ant-tabs {
    width: 100%;
  }

  .fake-pre-cost {
    .demand-button();
    .flex-layout-vertical(space-between, center, @gap-em);
    width: 100%;

    .ant-tabs-content-holder {
      width: 100%;
      max-width: 80vw;
    }

    &__wrapper {
      .flex-layout-vertical(space-between, center, @gap-em);
      width: 100%;
      overflow: scroll;
      max-height: 80vh;
      padding-bottom: 1em;
    }

    .proposal-empty-image {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-flow: dense;
      gap: calc(@gap-em * 3);
    }

    &__form {
      .demand-form();

      &_project-logistic {
        overflow: scroll;
        max-width: 845px;
        max-height: 75vh;
        .flex-layout-vertical(space-between);
        flex-wrap: nowrap;
      }

      &-fieldset {
        max-width: 100%;
        max-height: 100%;
        padding: @demand-button-radius;

        .ant-form-item {
          margin: 0;
        }

        &_horizontal {
          width: 100%;
          .flex-layout-horizontal(start, start, @gap-em);
        }

        &_vertical {
          .flex-layout-vertical(start, start, @gap-em);
        }

        &_contact {
          min-width: 200px;
        }

        &-legend {
          .flex-layout-horizontal(space-between);
        }
      }

      &-item {
        margin: 0;
        min-width: fit-content;
        width: 30%;

        &_address {
          width: 50%;
        }

        &.checkbox {
          align-self: end;
        }

        &_project-logistic {
          width: 60%;
        }
      }
    }

    &__card {
      .reset-full();

      &-filters {
        display: flex;
        align-self: end;
        gap: @gap-em;

        &-button {
          display: flex;
          gap: @gap-primary;
          padding-right: 28px;
          &-icon {
            visibility: hidden;
            &.visible {
              visibility: visible;
            }
            &.desc {
              transform: rotate(180deg);
            }
          }
          &.active {
            color: @color-primary;
            border-color: @color-primary;
            svg {
              fill: @color-primary;
            }
          }
          &.active .children {
            svg, path {
              stroke: @color-primary;
              fill: transparent;
            }
          }

          .children {
            display: flex;
            flex-direction: row;
            gap: 0.5em;
            p {
              margin: 0;
            }
          }
        }
      }

      &-list {
        .reset-full();
        padding: 1em 0;
        width: 100%;
      }

      &-item {
        display: grid;
        grid-template-columns: 130px 1fr 150px 150px 150px;
        gap: @gap-em;
        align-items: center;
        justify-items: center;
      }
    }

    &__button {
      &_search {
        .flex-layout-horizontal(center, center, @gap-em);
        //align-self: end;
        height: 35px;

        .ils-svg {
          svg {
            width: calc(35px / 2);
            height: calc(35px / 2);
          }
        }
      }
    }
  }

  .orders-action-buttons {
    gap: 10px;
    padding: 10px 0;
    margin-bottom: 10px;
  }

  .unresolved-orders-header {
    padding-right: 15px;
    padding-left: 10px;
    align-items: center;
    .ils-title {
      color: @color-darkgrey;
      text-align: left;
      font-size: 1.5em;
    }
  }
  .ils-title {
    width: 100%;
    text-align: center;
    padding: 10px 0 20px;
  }

  &-scroll {
    height: calc(100vh - 150px);
    overflow: auto;
    min-width: 790px;
    padding-right: 10px;
    width: 100%;
    & > .ant-tabs {
      overflow-x: hidden;
      .ant-tabs-content {
        height: 100%;
      }
    }
    .table-config {
      padding: 10px 5px 5px;
    }
  }

  &-left {
    height: 100%;
    overflow: hidden;
    width: 100%;

    .order-card {
      background: #fff;
      border: 3px solid #e9ecef;
      cursor: pointer;
      border-radius: 5px;
      margin-bottom: 10px;
      padding: 10px 15px;
      &.active {
        border: 3px solid @color-primary;
      }
      &.select {
        background: @order-select;
      }
      .info,
      .depot,
      .cargo {
        margin-bottom: 10px;
      }
      .info {
        .flex-horizontal();
        justify-content: space-between;
        padding-bottom: 5px;
        border-bottom: 1px solid @color-lightgrey;
        &-number {
          .flex-horizontal();
          align-items: center;

          & > :first-child {
            margin-right: 10px;
          }
        }
        &-number,
        &-date {
          color: #7e7e7e;
          font-weight: 500;
          .flex-horizontal();
          & .ant-checkbox {
            margin-right: 10px;
          }
          & > span {
            font-weight: 600;
            color: @color-primary;
            padding: 0 5px;
          }
        }
      }
      .depot {
        .flex-horizontal();
        color: @color-primary;
        margin-bottom: 8px;
        &-source {
          margin-right: 20px;
        }
        &-target {
          margin-left: 20px;
        }
      }
      .cargo {
        .flex-horizontal();
        align-items: center;
        gap: 10px;
        color: @color-primary;
        span {
          //font-size: 1.05rem;
          font-weight: 800;
        }
        &-title {
          text-decoration: underline;
          color: @color-darkgrey;
        }
        &-mass,
        &-volume,
        &-restriction,
        &-amount {
          .flex-horizontal();
          align-items: center;
          justify-content: center;
          gap: 5px;
          padding-right: 10px;
          border-right: 1px solid @color-lightgrey;
          &:last-child {
            border: none;
          }
        }
      }
    }
  }

  &-right {
    flex: 0 0 40%;
    height: 100%;
    margin-left: 10px;
    background: @color-white;
    border: 1px solid @color-lightgrey;
    padding: 0 10px;
    overflow-x: hidden;
    max-height: 85vh;
  }
  .empty-component {
    .flex-horizontal();
    width: 100%;
    height: 80vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      font-size: 26px;
      color: @color-darkgrey;
    }
    img {
      width: 150px;
    }
  }
  .flex-horizontal();
  &-collapse {
    background: @color-white;
    border: 1px solid @color-lightgrey;

    &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
      color: #001e39;
      .anticon {
        color: @color-error;
      }
    }

    &.ant-collapse:not(.main-collapse) > .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header {
      color: #001e39;
      background: @color-white;
    }
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
  input[disabled] {
    color: rgba(0, 0, 0, 0.6);
  }

  &-filters {
    margin-bottom: 10px;
  }

  &-unresolved {
    margin-bottom: 10px;
    position: relative;
    .action-buttons {
      position: absolute;
      top: 8px;
      right: 15px;
      button {
        margin-right: 10px;
      }
    }
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: @color-error;
  }
  .ant-radio-inner::after {
    background-color: @color-error;
  }

  .ant-tabs-tab-btn[aria-selected='false'] {
    color: @color-primary;
  }

  .switch {
    &-block {
      .ant-radio-wrapper {
        margin: 3px;
      }
    }
    &-container {
      .ant-radio-wrapper {
        margin: 3px;
      }
      .ant-radio-button-wrapper {
        color: @color-darkgrey;
      }
      .ant-radio-button-wrapper {
        line-height: 28px;
        padding: 8px;
        height: 30px;
        &-checked {
          color: @color-white;
        }
      }
      margin-bottom: 15px;
      .trip-status-menu {
        margin-bottom: 5px;
        button {
          margin-right: 5px;
        }
        label {
          margin-bottom: 5px;
        }
      }

      .tender-status-menu {
        &-buttons {
          label {
            margin-bottom: 5px;
          }
        }
      }
    }
    &-title {
      font-weight: 600;
      min-width: 150px;
      max-width: 150px;
      padding-right: 20px;
      text-align: right;
    }
  }

  .right-container {
    .ant-tabs-nav {
      margin-bottom: 20px;
    }

    .search-performer {
      margin-bottom: 10px;
    }
  }
  .trip-card {
    background: @color-white;
    border: 3px solid @color-lightgrey;
    cursor: pointer;
    border-radius: 5px;
    position: relative;
    &.active {
      border: 3px solid @color-primary;

      &.new {
        border: 3px solid @color-error;
      }
    }
    margin-bottom: 10px;
    &-actions {
      .flex-horizontal();
      flex-wrap: wrap;
      width: 100%;
      &-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        padding: 10px;
        .trip-id {
          text-align: right;
          color: @color-inactive;
        }
      }
      .action {
        margin-bottom: 10px;
        max-width: 380px;
        min-width: 380px;
      }
    }
    .action {
      &-arrival {
        .flex-horizontal();
      }
      &-type,
      &-city {
        font-weight: 600;
        margin-bottom: 2px;
        color: @color-darkedgrey;
      }
      &-cargo-type {
        margin-bottom: 5px;
        color: @color-primary;
        text-decoration: underline;
      }
      &-address {
        color: @color-primary;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &-direction {
        .flex-horizontal();
        align-items: center;
        padding: 0 20px;
      }
      .arrival {
        color: @color-primary;
        &-date {
          margin-right: 10px;
        }
      }
    }

    &-details {
      display: grid;
      grid-template-columns: repeat(5, minmax(100px, 1fr));
      grid-template-rows: auto;
      grid-template-areas:
        'distance distance vehicle vehicle loading'
        'tonage tonage volume price price'
        'trip-status trip-status trip-status trip-status trip-status';
      grid-auto-flow: dense;
      gap: @gap-em;

      .distance,
      .vehicle,
      .loading,
      .tonage,
      .volume,
      .trip-status,
      .price {
        font-weight: 800;
        color: @color-primary;
        border-right: 1px solid #758ca3;
        .flex-layout-horizontal(start, center);

        &-icon {
          margin-right: 10px;
        }
      }

      .distance {
        grid-area: distance;
      }

      .vehicle {
        grid-area: vehicle;
      }

      .loading {
        grid-area: loading;
        border: none;
      }

      .tonage {
        grid-area: tonage;
      }

      .volume {
        grid-area: volume;
      }

      .trip-status {
        grid-area: trip-status;
        border: none;
      }

      .price {
        grid-area: price;
        border: none;
      }

      .trip-status {
        &-now {
          span {
            font-weight: 600;
            color: @color-primary;
          }
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .action-button,
  .switch-block {
    /*background: @color-info;
    border: 1px solid @color-info; */
    display: flex;
    align-items: center;
    &.black,
    .ant-radio-button-wrapper-checked {
      /*background: #001e39;
      border: 1px solid #001e39;*/
    }
    &.width-190 {
      width: 180px;
      span {
        text-align: center;
        width: 100%;
      }
      svg {
        font-size: large;
      }
    }
    &.fetch-all {
      margin-right: 17px;
    }
  }
  .switch-block {
    font-weight: 500;
  }

  .transport-company {
    width: 100%;
    min-width: 490px;
    .header {
      .flex-horizontal();
      background: @bg-body;
      color: @color-darkgrey;
      font-weight: 600;
      justify-content: space-between;
      border-top: 1px solid @color-lightgrey;
      &-logo {
        border-left: 1px solid @color-lightgrey;
      }
      &-name,
      &-logo,
      &-price,
      &-action {
        flex: 1;
        height: 30px;
        line-height: 30px;
        padding: 0 20px;
        border-right: 1px solid @color-lightgrey;
        min-width: 120px;
      }
      &-action {
        min-width: 211px;
      }
    }
    &-container {
      .border-lightgrey();
      .company {
        .flex-horizontal();
        justify-content: space-between;
        align-items: stretch;
        border-bottom: 1px solid @color-lightgrey;

        &:last-child {
          border-bottom: none;
        }

        &-name,
        &-img,
        &-info,
        &-action {
          flex: 1;
          padding: 10px;
          align-items: center;
          display: flex;
          border-right: 1px solid @color-lightgrey;
          min-width: 120px;
        }

        &-action {
          min-width: 210px;
          border-right: none;
        }

        &-name {
          font-weight: 500;
        }

        &-info {
          align-items: flex-start;
          flex-direction: column;
        }
        &-price {
          color: @color-primary;
        }
        &-description {
          line-height: 1;
        }
        &-action {
          .flex-horizontal();
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .order-action-form {
    h2 {
      text-align: center;
    }
    .ant-form-item {
      margin-bottom: 10px;
    }
    overflow: hidden;
    padding: 5px;
    &-timeline {
      width: 100%;
      &.ant-timeline.ant-timeline-label {
        .ant-timeline-item-label {
          text-align: left;
          width: 80px;
          font-weight: bold;
        }
        .ant-timeline-item-tail,
        .ant-timeline-item-head {
          left: 85px;
        }
        .ant-timeline-item-left .ant-timeline-item-content {
          width: calc(100% - 95px);
          left: 70px;
          padding-top: 5px;
          padding-left: 10px;
        }
      }
      .timeline-svg {
        div {
          width: 32px;
          height: 32px;
        }
      }
    }
    &-location {
      width: 100%;
      .ant-form-item {
        margin-right: 10px;
        width: 100%;
      }
    }
    &-submit {
      .flex-horizontal(@gap-em);
      align-items: center;
      justify-content: flex-end;
      button {
        .flex-horizontal(@gap-primary);
        align-items: center;
        justify-content: center;
        margin: 5px;
      }
    }
    &-cargo {
      width: calc(100% - 20px);
      margin-bottom: 10px;

      .order-cargo {
        &-params {
          .ant-form-item {
            width: 30%;
          }
        }
      }
    }
    &-date {
      margin-bottom: 10px;
      width: 100%;
      justify-content: space-between;
      & > div {
        width: 100%;
        margin-right: 10px;
      }
    }

    .transport-requirement {
      h3 {
        text-align: center;
      }
      .vehicle-options,
      .additional-options {
        position: relative;
        left: 110px;
        width: calc(100% - 110px);
        &-body {
          justify-content: space-between;

          & > div {
            width: 30%;
            margin-right: 15px;
          }
        }
        .flex-horizontal.group {
          & > div {
            margin-right: 10px;
          }
        }
      }
    }

    .target-cargo {
      gap: 20px;
      & > div {
        width: 100%;
      }
    }
  }

  .tender-trip-form {
    padding: 0 10px 20px;
    h2 {
      text-align: center;
    }
    .ant-form-item {
      flex-direction: row;
      margin-bottom: 15px;
    }
    .ant-form-item-label {
      min-width: 240px;
      max-width: 240px;
      margin-right: 10px;
    }
    &-submit {
      text-align: right;
      button {
        margin: 20px 5px;
      }
    }
  }

  .documents-container {
    padding: 0 10px;
    color: @color-black;
    .documents-upload-dropzone {
      &__heading {
        .flex-layout-horizontal();
        font-weight: @fw-600;
      }
      &_disabled {
        color: @color-inactive;
      }
    }
    .documents-upload {
      &-list {
        .flex-vertical(@gap-em);
        padding-top: @gap-em;
      }
      &-card {
        .flex-horizontal();
        justify-content: space-between;
        align-items: center;
        padding: 4px;
        border-radius: 2px;
        border: @border-primary;
        &__group {
          .flex-horizontal(@gap-em);
          align-items: center;
        }
        &__extension,
        &__name {
          margin: 0;
        }
        &__extension {
          min-width: 36px;
          font-size: 12px;
          font-weight: @fw-600;
          text-transform: uppercase;
          color: @color-primary;
          pointer-events: none;
        }
        &__name {
          .overflow-ellipsis();
        }
      }
    }
  }

  .trip-timeline {
    color: @color-black;
    &__rows {
      .flex-vertical(@gap-primary);
    }
    &__created {
      color: @color-darkgrey;
      width: 190px;
    }
    &__title {
      flex-grow: 1;
    }
    &__creator {
      max-width: 30%;
    }
    &__headers {
      padding-bottom: @gap-primary;
      margin-bottom: @gap-primary;
      border-bottom: @border-primary;
      .trip-timeline__created,
      .trip-timeline__title,
      .trip-timeline__creator {
        font-weight: @fw-600;
      }
    }
  }

  .details {
    padding: @gap-primary;
    color: @color-black;

    &-header {
      &__columns {
        .flex-horizontal(@gap-em);
        justify-content: space-between;
      }

      &__column {
        .flex-vertical(@gap-primary);
      }
      &__title,
      &__value {
        font-weight: @fw-600;
      }
      &__value {
        color: @color-primary;
      }
      &-title-group {
        .flex-horizontal(@gap-em);
        justify-content: space-between;
        align-items: center;
      }
      &-buttons {
        .flex-horizontal(@gap-em);
      }
      &-delete-button {
        .flex-horizontal(@gap-em);
      }
    }

    &-content {
      .flex-vertical(@gap-em);
      &-header {
        .flex-horizontal();
        justify-content: space-between;
        align-items: center;
      }
      &-distance,
      &-buttons {
        .flex-horizontal(@gap-em);
      }
      &-button {
        .flex-horizontal(@gap-em);
        font-weight: @fw-500;
        &.active {
          color: @color-primary;
          border: @border-active;
        }
      }
      &-general {
        .flex-horizontal();
        padding: @gap-em 0;
      }
      &-group {
        .flex-horizontal(@gap-primary);
        padding: 0 10px;
        border-right: 1px solid @color-darkgrey;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          border-right: none;
        }
      }
    }

    &-map {
      width: 100%;
      height: 500px;
    }
    &-route {
      .order-action-form-timeline.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
        color: @color-darkgrey;
        padding: 0 20px;
      }
    }
  }
  .orders-and-trips-virtual-box {
    height: calc(100vh - 310px);

    .rc-virtual-list-holder {
      scrollbar-width: thin;
      scrollbar-color: @color-gray;
      padding: 0 5px;
    }

    /* Works on Chrome, Edge, and Safari */
    .rc-virtual-list-holder::-webkit-scrollbar {
      height: 0;
      width: 8px;
    }

    .rc-virtual-list-holder::-webkit-scrollbar-track {
      background: none;
    }

    .rc-virtual-list-holder::-webkit-scrollbar-thumb {
      background-color: @color-gray;
    }
  }
  .ant-form-item-label {
    label {
      font-weight: 500;
    }
  }
}

.external-transport-contract {
  &-container {
    .flex-vertical(@gap-em);
    min-height: 70vh;
    width: 60vw;
  }
  &-spinner {
    width: 100%;
  }
  &-buttons {
    .flex-horizontal(@gap-em);
    justify-content: flex-end;
  }
}

.orders-and-trips-export-action {
  display: flex;
  padding: 0;
  margin-bottom: 10px;
}


@primary-color: #4C91FF;