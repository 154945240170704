@import '@styles/default.less';

.avatar() {
  width: 30px;
  height: 30px;
}

.ant-layout-header.ils-header {
  .flex-layout-horizontal(space-between, center, @gap-rem);
  background-color: @color-white;
  max-height: @header-height;
  height: @header-height;
  min-height: fit-content;
  overflow-y: hidden;
  padding: @gap-rem;
  box-sizing: border-box;

  .current-user {
    .flex-layout-horizontal(space-between, center, @gap-rem);

    &-avatar {
      .avatar();

      &-empty {
        svg {
          display: block;
        }
      }
    }

    &-name {
      line-height: 16px;
    }

    &-logout,
    .show-questions-button {
      border-color: transparent;
      box-shadow: none;

      svg {
        width: 25px;
        height: 25px;
      }
    }

    &-orders-and-trips {
      .flex-horizontal(@gap-em);
      align-items: center;
    }
  }
}

@primary-color: #4C91FF;