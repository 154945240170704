@import '@styles/default.less';

@tab-header-height: 70px;
@card-actions-width: 40px;

.account {
  background-color: @color-white;

  &-form {
    &.account {
      width: 100%;
      max-height: calc(@main-height - 130px);
      .extra-attachments {
        &-facsimile {
          display: flex;
          padding: 20px 0;
          & > div {
            margin-right: 20px;
          }
          .ant-upload {
            padding: 0 15px;
            line-height: 1;
            height: 90px;
          }
          .attachment-document {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 10px;
            background: beige;

            &-name {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }

    &.account {
      .account-form-item {
        margin-top: @gap-rem;
      }
    }

    &.user {
      .flex-layout-vertical(start, start, @gap-rem);
      padding-right: 24px;

      .form-buttons {
        .flex-layout-horizontal(space-between, center);
        width: 100%;
      }
    }

    &-item {
      .reset-full();
      width: 330px;
      max-width: 100%;

      h3 {
        .margin-reset();
      }
    }

    .code {
      .flex-layout-horizontal(start, start, @gap-primary);

      &-copy {
        width: 330px;
        min-width: fit-content;
      }
    }
  }

  &-tab {
    .flex-layout-vertical(start, start, @gap-rem);
    padding: @gap-rem;
    height: calc(@main-height - @tab-header-height);

    .users {
      .flex-layout-horizontal(start, start, @gap-em);
      flex-wrap: wrap;
      width: 100%;
      margin-top: @gap-em;

      .user {
        width: 300px;
        &-info {
          .no-margin();
          max-width: 160px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: inherit;
        }

        &.active {
          border: @border-active;
        }
      }
    }

    .ant-input[disabled] {
      color: @color-black;
    }

    .blocked {
      background: red;
      border: none;
    }

    .actions {
      .flex-layout-horizontal(space-between, center);
      width: @card-actions-width;

      button {
        min-width: 26px;
        width: 26px;
        height: 26px;

        .svg-container {
          width: 16px;
          height: 16px;
        }

        svg,
        svg > path {
          fill: @color-white;
        }
      }
    }

    .avatar-upload {
      width: 120px;
      height: 120px;
      overflow: hidden;

      > div,
      .ant-avatar,
      span.ant-upload-picture-card-wrapper,
      .ant-upload-list-picture-card-container,
      .ant-upload,
      .ant-upload-list {
        width: 100% !important;
        height: 100% !important;
      }

      .circle {
        .ant-upload-list,
        .ant-upload-list-item,
        .ant-upload-list-item-info,
        .ant-upload {
          border-radius: 50%;
        }
      }

      .saved {
        .ant-upload-picture-card-wrapper {
          z-index: @z-index-primary;
          position: absolute;
          display: none;
        }

        .ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
          bottom: 30px;
        }
      }

      > div:hover {
        .ant-upload-picture-card-wrapper {
          display: block;
        }
      }
    }

    span.ant-avatar.ant-avatar-circle.ant-avatar-icon {
      background-color: @color-normal;
      width: 50px;
      height: 50px;

      svg.injected-svg {
        margin: 25%;
        width: 50%;
        height: 50%;

        path {
          fill: @color-white;
        }
      }
    }
  }
}


@primary-color: #4C91FF;