@import '@styles/default.less';

.planning-params {
  //max-width: 1000px;
  .config-list {
    height: 100px;
  }

  &-external-config-list {
    padding: 0 @gap-em;
  }

  &-wrapper {
    height: calc(100vh - 120px);
  }

  &-main {
    height: calc(100vh - 80px);
  }

  &-max-500 {
    max-height: 500px;
  }

  .param-row {
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 3px;
    .flex-justify-align(center, center);

    .ant-input-number {
      width: 100%;
    }

    .name {
      width: calc(100% - 315px);
      white-space: normal;
    }

    .default {
      width: 150px;

      .ant-input-number-disabled,
      .ant-select-disabled.ant-select:not(.ant-select-customize-input),
      .ant-select-selector {
        background-color: @color-white;
      }
    }

    .value {
      width: 150px;
    }
  }

  .param-header {
    color: @color-white;
    background: @color-success;
    width: calc(100% + 10px);
    margin: -7px 0 0 -7px;
    padding: 5px 10px 5px 7px;

    .name {
      width: calc(100% - 325px);
    }
  }

  .sub-param-header {
    color: @color-white;
    background: @color-success;
    padding-left: 7px;
    opacity: 0.8;
  }

  .default-value {
    text-align: center;
    border-color: @color-normal;
    color: #555;
  }

  .ant-collapse-item {
    border-bottom: none !important;
    background-color: #fff !important;
    margin-bottom: 10px;

    > .ant-collapse-header {
      color: @color-black !important;
      background-color: #fff !important;

      .ant-collapse-arrow {
        font-size: 16px !important;

        svg {
          fill: @color-primary !important;
        }
      }
    }
  }

  .ant-collapse-header {
    cursor: pointer !important;
  }

  .ant-collapse-item:not(.ant-collapse-item-active) svg {
    transform: rotate(90deg);
  }

  .ant-collapse-item.ant-collapse-item-active {
    svg {
      transform: rotate(0deg) !important;
    }
  }

  .config-panel {
    div:hover {
      cursor: default;
    }

    .active {
      background: @bg-primary;
    }

    .ant-select {
      width: 100%;
    }
  }

  .import-export-btns {
    position: absolute;
    right: 15px;
    z-index: @z-index-button;
  }

  .upload-config {
    input {
      position: absolute;
      width: 100%;
      opacity: 0;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: @z-index-select;
    }
  }

  &-save-buttons {
    display: flex;
    padding: 10px 0;
    column-gap: 10px;
  }
}


@primary-color: #4C91FF;