@import '~antd/dist/antd.less';
@import '@styles/default.less';
@import '@styles/bootstrap.less';
@import '@styles/global.less';
@import '@styles/mixins.less';

@table-grouping-checkboxes-max-height: 140px;
@header-height: 65px;

//general styles
body {
  color: @color-black;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;

  > iframe:not(.html2canvas-container) {
    display: none;
  }
}

.ils-wrapper {
  background-color: @bg-body;
  height: @wrapper-height;
  overflow: hidden;
  background-size: cover !important;

  .full-content,
  .ils-content .ant-breadcrumb {
    padding: @paddings-general @padding-primary;
  }

  .auth-content {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    &__header {
      height: @header-height;
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: row;
    }

    .logo {
      position: absolute;
      top: 10px;
      left: 30px;
    }
  }

  .ils-content {
    position: relative;
    padding: 0 @padding-primary;
    height: @main-height;
  }

  .page-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  .ils-container form {
    z-index: @z-index-primary;
  }

  .ant-breadcrumb {
    margin: 0;
    padding: 1em 0;

    li {
      display: inline-block;
    }
  }

  .auth-form {
    max-width: 100%;
    width: 490px;
    text-align: center;

    input {
      background-color: @color-white !important;
    }

    .register {
      &-form {
        max-height: 100vh;
      }

      &__textfield {
        margin-bottom: 10px !important;
      }

      &__checkboxes {
        display: flex;
        align-items: center;
      }

      &__title {
        margin-right: 10px;
      }
    }

    .react-customscroll-holder {
      .flex-justify-align(center, center);

      .react-customscroll-frame {
        width: 100%;
      }
    }
  }
}

.react-customscroll {
  .react-customscroll-scrollbar {
    background: @color-scroll !important;
    border-radius: 0 !important;
  }

  .react-customscroll-scrollbar-holder {
    background: @color-white;
  }

  .react-customscroll-scrollbar-area {
    width: 10px !important;
  }
}

.ils-sidebar {
  width: 100%;
  height: 100vh;
  background-color: @bg-normal;
}

h1 {
  &.ils-title {
    font-size: @fz-title_1;
    .ils-title();
  }
}

h2 {
  &.ils-title {
    font-size: @fz-title_2;
    .ils-title();
  }
}

h3 {
  &.ils-title {
    font-size: @fz-title_3;
    .ils-title();
  }
}

h4 {
  &.ils-title {
    font-size: @fz-title_4;
    .ils-title();
  }
}

h5 {
  &.ils-title {
    font-size: @fz-title_5;
    .ils-title();
  }
}

h6 {
  &.ils-title {
    font-size: @fz-title_6;
    .ils-title();
  }
}

//customscroll
.ils-content > .react-customscroll > .react-customscroll-holder {
  > .react-customscroll-scrollbar-area {
    width: 10px !important;
  }

  > .react-customscroll-frame {
    padding-right: 10px !important;
  }
}

.react-customscroll {
  .react-customscroll-scrollbar-area {
    background: inherit !important;
    padding: 0 !important;
    z-index: @z-index-scroll;
  }

  .react-customscroll-scrollbar {
    background: #bbb !important;
  }
}

@-moz-document url-prefix() {
  .react-customscroll-holder {
    scrollbar-width: none;
    padding-right: 17px;
  }
}

button.bg-success:hover,
button.bg-warning:hover,
button.bg-error:hover,
button.bg-yellow:hover,
button.bg-info:hover {
  opacity: 0.7;
}

.text-error {
  color: @color-error;
  display: block;
  font-size: 11px;
}

.text-darkgrey {
  color: @color-darkgrey;
}

button.grey {
  background-color: @bg-component;
  color: @color-black;
  border-color: @bg-component;
}

.gap-1 {
  gap: @gap-em;
}

//containers
.h-0 {
  height: 0 !important;
}

.h-100 {
  height: 100% !important;
}

.w-0 {
  width: 0 !important;
}

.w-25 {
  width: 25% !important;
}

.w-33 {
  width: 33% !important;
}

.w-50 {
  width: 50% !important;
}

.w-67 {
  width: 67% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.map-container {
  position: relative;

  height: 100%;
  width: 100%;
}

.svg-container {
  width: 20px;
  height: 20px;

  div {
    .flex-justify-align(center, center);
  }

  div,
  svg {
    width: 100%;
    height: 100%;
  }
}

.svg-container.arrow {
  width: 15px;
  height: 15px;

  div {
    transform: rotate(90deg);
  }
}

.svg-container.arrow.active {
  div {
    transform: rotate(-90deg);
  }
}

.error-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 9px 5px;
  border: 1px solid #fff;
  font-size: 1rem;

  .error-square {
    width: 10px;
    height: 10px;
    margin-right: 7px;

    &-error {
      background: rgb(243, 79, 79);
    }

    &-warning {
      background: #faad14;
    }

    &-info {
      background: #4c91ff;
    }

    &-bad {
      background: red;
    }
  }

  &_active {
    background: linear-gradient(0deg, #e0ecff, #e0ecff), #eef3ff !important;
    border: 1px solid #4c91ff;
  }

  &:hover {
    background: #f0f8ff;
    border: 1px solid #c7e2ff;
  }
}

button .svg-container {
  width: 20px;
  height: 20px;
  max-width: 100%;
  max-height: 100%;
  margin-right: 7px;
  float: left;

  div,
  svg {
    width: 100%;
    height: 100%;
  }
}

button img.svg-container {
  width: 100%;
  height: 100%;
  display: block;
}

button.success,
button.success:hover,
button.success:focus {
  background: @color-success;
  border-color: @color-success;
}

button.success:hover,
button.success:focus {
  opacity: 0.8;
}

button.info-catalog-button {
  .color-primary();
  border-color: @color-normal;
  border-radius: 25px;
  padding: 0;
  width: 30px;
  height: 30px;

  svg {
    width: 28px;
    height: 28px;
  }
}

button.ant-btn-primary.white-icon {
  svg,
  path {
    fill: @color-white;
  }
}

.warning-icon {
  svg {
    width: 42px;
    height: 42px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

//flex containers
.d-flex {
  display: flex;
}

.flex-center-center {
  .flex-justify-align(center, center);
}

.flex-between-center {
  .flex-justify-align(space-between, center);
}

.flex-start-center {
  .flex-justify-align(flex-start, center);
}

.flex-end-center {
  .flex-justify-align(flex-end, center);
}

.flex-start-end {
  .flex-justify-align(flex-start, flex-end);
}

.flex-start-start {
  .flex-justify-align(flex-start, flex-start);
}

.flex-wrap-wrap {
  flex-wrap: wrap;
}

.flex-wrap-nowrap {
  flex-wrap: nowrap;
}

.flex-direction-column {
  flex-direction: column;
}

.button-flex-column {
  .flex-direction-column();

  button {
    min-width: 80px;
    margin-top: 5px;
  }

  .button-75 {
    .w-75();
    max-width: 200px;
  }

  button:first-child {
    margin-top: 0;
  }
}

//components styles
.ant-alert.ils-infobox {
  border-width: 0 0 0 5px;
  padding: 10px;

  .ant-alert-message {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0;
  }
}

.ant-alert.icon-right {
  flex-direction: row-reverse;

  .ant-alert-icon {
    margin: 0 0 0 4px;
  }
}

.ant-picker-cell-disabled {
  pointer-events: auto !important;
}

.ant-typography-single-line {
  white-space: pre;
}

.full-view,
.change-table,
.open-map,
.close-btn {
  position: absolute !important;
  right: 3px;
  top: 3px;
  width: 38px !important;
  height: 38px !important;
  padding: 8px !important;
  background-color: @bg-body;
  border: none !important;
  z-index: @z-index-button;

  div,
  svg {
    width: 24px;
    height: 24px;
  }
}

.close-btn-small {
  border: none !important;

  div,
  svg {
    width: 24px;
    height: 24px;
  }
}

.full-view:hover,
.change-table:hover {
  background-color: @bg-body;
}

.change-table {
  top: 50px;
}

.open-map {
  top: 100px;
}

.close-btn {
  top: 0;
}

.hidden-input {
  min-height: 0;
  height: 0;

  .ant-form-item-control-input {
    min-height: 0;
    height: 0;
    visibility: hidden;
  }
}

.table-config {
  background: @color-white;
  position: relative;
  max-width: 100%;

}

.grouping-container {
  .flex-vertical(@gap-em);
  justify-content: space-between;
  min-width: 200px;
  max-width: 300px;
  max-height: 40vh;
  min-height: 250px;

  .grouping-checkboxes {
    .flex-vertical(@gap-em);
    max-height: calc(40vh - @table-grouping-checkboxes-max-height);

    label span:nth-of-type(2) {
      .overflow-ellipsis();
    }
  }

  .grouping-buttons {
    .flex-vertical(@gap-em);
  }
}

.config-container.all {
  position: relative;

  .react-customscroll {
    max-height: calc(100% - 30px);
  }
}

//checkbox
.ant-checkbox,
.ant-checkbox-checked {
  width: 16px !important;
  height: 16px !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border: 1px solid #d9d9d9 !important;
}

.ant-checkbox-checked {
  background-color: #fff;
  border: 1px solid #d9d9d9;

  span.ant-checkbox-inner {
    border: none !important;
    width: 10px !important;
    height: 10px !important;
    top: 2px;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: hidden;
}

.ant-checkbox-checked::after {
  transform: none !important;
  animation: none !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  width: 10px !important;
  height: 10px !important;
  top: 6px;
  left: 7px;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  display: none;
}

.ant-notification-notice.confirm {
  margin-left: calc((100vw - 384px) / 2);

  .ant-notification-notice-btn {
    width: 100%;
  }
}

.notification-large {
  width: 450px;

  div div {
    div.ant-notification-notice-message {
      font-size: 1.2em;
    }

    div.ant-notification-notice-description {
      font-size: 1.2em;
    }
  }
}

.notification-bordered-left {
  border-style: solid;

  &.ant-notification-notice-error {
    border-color: fade(@color-error, 50%);
  }

  &.ant-notification-notice-warning {
    border-color: fade(@color-warning, 50%);
  }

  &.ant-notification-notice-success {
    border-color: fade(@color-success, 50%);
  }

  &.ant-notification-notice-info {
    border-color: fade(@color-info, 50%);
  }

  border-width: 0 0 0 5px;
}

.ant-message-custom-content {
  .content {
    transition: all 0.2s;

    div:not(:first-child) {
      margin-top: 5px;
    }

    .child-content {
      display: none;
    }
  }

  .content:hover {
    label {
      display: none;
    }

    .child-content {
      display: block;
    }
  }
}

//carousel
ul.slick-dots.slick-dots-top {
  top: 0;
  z-index: @z-index-carousel;
}

.ant-carousel .slick-dots li.slick-active button {
  background: @color-primary !important;
}

.ant-carousel .slick-dots li,
.ant-carousel .slick-dots li.slick-active {
  width: 25px !important;
}

.ant-carousel .slick-dots li button {
  height: 20px !important;
  width: 20px !important;
  opacity: 1 !important;
  border-radius: 50% !important;
  border: 1px solid @color-primary !important;
  background: @color-white !important;
}

//progress
.big.ant-progress,
.medium.ant-progress {
  .ant-progress-inner {
    background-color: @color-normal;
    border-radius: 0;
  }

  .ant-progress-outer {
    padding-right: 0;
  }

  .ant-progress-bg {
    height: 40px !important;
    background-color: @color-info;
    border-radius: 0;
  }

  .ant-progress-text {
    width: auto;
    font-size: 24px;
    position: absolute;
    right: 5px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 24px;
  }
}

.medium.ant-progress {
  .ant-progress-bg {
    height: 26px !important;
    background-color: @color-info;
    border-radius: 0;
  }
}

.ils-progress {
  .title {
    position: absolute;
    left: 15px;
    bottom: 0;
    top: 0;
    margin: auto;
    font-size: 20px;
    height: 30px;
    color: @color-white;
    font-weight: 600;
  }
}

.ant-picker {
  padding-left: 27px;

  .ant-picker-input {
    flex-direction: row-reverse;
    gap: 5px;
  }

  .ant-picker-suffix {
    margin-left: 0;
  }
}

.rmdp-container {
  .rmdp-shadow {
    margin-top: -10px;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    transition: margin 0.3s;
  }

  .rmdp-ep-arrow {
    display: none;
  }

  .rmdp-calendar {
    padding: 0;

    .rmdp-arrow {
      border-color: rgba(0, 0, 0, 0.25);
    }

    .rmdp-arrow-container:hover {
      .rmdp-arrow {
        border-color: rgba(0, 0, 0, 0.85);
      }

      background-color: transparent;
      box-shadow: none;
    }

    .rmdp-header {
      font-weight: 500;
      margin-top: 0;
      border-bottom: 1px solid #f0f0f0;
      padding: 0 10px;
      height: 41px;
      line-height: 40px;
    }

    .rmdp-day-picker {
      padding: 8px 12px;
    }

    .rmdp-day,
    .rmdp-week-day {
      width: 36px;
      height: 30px;
    }

    .rmdp-day span {
      border-radius: 2px;
      color: @color-black;
      bottom: 3px;
      //font-size: 14px;
      left: 6px;
      position: absolute;
      right: 6px;
      top: 3px;
    }

    .rmdp-week-day {
      font-weight: 400;
      color: @color-black;
    }

    .rmdp-day.rmdp-today span {
      border: 1px solid #4c91ff;
      background-color: transparent;
      color: @color-black;
    }

    .rmdp-day.rmdp-selected span:not(.highlight) {
      background-color: @color-primary;
      color: @color-white;
      box-shadow: none;
    }
  }
}

//collapse
.ant-collapse:not(.main-collapse) > .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header {
  background-color: @color-primary;
  color: @color-white;
}

.ant-collapse > .ant-collapse-item.ant-collapse-item-active.secondary > .ant-collapse-header {
  background: @bg-primary;
  color: @color-black;
}

.main-collapse {
  border: none !important;

  .ant-collapse-item {
    background-color: @color-white;
    margin-bottom: 10px;
    border-bottom: none !important;
    border-radius: 5px;
  }

  .ant-collapse-header {
    min-height: 45px;

    .ant-collapse-arrow {
      font-size: 16px !important;
    }

    svg {
      fill: @color-primary;
    }
  }

  .ant-collapse-item .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header {
    svg {
      fill: #fff;
    }
  }

  .ant-collapse-content-box {
    .react-customscroll-frame {
      padding-right: 6px;
    }
  }

  .ant-collapse-header {
    min-height: 45px;

    .panel-header {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      padding: 12px 16px 12px 38px;
    }
  }

  .ant-collapse-content.ant-collapse-content-active {
    margin-top: -3px;
    padding-top: 10px;
    border-top: 1px solid #f0f0f0 !important;
  }

  .ant-collapse.ant-collapse-icon-position-right .panel-header {
    padding-left: 16px;
  }

  .ant-collapse-content {
    border: none !important;
  }
}

//content menu
.ils-content-menu {
  ul.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light {
    background-color: inherit;
    border: none;

    li {
      background: @color-white;

      .anticon {
        font-size: 16px;
      }
    }

    li.ant-menu-item-selected {
      background: @bg-primary;
      color: @color-black;
    }
  }

  span.ant-menu-title-content {
    width: 100%;

    .svg-container {
      width: 10px;
      height: 14px;
    }
  }

  .dash-button {
    width: 320px;
  }
}

//form item
.ant-form-item-control {
  .ant-input-affix-wrapper {
    padding: 0 11px;
  }

  .timezone-select,
  input[type='text'],
  input[type='password'],
  input[type='number'],
  .ant-select-selector {
    min-height: 35px;
    height: 35px;
    max-height: 35px;
    border-radius: 4px;
  }

  .timezone-select {
    text-align: left;

    > div:nth-child(3) {
      height: 100%;

      > div:first-child {
        padding: 0 8px;

        > div:first-child {
          line-height: 15px;
          top: 45%;
        }

        > div:last-child {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}

//tabs
.card-container p {
  margin: 0;
}

.card-container > .ant-tabs-card .ant-tabs-content {
  margin-top: -16px;
}

.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 16px;
  background: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}

.card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}

.card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #fff;
  border-color: #fff;
}

.card-container {
  padding: 5px 10px 10px;
  overflow: hidden;
  background: #f5f5f5;

  .ant-slider {
    margin: 2px 0;
  }
}

//general
.nowrap {
  white-space: nowrap;
}

//split
.custom-gutter-horizontal {
  background: transparent !important;

  .__dbk__dragger {
    z-index: @z-index-resizer;
    background: transparent !important;
  }
}

.custom-gutter-horizontal:hover .__dbk__dragger {
  background: @color-darkgrey !important;
  z-index: @z-index-resizer;
}

.hide-gutter {
  display: none !important;
}

.__dbk__child-wrapper {
  max-width: 100%;
}

//modal
.ant-modal-root {
  .ant-modal-wrap {
    z-index: @z-index-modal;
  }

  .ant-modal-header {
    background: @color-white;
  }

  .ant-modal-body {
    background: @color-white;
  }

  .ant-modal-footer {
    background: @color-white;
  }
}

//tooltips
.ant-tooltip {
  //z-index: @z-index-tooltip;
  // NOTE: без этого свойства окно эффективности будет сжиматься, это баг
  width: fit-content;
  max-width: 800px;
  // NOTE: убирает мерцание tooltip при пересечении с родительским объектом (если у tooltip большой контент)
  pointer-events: none;
}

.ant-input-search-button {
  width: 37px;
  height: 37px;
}

//svg icons
.ils-svg {
  &.primary {
    fill: @color-primary;
    color: @color-primary;
  }

  &.success {
    fill: @color-success;
    color: @color-success;
  }

  &.error {
    fill: @color-error;
    color: @color-error;
  }

  &.white {
    fill: @color-white;
    color: @color-white;
  }

  &.green {
    fill: @color-info;
    color: @color-info;
  }

  &.silver {
    fill: @color-gray;
    color: @color-gray;

    &:hover {
      fill: @color-primary;
      color: @color-primary;
    }
  }

  &.pointer {
    cursor: pointer;

    &:hover {
      fill: lighten(@color-error, 20%);
      color: lighten(@color-error, 20%);
    }
  }

  div {
    height: 20px;
  }

  svg {
    width: inherit;
    height: inherit;
  }
}

.divider-vertical-full {
  height: 100vh;
}

.ils-order-data {
  &-summary {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
  }

  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
    background-color: #f3f3f1;
    border: 1px solid #e7e7e7 !important;
    padding: 4px 2px 0 2px;
    border-radius: 3px;
  }

  &-value {
    margin-left: 10px;
  }
}

//TODO почему фильтры планировщика в App.less
.ils-filter-data {
  .flex-layout-horizontal(start, center);
  width: 100%;

  .ant-switch {
    width: @button-icon-width;
    height: @button-icon-width;
    padding: 2px 0;
    font-size: 16px;
    border-radius: 2px;

    &-inner {
      margin: 0;
      border: none;
      display: block;
      width: @button-icon-width;
      height: @button-icon-width;

      .ils-svg {
        .flex-layout-horizontal(center, center, 0);
        width: 100%;
        height: 100%;

        div {
          width: 100%;
          display: flex;
          height: 100%;
          justify-content: center;
          align-items: center;
          padding: 2px 0 4px;

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    &-handle {
      display: none;
    }
  }

  &-select,
  &-icon {
    display: flex;
    place-content: center;
    background: transparent;
    border: none;
    width: 100%;
    height: 100%;
    border-radius: 3px;

    &.active {
      background-color: @color-primary;
    }
  }

  &-select {
    width: 200px;
    background-color: #fff;
    border: none !important;
  }
}

.ils-approved-orders,
.ils-choosed-vehicle {
  display: flex;
  align-items: center;

  .ils-approve-item,
  .ils-vehicle-btn {
    display: flex;
    justify-content: space-between;
    background-color: white;
    z-index: @z-index-button;
    margin-right: 10px;

    span {
      margin-left: 10px;
    }

    svg {
      width: 28px;
      height: 28px;
    }
  }

  .ils-approve-item {
    background-color: @color-warning-primary;
    border: 1px solid @color-table-highlight-primary !important;
  }
}

.ils-scroll-header {
  .flex-layout-horizontal(space-between, center, @no-gap);
  .ils-content-scroll(auto, hidden);
  width: 100%;
  cursor: default;

  &-buttons {
    .reset-full();
    .flex-layout-horizontal(start, center);
    .ils-content-scroll(auto, hidden);
    min-width: 170px;
  }
}

.ils-table-row-active-select {
  background: @color-warning-primary;
}

.active-row-custom {
  background: @color-warning-primary !important;
}

.table-tip {
  .tip-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__head {
      display: flex;
      align-items: center;
      flex: 1;
      border-bottom: 1px dotted #333;
    }

    &__tr {
      display: flex;
      align-items: center;
      flex: 1;
      border-bottom: 1px dotted #333;

      &:last-child {
        border-bottom: none;
      }
    }

    &__th {
      border-right: 1px dotted #333;
      padding: 5px 10px;

      &:last-child {
        border-right: none;
      }

      width: 100px;

      &:first-child {
        flex: 1;
        min-width: 400px;
        max-width: 400px;
      }
    }

    &__td {
      border-right: 1px dotted #333;
      padding: 5px 10px;
      box-sizing: border-box;
      height: 100%;

      &:last-child {
        border-right: none;
      }

      width: 100px;

      &:first-child {
        flex: 1;
        min-width: 400px;
        max-width: 400px;
      }
    }
  }
}

.text-center {
  width: 100% !important;
  text-align: center;
}

.ant-table {
  &-thead > tr > th {
    position: relative;
    overflow: hidden;
  }
  &-column {
    &-title {
      position: static;
    }
  }
}

.text-wrap {
  text-overflow: ellipsis;
  white-space: normal;
}

.custom-ul-for-tooltip {
  padding: 0;

  li {
    list-style-type: none;
    font-size: 13px;
    margin-bottom: 3px;
  }
}

.custom-ul-for-graph-tooltip {
  padding: 0;

  .custom-li-time-for-graph-tooltip {
    list-style-type: none;
    font-size: 13px;
  }

  li {
    font-size: 12px;
    margin-bottom: 5px;
    padding-left: 0;
  }

  .custom-span-for-graph-tooltip {
    color: black;
  }

  .marg {
    float: right;
  }
}


@primary-color: #4C91FF;