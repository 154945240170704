@import '@styles/default.less';
@import '@modules/analytics/common/styles.less';

.analytics-tender {
  &-board-date {
    margin-left: auto;
    margin-right: 20px;
  }

  &-component {
    display: flex;
    justify-content: space-between;
    height: 80vh;

    &-board {
      width: 100%;
    }

    .boards-list {
      width: 347px;

      .item-list {
        max-height: calc(80vh - 190px);
        overflow-y: auto;
      }

      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;

        .edit {
          width: 24px;
          height: 24px;

          background: #ffffff;
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
          border-radius: 2px;

          .svg-container {
            width: 14px;
            height: 14px;
            fill: #4c91ff;
          }
        }
      }

      &-item {
        padding: 0 12px;
        position: relative;
        transition: 0.2s all;
        cursor: pointer;

        &:first-child {
          &::before {
            content: '';
            background: @color-normal;
            height: 1px;
            position: absolute;
            top: 0;
            left: 16px;
            right: 16px;
          }
        }

        &:hover {
          background: @color-normal;
        }

        &:not(:last-child) {
          &::after {
            content: '';
            background: @color-normal;
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 16px;
            right: 16px;
          }
        }
      }

      &-selected-item {
        background: @selected-item-color;
      }
    }
  }

  &-board {
    &-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .export-button {
        margin-bottom: 7px;
        background-color: @primary-color;
        color: @color-white;
      }
    }

    &-subtitle {
      color: #232323;
      font-size: 16px;
      font-weight: 600;
      position: relative;
      width: auto;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -6px;
        right: 0;
        background: #ececec;
        height: 1px;
      }
    }

    &-button {
      opacity: 0.8;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      width: @button-icon-width;
      height: @button-icon-width;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .svg-container {
        width: 14px;
        height: 14px;
        fill: #4c91ff;
      }

      &:hover {
        opacity: 1;
      }
    }

    &-view {
      padding: 16px 0 16px 0;
      height: 100%;
      width: 100%;

      &-footer {
        height: 96px;
        width: 100%;
        margin-top: 10px;
      }

      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0;

        .edit {
          width: 40px;
          height: 40px;

          background: #ffffff;
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
          border-radius: 2px;

          .svg-container {
            width: 20px;
            height: 20px;
            fill: #4c91ff;
          }
        }
      }

      &-title {
        color: #232323;
        font-size: 16px;
      }
    }
  }
}

@primary-color: #4C91FF;