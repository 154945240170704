@import '@styles/default.less';

.ils-switch {
  &.ant-switch {
    background: @color-white;
    width: @button-icon-width;
    min-width: @button-icon-width;
    height: @button-icon-width;
    padding: 2px 0;
    font-size: 16px;
    border-radius: 2px;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);

    &-checked {
      background-color: @color-primary;
    }

    .ant-switch {
      &-inner {
        margin: 0;
        border: none;
        display: block;
        width: @button-icon-width;
        height: @button-icon-width;
        padding: @paddings-general;

        .ils-svg {
          .flex-layout-horizontal(center, center, 0);
          width: 100%;
          height: 100%;

          div {
            width: 100%;
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;
            padding: 2px 0 4px;

            svg {
              width: 24px;
              height: 24px;
            }
          }
        }
      }

      &-handle {
        display: none;
      }
    }
  }

  &-icon {
    display: flex;
    place-content: center;
    background: transparent;
    border: none;
    width: 100%;
    height: 100%;
    border-radius: 3px;

    &.active {
      background-color: @color-primary;
    }
  }

}
@primary-color: #4C91FF;