@import '@styles/default.less';

@bg-color-opacity: 0.5;
@selected-row-color: #eff0b6;
@date-picker-position: 27px;
@alert-date-picker-position: 10px;
// TODO В случае изменения @cell-padding необходимо изменить...
@cell-padding: 5px 7px;
@reference-button-width: 32px;

.disable-but-editable() {
  background: transparent !important;
  border: none !important;
  .ant-picker-input > input[disabled] {
    color: black;
    cursor: pointer;
  }
}

.resizer() {
  box-sizing: border-box;
  position: absolute;
  width: @resizer-width;
  height: 100px;
  top: 0;
  right: 0;
  z-index: @z-index-resizer;
  cursor: col-resize;
}

.filter-dropdown.ant-form {
  display: grid;
  grid-template-areas:
    'input input'
    'checkbox checkbox'
    'search reset';
  gap: @gap-em;
  padding: @gap-em;

  .filter-dropdown {
    &-checkbox {
      grid-area: checkbox;

    }

    &-input {
      grid-area: input;
    }

    &-search {
      grid-area: search;
    }

    &-reset {
      grid-area: reset;
    }
  }
}

.ils-table {
  .reset-full();
  background: @color-white;

  .ant-table {
    &.ant-table-bordered > .ant-table-title {
      border: none;
    }

    &-tbody {
      > tr {
        > td {
          border-bottom: 1px solid @border-color-primary;
          border-right: 1px solid @border-color-primary;
          transition: background 0.3s;
        }
      }

      #row_expand {
        .cell-wrapper {
          .ant-empty.ant-empty-normal {
            transform: translateX(50vw);

            .ant-empty-description {
              width: fit-content;
              white-space: nowrap;
            }
          }
        }
      }
    }

    &-column-sorters::after {
      pointer-events: none;
    }
  }

  .ant-table.ant-table-bordered > .ant-table-footer {
    padding: 0;
  }

  .table-header-form {
    background: @color-primary !important;
    color: @color-white !important;
  }

  .change-cell {
    width: 20px !important;
    height: 20px !important;
    position: absolute !important;
    right: 5px;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .change-cell,
  .change-cell:hover,
  .change-cell:focus {
    background-color: transparent !important;
    border: none !important;
  }

  .cell-wrapper {
    .flex-layout-horizontal(space-between, center, @no-gap);
    width: calc(100%);

    > .ant-checkbox-wrapper {
      align-self: center;
      justify-content: center;
    }

    &-editable {
      cursor: pointer;
      padding: 4px 5px;
      width: 100%;
      height: 100%;
      min-height: 30px;
      box-sizing: border-box;
      border-radius: 2px;

      &-full {
        width: calc(100% - @reference-button-width);
        height: 100%;
      }
    }

    &-icon-action {
      .flex-layout-horizontal(center, center, @no-gap);
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    &.reference {
      > .cell-wrapper-editable {
        // текст на всю ячейку, но с учётом кнопки открытия подтаблицы
        width: calc(100% - @reference-button-width);
      }
    }

    .select-arrow {
      width: 12px;
      height: 12px;
      fill: @color-primary;
      color: @color-primary;

      svg {
        width: 12px;
        height: 12px;
      }
    }

    .ant-checkbox-wrapper {
      width: 100%;
      height: 100%;
    }
  }

  .ant-alert-content {
    width: max-content;

    .ant-alert-message {
      .ant-picker.ant-picker-disabled {
        .disable-but-editable();
        padding-left: @alert-date-picker-position;
      }
    }
  }

  .ant-table-cell-ellipsis {
    > .flex-start-center div,
    > .editable-cell-wrapper {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .flex-between-center {
      div {
        overflow: hidden;
        max-width: 100%;
      }
    }
  }

  @-moz-document url-prefix() {
    .ant-table-cell {
      height: 100%;
    }
  }

  .ant-space-item {
    button.delete {
      width: 20px;
    }

    button.edit {
      width: 20px;
    }
  }

  .cell-label {
    color: @color-darkgrey;
    border: solid 1px @color-darkgrey;
    border-radius: 3px;
    line-height: 100%;
    padding: 2px;
    margin-right: 5px;
    vertical-align: middle;
  }

  .cell-icon {
    width: 25px;
    height: 22px;
    margin-right: 5px;

    svg {
      padding: 2px;
      width: 25px;
      height: 22px;
    }
  }

  .cell-icon + div {
    width: calc(100% - 25px);
    overflow: hidden;
  }

  td {
    .cell-icon {
      display: inline-block;
    }

    .cell-icon + .flex-start-center {
      width: calc(100% - 30px);
    }

    .cell-icon-wrap {
      display: flex;
    }
  }

  .cell-percent {
    .flex-layout-horizontal();
    width: 100px;
    height: 100%;
    border-radius: 10%;

    &-text {
      color: @color-black;
    }

    &.low {
      background-color: tint(@color-warning, 50%);
    }

    &.good {
      background-color: tint(@color-yellow, 50%);
    }

    &.high {
      background-color: tint(@color-success, 50%);
    }

    &.over {
      background-color: tint(@color-error, 50%);
    }
  }

  .cell-progress {
    overflow: hidden;
    .ant-progress-outer {
      margin-right: calc(-3em - 8px);
      padding-right: calc(3em + 8px);
    }
  }

  .cell-badge {
    width: 5px;
    height: 5px;
    display: inline-block;
    margin-right: 4px;
    margin-bottom: 2px;
  }

  .cell-duration-view {
    align-items: center;
    padding-left: 2px;
    display: flex;

    .time-value {
      margin-left: 8px;
      margin-right: 1px;
    }
  }

  .table tr {
    min-height: 40px;
  }

  .ant-table-small .ant-table-thead {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: @color-white;
    font-size: 13px;
    z-index: @z-index-primary;

    > tr {
      > th {
        color: @color-black !important;
        background: @color-white !important;
      }
    }
  }

  th.ant-table-cell-ellipsis.ant-table-cell-fix-left-last {
    .ant-table-cell-content {
      width: calc(100% + 8px);
      padding-right: 5px;
    }
  }

  .ant-table .ant-table-small .ant-table-thead {
    top: -5px;
  }

  .ant-table-body::-webkit-scrollbar-thumb {
    background: @color-scroll;
  }

  .ant-table-body::-webkit-scrollbar {
    width: 10px;
    height: 8px;
    background: @color-white;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: transparent;
  }

  tr.active-row td,
  tr.active-row:hover td {
    background: @bg-primary !important;
  }

  tr.selected-row td,
  tr.selected-row:hover td {
    background: @selected-row-color !important;
    opacity: 0.9;
  }

  tr.active-row.selected-row td,
  tr.active-row.selected-row:hover td {
    opacity: 1;
  }

  tr.deleted-row td,
  tr.deleted-row:hover td {
    background: @color-deleted-row !important;
  }

  tr.deleted-row.active-row td,
  tr.deleted-row.active-row:hover td {
    background: darken(@color-deleted-row, 20%) !important;
  }

  .ant-table-thead th.ant-table-cell {
    padding: 8px 6px !important;
    span.ant-typography {
      min-width: 20px;
      min-height: 20px;
      display: inline-block;
    }

    .svg-container {
      min-width: 20px;
    }
  }

  .resizer-line {
    position: absolute;
    width: 2px;
    height: 100%;
    background: @color-darkgrey;
    display: block;
    top: 0;
    z-index: @z-index-resizer;
  }

  .table-resize-area.disabled {
    .ant-table-wrapper {
      pointer-events: none;
      user-select: none;
    }
  }

  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down,
  span.ant-table-filter-trigger {
    font-size: 15px !important;

    svg {
      transform: scale(1.1);
    }
  }

  span.ant-table-filter-trigger {
    div {
      width: 15px;
      height: 15px;

      svg {
        width: 100%;
        height: 100%;
        fill: #bfbfbf;
      }
    }

    .search-filter {
      margin-top: -5px;
    }
  }

  span.ant-table-filter-trigger.active {
    svg {
      fill: @color-primary;
    }
  }

  .ant-table-filter-dropdown {
    .svg-container {
      padding: 3px;
      margin-right: 0;
    }
  }

  .editable-table {
    td.ant-table-cell,
    th.ant-table-cell {
      padding: @cell-padding !important;
    }
  }

  td {
    > .flex-between-center {
      width: 100%;

      > .flex-start-center {
        width: 100%;
      }
    }

    .ref-cell {
      > div:first-child {
        width: calc(100% - 25px) !important;
      }

      > button.ant-btn {
        width: 20px;

        &:disabled {
          filter: grayscale(1);
        }
      }
    }

    .ant-select.icon-only {
      width: calc(100% + 6px) !important;
      margin-left: -3px;

      span.ant-select-arrow {
        display: none;
      }

      span.ant-select-selection-item {
        margin-top: -2px;
        padding-left: 5px;
      }
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-cascader-picker,
    .ant-cascader-input.ant-input {
      border: none;
      background-color: transparent !important;
      padding: 0;
    }

    .ant-select-show-arrow .ant-select-selection-item,
    .ant-cascader-picker-label {
      padding: 0 0 0 18px !important;
    }

    .ant-select-show-arrow .ant-select-arrow,
    .ant-cascader-picker-arrow {
      left: 0;
      right: auto;
      color: @color-primary;
    }

    .ant-select-multiple .ant-select-selection-item {
      background-color: transparent;
      border: none;
    }

    .ant-select-selection-overflow {
      width: 100%;
      flex-wrap: nowrap !important;

      &-item {
        flex: auto !important;
        &:first-child {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }

    .ant-select-show-search .ant-select-selector {
      max-height: @button-icon-width;
    }

    .date {
      .svg-container {
        width: 16px;
        height: 16px;
      }
    }

    .time {
      .svg-container {
        width: 19px;
        height: 18px;
      }
    }

    .cell-loading {
      position: absolute;
      width: 18px !important;
      max-width: 18px !important;
      height: 18px !important;
      left: 5px;
      top: 0;
      bottom: 0;
      margin: auto;
      background: inherit;
    }

    span.not-ellipsis {
      white-space: normal;
    }
  }

  td.ant-table-cell:last-child,
  th.ant-table-cell:last-child {
    border-right: none !important;
  }

  .ant-table.ant-table-bordered > .ant-table-container {
    border-left: none !important;
  }

  .text-table {
    td.ant-table-cell,
    th.ant-table-cell {
      padding: 5px 8px !important;
    }
  }

  .table-header {
    display: flex;
    flex-direction: column-reverse;
    gap: @gap-rem;
    background: @color-white;
    width: 100%;

    &-top {
      .reset-full();
      .flex-layout-horizontal(space-between, flex-start, @gap-rem);
      width: 100%;
      overflow-x: hidden;

      .ils-notification-settings {
        background-color: #2577ae;
      }
    }

    &-title {
      .reset-full();
      min-width: fit-content;
    }

    &-buttons {
      .flex-layout-horizontal(start, center);
      flex-wrap: wrap;
    }

    // TODO мы можем решить проблему "TMS-2524 исправления drag&drop с карты" не в общей таблице? слишком глобально ко всем таблицам
    // внутри которых react-customscroll применяется стиль, который сложно переопределить
    .react-customscroll-frame,
    .react-customscroll-holder,
    .react-customscroll {
      .flex-layout-horizontal(flex-start, center, @gap-primary);
      flex-direction: row-reverse;
      height: fit-content;

      button {
        min-width: @button-icon-width;
        min-height: @button-icon-width;
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }

    .ils-notification-settings {
      background-color: #2577ae;
    }
  }

  .actions {
    .reset-full();
    .flex-layout-horizontal(start, center);
    flex-wrap: wrap;
  }

  .ant-select-item {
    padding: 5px 5px !important;
  }

  tr.ant-table-expanded-row.ant-table-expanded-row-level-1 > td {
    padding: 5px 0 36px 36px !important;

    .actions.mb-3 {
      margin-bottom: 0 !important;
    }
  }

  .position-fixed {
    position: fixed;
  }

  .color-cell {
    width: 70%;
    height: 25px;
    margin: auto;


    &.view {
      opacity: @bg-color-opacity;
    }

    &-changed {
      width: 100%;
      height: 100%;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  tr.Plan {
    td,
    td .ant-typography {
      color: @color-processing;
      font-weight: 600;
      font-size: 16px;
    }

    td div.cell-percent-text,
    td div.percent-text,
    td div div div.cell-percent-text {
      color: @color-black;
    }
  }

  tr.Route {
    td,
    td .ant-typography {
      font-size: 14px;
    }
  }

  tr.Trip {
    // .color-cell.view {
    // 	width: 20px;
    // 	height: 20px;
    // }

    td,
    td .ant-typography {
      font-size: 13px;
    }
  }

  tr.Waypoint {
    // .color-cell.view {
    // 	width: 15px;
    // 	height: 15px;
    // }

    td,
    td .ant-typography {
      font-size: 12px;
    }
  }

  tr.OrderAction {
    td,
    td .ant-typography {
      font-size: 12px;
    }
  }

  table {
    td[data-target] {
      position: unset;
    }

    tr {
      position: relative;
      width: 100%;
      //padding: 0!important;
      span.droptarget {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: @z-index-drop-container;

        .target {
          position: absolute;
          width: 100%;
          height: 100%;
        }
      }

      .target {
        z-index: @z-index-drop-container;
      }

      span.droptarget.highlighted {
        border: 2px dashed @color-primary;
        background: rgba(76, 145, 255, 0.1);
      }
    }

    tr.drag-hovered:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: @z-index-drag-container;
      border: 2px dashed @color-primary !important;
      background: rgba(76, 145, 255, 0.1);
    }

    .color-picker {
      top: 5px;
      left: 10px;
      position: absolute;
      z-index: @z-index-context-menu;

      .chrome-picker {
        display: flex;
        width: 300px !important;
        height: 84px;

        > div:first-child {
          padding-bottom: 84px !important;
        }

        > div:last-child {
          padding: 5px !important;
          width: 100%;

          .flexbox-fix {
            div:last-child {
              margin-top: 0 !important;
            }
          }

          .flexbox-fix:last-child {
            padding-top: 9px !important;

            label {
              margin-top: 4px !important;
            }

            div:last-child {
              > div svg {
                margin-top: -5px !important;
                margin-right: 3px;
              }
            }
          }
        }
      }

      .color-ok-btn {
        position: absolute;
        bottom: 1px;
        right: 1px;
        height: 20px;
        padding: 0 5px;
        line-height: 14px;
      }
    }

    .color-picker-cover {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    tr:nth-last-child(-n + 3):not(:nth-child(-n + 2)) {
      .color-picker {
        top: -45px;
      }
    }

    td:nth-last-child(-n + 2) {
      .color-picker {
        right: 10px;
        left: auto;
      }
    }

    button.expand-btn {
      padding: 0 !important;
      width: 20px;
      height: 20px;

      span {
        position: relative;
        top: -2px;
      }
    }

    .ant-modal.cell-modal {
      min-width: 900px !important;
    }

    #multiple-edit-row::-webkit-scrollbar-thumb {
      background: @bg-success !important;
    }

    #multiple-edit-row::-webkit-scrollbar {
      width: 0;
      height: 0;
      background: @bg-success;
    }

    #multiple-edit-row.with-pagination {
      bottom: 43px;
    }

    .ant-table {
      margin-bottom: 40px !important;
    }
  }

  #multiple-edit-row {
    position: sticky;
    width: 100%;
    height: 36px;
    z-index: @z-index-content-above;
    bottom: 0;
    left: 0;
    background: @bg-success;
    padding: 2px 8px 2px 0;
    overflow-x: hidden;
    overflow-y: hidden;
    scrollbar-width: thin;
    scrollbar-color: @bg-success @bg-success;

    .cell {
      &-multiple-edit-row {
        height: @button-icon-width;
        width: 100%;
        display: flex;
        padding: 0 6px;
        border: none;
        border-right: 1px solid @color-white;
        &.first {
          align-items: center;
          justify-content: space-around;
        }

        &.gray {
          background: @color-gray;
          cursor: not-allowed;

          .cell-wrapper-editable {
            cursor: not-allowed;
            // Добавлен !important для color-cell, поскольку её стили inline, появляется чёрный квадрат поверх не редактируемой ячейки
            background: @color-gray !important;
          }
        }
      }
    }

    .row {
      display: flex;
      align-items: center;

      .bordered:last-child {
        border: none;
      }
    }
  }

  td.repeated-driver {
    color: #f00;

    .ant-typography {
      color: #f00;
    }
  }

  // для expandedRowRender content
  .expand-row {
    min-width: 1600%;
    max-width: fit-content;
  }

  // выравнивание checkbox
  .ant-checkbox-wrapper {
    padding: 4px 8px;
  }

  th > .ant-checkbox-wrapper {
    padding: 0;
  }

  .ant-tabs-content.ant-tabs-content-top {
    height: calc(100vh - 200px);
  }

  .ant-table-body {
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: @color-scroll @color-white;

    > div {
      width: 100%;
    }
  }

  .antd-td {
    .ant-checkbox-wrapper,
    .ant-table-row-expand-icon,
    .ant-table-row-expand-icon-collapsed {
      .flex-layout-horizontal();
    }
  }

  //   expand-row не ориентировалась на ячейку таблицы по положению
  div.expand-row {
    align-self: baseline;
  }

  .expand-row:first-child {
    width: 70vw;
  }

  .disabled-table-cell {
    //for select
    .ant-select-selector > span.ant-select-selection-item {
      color: rgba(0, 0, 0, 0.85);
      cursor: text;
      user-select: text;
    }
  }

  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    margin: 0 0 0 10px;
  }
}

.ant-modal.table-modal {
  width: 90vw !important;
  height: 85vh !important;

  .ant-modal-content {
    height: 100%;
  }

  .change-table {
    top: 0;
    right: 15px;
  }
}

/*
  Стили формы создания записи
*/
.ant-popover {
  .adding-form-item-row {
    flex-direction: row;
    flex-wrap: nowrap;
    display: flex;
    justify-content: center;
    .ant-form-item {
      width: 100%;
    }
    .button-table-reference {
      margin: auto;
    }
  }
}

.ant-table-cell {
  .resizer {
    .resizer();
  }

  &-fix-right,
  &-fix-left {
    z-index: @z-index-table-button;
  }

  .ils-delivery-status {
    display: flex;
    align-items: center;
    gap: 24px;

    &-info {
      display: flex;
      gap: 8px;
      width: 100%;
    }

    &-square {
      display: flex;
      justify-content: center;
      align-items: center;
      text-shadow: 0 0 5px white;
      border-radius: 4px;
      flex-shrink: 0;
      cursor: pointer;
    }

    &-header {
      display: block;
      overflow-x: scroll;
      overflow-y: hidden;
      height: 1px;
    }
  }

  .icon-unviewed {
    text-align: center;
    justify-content: center;
    display: flex;

    svg {
      fill: @color-primary;
    }

    color: @color-primary;
  }

  .icon-viewed {
    justify-content: center;
    display: flex;

    text-align: center;
  }
}

.ant-table-cell.ils-delivery-status-cell {
  overflow: hidden;
}

.ils-delivery-status-tooltip {
  .ant-tooltip-inner {
    width: 330px;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.5);
    background-color: white;

    .row {
      .label.short {
        width: 40%;
        padding-right: 5px;
      }

      .value {
        width: 60%;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .table-header,
  .actions {
    max-width: 530px;
    overflow-y: unset;
    overflow-x: auto;
  }

  .table-header-top {
    max-width: 100%;
  }

  .table-header-buttons-action {
    max-width: 100%;
    overflow: scroll;
  }
}

.cell-type {
  &-status {
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
    height: @button-icon-width;
    padding: 4px 4px;
    width: 100%;
    font-size: 14px;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.85);

    &-success {
      background: @color-info;
      cursor: not-allowed;
    }

    &-exception {
      background: @color-error;
      cursor: not-allowed;
    }

    &-normal {
      background: @color-yellow;
    }
  }

  &-alert {
    .flex-layout-horizontal();
    flex-direction: row-reverse;

    &.ant-alert {
      padding: 0 @gap-primary;

      .ant-alert {
        &-content {
          overflow: hidden;
          font-weight: normal;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &-icon {
          margin: 0;
        }
      }
    }
  }
}

.cell-select-multiple-api-menu {
  .flex-vertical(0);

  &-button {
    align-self: center !important;
  }

  &-divider {
    margin: 8px 0 !important;
  }
}

.notification-settings-modal {
  .main-settings {
    display: flex;
    flex-direction: column;

    .switch-restrictions {
      margin-top: 10px;

      button {
        margin: 0 15px;
      }
    }
  }

  .restriction-place {
    display: flex;
    align-items: center;
    margin-top: 30px;

    &-container {
      width: 80%;
    }

    &-component {
      margin-bottom: 30px;

      .inputs {
        .flex-layout-horizontal(start, start, 0);

        &-auto-complete {
          min-width: 65%;
        }

        .area {
          width: 250px;
          margin-right: 10px;
        }

        .radius {
          width: 130px;
        }
      }

      .ant-divider {
        width: 80%;
      }
    }

    .ils-notification-settings {
      margin-left: 25px;
    }
  }

  .save {
    background-color: @primary-color;
    color: #fff;
  }
}

.table-tracker-select .ant-select-selection-placeholder {
  margin-left: 20px;
  opacity: 0.5;
  color: #000;
}

.ant-select {
  width: 100%;
}


@primary-color: #4C91FF;