@import '@styles/default.less';

@catalog-height: calc(100vh - 90px);
@editor-height: 276px;

.catalog {
  padding: 10px 0;

  .catalog-item {
    position: relative;

    .table-config {
      position: static;
    }

    .config-container {
      max-width: 300px;
    }
  }

  &-collapse-title {
    .flex-layout-horizontal();

    div {
      .margin-reset();
      min-width: fit-content;
      width: 200px;
    }
  }

  .short-box {
    min-height: 50px;
    max-height: 700px;

    .actions + .ant-table-wrapper {
      .ant-table-body {
        max-height: 350px !important;
      }
    }
  }

  .ant-collapse-content-box {
    padding-top: 0 !important;
    padding-right: 3px !important;
  }

  .ant-menu-vertical .ant-menu-item:not(:last-child) {
    margin: 0 !important;
  }

  .ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label {
    padding: 5px 15px;
  }

  .ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
    padding: 3px 15px;
  }

  .ils-content-menu ul.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light li.ant-menu-item-selected {
    margin-bottom: 0;
  }

  .full-menu {
    width: 100%;
  }

  .scroll-menu,
  .map .map-container {
    height: @catalog-height;
  }

  .ant-collapse {
    width: 100%;
  }

  .map {
    .map-container {
      width: 100%;

      .ils-map-toolbar {
        z-index: @z-index-context-menu;
        .map-btn-menu {
          background: #e8edf3;
          position: absolute;
          right: 0;
          z-index: @z-index-context-menu;
          min-width: 200px;
        }
      }
      .leaflet-container {
        z-index: @z-index-context-menu;
      }
    }
  }

  .__dbk__dragger.Horizontal {
    height: @catalog-height;
  }
}

.focusedFormula {
  color: white;

  background-color: #4c91ff;
}

.tariff {
  &-editor-zone {
    .flex-layout-horizontal(center, center, @no-gap);
    min-height: @editor-height;
    border: 1px solid @color-normal;

    .droptarget {
      flex: 1;
    }
  }

  &-form {
    .flex-layout-vertical(center, center, @gap-primary);
    box-sizing: border-box;
    order: 2;
    width: 250px;
    height: @editor-height;
    border-left: 1px solid @color-normal;
    background-color: @bg-body !important;

    &-item {
      margin: 0;
    }

    &-formula-btns {
      width: 100%;
      height: 100px;
      padding: @gap-primary;

      button {
        width: 95%;
        margin: 3px;
        height: auto;
        line-height: 16px;
      }
    }

    .tab-btns {
      height: 50px;
    }
  }

  &-desc {
    .flex-layout-horizontal(space-between);
    padding: @gap-primary;
    border: 1px solid @color-normal;
    border-top: 0;
    min-height: 50px;

    &-text {
      white-space: pre;
    }

    &-btns {
      .flex-layout-vertical(center, center, @gap-primary);
    }
  }

  .tox {
    &-tinymce {
      border: none !important;
    }

    &-sidebar-wrap {
      width: 100%;
    }

    .tox-tbtn--select {
      margin-right: 8px !important;
      border: 1px solid #ccc !important;
      min-width: 30px;
      height: 30px !important;
    }

    .tox-toolbar-overlord {
      width: 100%;
      background-color: @bg-body !important;
      border-bottom: 1px solid @color-normal;

      .tox-toolbar {
        padding: 0 10px;
        min-height: 44px;
        background: @bg-body !important;
      }
    }
  }

  button.check-btn {
    position: absolute;
    z-index: @z-index-button;
    bottom: 10px;
    left: 10px;
  }

  .tariff-field {
    border: 1px solid @color-normal;
    &-row {
      .flex-layout-horizontal(start, center);
    }
  }

  a[data-text] * {
    position: absolute;
    opacity: 0;
    z-index: @z-index-negative;
  }

  a[data-text]:before {
    content: attr(data-text);
  }
}

.checkboxland {
  margin: 10px 0 20px 0;
}

.title {
  font-weight: 600;
  font-size: 16px;
}

.tox-notifications-container,
.tox-statusbar {
  display: none !important;
}

.export-settings {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .input-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}


@primary-color: #4C91FF;