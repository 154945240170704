@import '@styles/default.less';

.custom-scroll-css {
  scrollbar-width: thin;
  scrollbar-color: @color-gray;
}

/* Works on Chrome, Edge, and Safari */
.custom-scroll-css-horizontal::-webkit-scrollbar {
  width: 0;
  height: 8px;
}

/* Works on Chrome, Edge, and Safari */
.custom-scroll-css-vertical::-webkit-scrollbar {
  height: 0px;
  width: 8px;
}

.custom-scroll-css::-webkit-scrollbar-track {
  background: none;
}

.custom-scroll-css::-webkit-scrollbar-thumb {
  background-color: @color-gray;
}

@primary-color: #4C91FF;