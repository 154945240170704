@import '@styles/default.less';

.logistic-services__card-badge {
  position: absolute;

  &_traffic-light {
    top: -10px;
    right: -14px;
    img {
      height: 64px;
    }
  }

  &_login {
    top: -6px;
    right: 0;
  }

  &_lock {
    top: -10px;
    right: -16px;
    svg,
    path {
      fill: @color-darkgrey !important;
      width: 26px !important;
      height: 26px !important;
    }
    border-radius: 50%;
    background: @color-white;
    padding: 4px;
    box-shadow: 0 0 10px -6px #2a3d4f;
  }
}


@primary-color: #4C91FF;