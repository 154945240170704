@import '@styles/default.less';

.planner {
  &-upload {
    &-drag-n-drop {
      box-sizing: border-box;
      height: auto;
      max-height: 200px;

      &.success {
        &.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
          border: 1px dashed @color-success;
        }
      }

      .ant-upload.ant-upload-btn {
        padding: @gap-rem;
      }

      &-icon {
        .icon-square(40px);

        div {
          .icon-square(40px);
        }

        &s {
          .flex-layout-horizontal();
        }
      }

      &-text {
        display: block;
      }
    }
  }
}

.trip-tender-platform-modal {
  p.warning-title {
    font-size: medium;
    display: flex;
    align-items: center;

    .warning-icon {
      color: @color-warning;
      padding-right: 10px;
      svg{
        width: 35px;
        height: 35px;
      }
    }
  }
}
.plan-table-header {
  padding: 0 14px !important;
}

.choose-project-modal {
  width: fit-content;

  .choose-project-radio {
    width: fit-content;
    .flex-layout-vertical(flex-start, flex-start, @gap-rem);
  }
}
@primary-color: #4C91FF;