@import '@styles/default.less';

.plan-analytic-content {
  height: 100vh;

  &-no-data {
    height: 100vh;
    .flex-layout-vertical(center, center);
    font-size: xx-large;
  }

  .export-row {
    width: 500px;
  }

  .inner-row {
    padding: 5px;
    border-bottom: 1px solid @color-normal;

    div {
      word-break: break-word;
    }
  }

  .log-tree {
    .row {
      display: none;
    }
  }

  .ils-tree {
    div {
      padding: 5px;
    }

    h1 {
      padding: 5px;
      background: @color-primary;
      color: @color-white;
      position: relative;
    }

    h1:before {
      content: '>';
      position: absolute;
      top: 0;
      right: 20px;
      bottom: 0;
      margin: auto;
      color: @color-white;
      height: 15px;
      width: 15px;
      line-height: 15px;
    }

    h1.toggle_o:before {
      transform: rotate(90deg);
    }

    h1.toggle_h + .branch {
      display: none;
    }

    h1.toggle_o + .branch {
      border: 1px solid @color-primary;
      position: relative;
      top: -10px;
    }

    //.branch_h{
    //    display: none;
    //}
    //.branch_o{
    //    border: 1px solid @color-primary;
    //    position: relative;
    //    top: -10px;
    //}

  }

  iframe {
    border: none;
    width: calc(100vw - 160px);
    height: calc(100vh - 60px);
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: @color-primary @color-normal;
  }

  iframe::-webkit-scrollbar-thumb {
    background: @color-primary;
  }

  iframe::-webkit-scrollbar {
    width: 0;
    height: 8px;
    background: @color-normal;
  }
}
@primary-color: #4C91FF;