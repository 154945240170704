@import '@styles/default.less';
@import '@modules/analytics/common/styles.less';

.analytics-planning {
  &-component {
    display: flex;
    justify-content: space-between;
    height: 80vh;

    &-board {
      width: 100%;
    }
  }

  .boards-list, .board-set-list {
    width: 347px;

    .item-list {
      max-height: calc(50vh - 190px);
      overflow-y: auto;
    }
  }

  .board-set-list {
    .item-list {
      max-height: calc(30vh - 100px);
    }
  }

  &-boards-sidebar {
    height: auto;
    overflow-y: auto;
  }

  &-board-view {
    height: 100%;
  }

  &-board-button {
    opacity: 0.8;
  }

  &-board-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .export-button {
      margin-bottom: 7px;
      background-color: @primary-color;
      color: @color-white;
    }
  }

  &-board-button:hover {
    opacity: 1;
  }

  &-board-settings {
    height: 100%;
  }

  &-board-sets {
    overflow-y: auto;
  }
}

.analytics-top-layout {
  .__dbk__child-wrapper {
    overflow: auto;
    background-color: @bg-body;
  }
}

@primary-color: #4C91FF;