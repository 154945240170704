@import '@styles/default.less';
@import '@styles/bootstrap.less';
@import '@styles/global.less';

@table-header-height: 75px;
@project-option-buttons-padding: 8px;

.planning-data {
  .flex-layout-horizontal(flex-start, flex-start);
  height: 100%;
  padding: @paddings-general;

  &-upload {
    .flex-layout-vertical(space-between, flex-start, @gap-rem);
    max-width: 300px;

    &-fetching {
      max-width: 80%;
    }

    &-form {
      .flex-vertical(@gap-rem);
      max-width: 300px;

      .ant-form-item {
        .margin-reset();
      }

      .ant-radio-group {
        .flex-layout-horizontal(space-between, center, @gap-em);
        padding-top: @project-option-buttons-padding;

        .ant-radio-button-wrapper {
          flex: auto;
          height: auto;
          text-align: center;
        }
      }

      &-titles-wrapper {
        .flex-layout-horizontal(flex-start, @gap-em);
      }
    }

    &-form-with-project {
      .ant-radio-button-wrapper {
        line-height: unset;
      }
    }

    .ant-btn-link {
      padding: 0;
      margin-bottom: 20px;
      text-align: center;
    }

    .ant-btn-link.disabled-btn {
      border: none;
      background: transparent !important;

      &:hover,
      &:focus,
      &:active {
        color: rgba(0, 0, 0, 0.05) !important;
        border: none !important;
        background: transparent !important;
        text-shadow: none !important;
        box-shadow: none !important;
      }

      span {
        color: rgba(0, 0, 0, 0.25) !important;
      }
    }

    &-dictionary {
      .flex-layout-vertical();
      .margin-reset() !important;
    }

    &-actions {
      display: grid;
      gap: @gap-em;
      padding: @gap-rem;
      grid-template-areas: 'config' 'planning' 'start' 'submit';
      align-self: center;
      max-width: 100%;
      min-width: fit-content;

      &-config {
        grid-area: config;
      }

      &-start {
        grid-area: start;
      }

      &-planning {
        grid-area: planning;
      }

      &-reset {
        grid-area: reset;
      }

      &-submit {
        grid-area: submit;
      }
    }

    &-tab {
      height: fit-content;

      .button-icon-text {
        padding: 0;
        .flex-layout-horizontal();

        &.disabled-btn {
          border: none;
          background: transparent !important;

          &:hover,
          &:focus,
          &:active {
            color: rgba(0, 0, 0, 0.05) !important;
            border: none !important;
            background: transparent !important;
            text-shadow: none !important;
            box-shadow: none !important;
          }

          span {
            color: rgba(0, 0, 0, 0.25) !important;
          }
        }
      }

      .react-customscroll-frame {
        .flex-layout-vertical(space-between, center, @gap-em);
        max-height: 40vh;
      }

      &s {
        .ant-upload.ant-upload-drag .ant-upload-drag-container {
          .flex-layout-vertical();
        }

        .ant-upload-drag-container {
          width: 100%;

          .svg-container {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }

  .ant-divider {
    background: black;
    height: inherit;
  }

  &-view {
    height: calc(100% - 100px);

    .react-customscroll {
      width: 100% !important;

      &-frame {
        padding-right: 6px;
      }
    }

    .flex-layout-vertical(flex-start, flex-start, @gap-rem);
    width: calc(100% - 270px);
    padding: @gap-rem;

    &-badge {
      display: flex;
      align-items: baseline;

      sup {
        right: -15px;
      }
    }

    &-no-data {
      font-weight: @fw-500;
      line-height: 1.5;
    }

    &-empty {
      transform: translate(15vw, 25vh);
    }

    &-collapse {
      width: 100%;
    }
  }
}


@primary-color: #4C91FF;