@import '@styles/default.less';

@content-width: 840px;
@video-width: 540px;
@content-padding: 24px;
@company-logo-container-width: 150px;
@company-logo-width: 100px;
@content-border-radius: 4px;
@close-icon-size: 25px;
@video-preview-icon-size: 60px;

.questions {
  &-container {
    width: 100%;
    padding: @gap-em;
    border-radius: @content-border-radius;
    background-color: @color-white;
    .flex-layout-vertical(start);
  }

  &-section {
    width: 100%;
    max-height: 50%;
    .flex-layout-vertical(start);
    overflow: hidden;

    &:last-of-type {
    }
  }

  &-header {
    width: 100%;
    .flex-horizontal();
    justify-content: center;
    border-radius: 4px;
    background-color: @bg-normal;
    padding: @gap-primary;
    position: relative;
    h2 {
      margin: 0;
      padding: 0 @gap-em;
      border-radius: 4px;
      background-color: @color-white;
    }
    &-close-button {
      position: absolute;
      right: @gap-primary;
      top: 50%;
      transform: translate(0, -50%);
      border: none;
      background-color: transparent;
      svg {
        fill: @color-primary;
        width: 25px;
        height: 25px;
      }
    }
    &-content {
      .flex-horizontal();
      justify-content: center;
      width: 100%;
      max-width: @content-width;
      padding: @content-padding 0;
    }
  }

  &-video-component,
  &-list-container {
    .flex-horizontal();
    justify-content: center;
  }
  &-video {
    width: @video-width;
    aspect-ratio: 16/9;
    height: auto;
  }
  &-preview {
    position: relative;
    cursor: pointer;
    &-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.2);
      transition: background-color 0.3s;
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
        svg {
          fill: @color-primary;
        }
      }
    }
    &-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      svg {
        width: @video-preview-icon-size;
        height: @video-preview-icon-size;
        fill: @color-darkgrey;
      }
    }
    img {
      aspect-ratio: 16/9;
      width: 100%;
    }
  }

  &-scroll {
    height: 38vh;
  }

  &-list {
    .flex-vertical(@gap-em);
    margin-right: @gap-primary;
    .question-item {
      .flex-vertical(@gap-primary);
      &-question,
      &-answer {
        width: 60%;
        padding: 4px 10px;
        border-radius: 10px;
        border: 2px solid @border-color-primary;
        white-space: break-spaces;
        p {
          margin: 0;
          span {
            font-weight: @fw-500;
          }
        }
      }
      &-question {
        align-self: flex-start;
      }
      &-answer {
        align-self: flex-end;
        background-color: @border-color-primary;
      }
    }
  }

  &-rules-list {
    .flex-vertical(@gap-em);
    width: 100%;
    &-row {
      .flex-horizontal(@gap-em);
      font-weight: @fw-500;
      .company-img {
        width: @company-logo-container-width;
        padding: 4px 0;
        .flex-horizontal();
        border: @border-primary;
        justify-content: center;
        img {
          width: @company-logo-width;
        }
      }
    }
  }
}


@primary-color: #4C91FF;