#proxy-renderer {
  overflow-y: unset;
}

#react-doc-viewer #header-bar {
  display: none;
}

.document-header {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 40px;
  position: absolute;
  top: -50px;
  right: 0;
  width: 100%;
  min-height: 40px;
  height: 40px;
  background: #fff;
  padding: 0 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.header-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pdf-viewer {
  width: 100%;
  height: 78vh;
}

.img-viewer {
  width: 100%;
  object-fit: contain;
}

@primary-color: #4C91FF;