@import '@styles/default.less';

.nav-container {
  .flex-layout-horizontal(space-between, center);
  padding: 0 16px;
  height: 48px;
  background: @color-white;
  margin-bottom: 1rem;

  &-links {
    .flex-layout-horizontal(center, center, 24px);

    a {
      color: @link-color;

      &.active {
        color: @color-black;
      }
    }
  }

}
@primary-color: #4C91FF;