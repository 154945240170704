@import '@styles/default.less';

.ant-modal.tender-user-modal {
  width: 500px !important;
  min-width: 500px !important;

  .ant-modal-header {
    padding: 20px 40px;
  }

  .ant-modal-body {
    padding: 40px;
  }

  .list {
    border-top: 1px solid @color-normal;
    padding: 12px;
    height: 200px;
  }

}

.tender-form {
  .block-item {
    .disabled-form-info();
    border-bottom: 1px solid @color-normal;
    position: relative;
  }

  span.ant-input-group-addon {
    border: none !important;
  }

  .rmdp-container {
    width: 100%;
  }
}

.tender-platform-card {
  &-form {
    .documents {
      &-upload {
        &-button {
          .flex-layout-vertical();
          max-width: 100%;
          background: transparent;
          border: none;

          span {
            white-space: break-spaces;
          }
        }
      }
    }
  }
}

.carrier-form, .carrier-checkboxes {
  .flex-vertical(@gap-em);
}

.tender-lot-buttons {
  .flex-layout-vertical(flex-start, flex-start, @gap-rem);
  justify-content: space-evenly;
}

.lot-template-list {
  .flex-horizontal(@gap-rem);
  flex-wrap: wrap;
  max-width: 100%;

  .ant-radio-button-wrapper {
    background-color: @bg-component;
    color: @color-black;
    border-color: @bg-component;
  }
}

.ils-tender-form-rating {
  &-input {
    .margin-reset();
  }

  &-counter {
    color: @color-error;
  }

}

.tender-rating-form {
  .flex-layout-horizontal(space-between, center, @gap-rem);

  fieldset {
    width: calc(50% - (@gap-rem / 2));
  }
}
@primary-color: #4C91FF;