// BOX-MODEL
.no-margin {
  .margin-reset()
}

.width-full {
  width: 100%;
}

//backgrounds
.bg-primary {
  background: @bg-primary !important;
}

.bg-main {
  background: @color-primary;
  border-color: @color-primary;
}

.bg-info,
.bg-info:hover {
  background-color: @color-info;
  border-color: @color-info;
}

.bg-component {
  background-color: @bg-component;
  border-color: @bg-component;
}

.bg-normal {
  background-color: @bg-normal;
  border-color: @bg-normal;
}

.bg-white {
  background-color: @color-white;
}

.bg-body {
  background-color: @bg-body;
}

.bg-success,
.bg-success:hover,
.bg-success:focus {
  background-color: @color-success;
}

.bg-warning,
.bg-warning:hover,
.bg-warning:focus {
  background-color: @color-warning;
}

.bg-error,
.bg-error:hover,
.bg-error:focus {
  background-color: @color-error;
}

.bg-error-light,
.bg-error-light:hover,
.bg-error-light:focus {
  background-color: rgba(210, 71, 71, 0.3);
}

.bg-warning-light,
.bg-warning-light:hover,
.bg-warning-light:focus {
  background-color: rgba(255, 166, 5, 0.3);
}

.bg-yellow,
.bg-yellow:hover,
.bg-yellow:focus {
  background-color: @color-yellow;
}

//colors
.color-primary {
  color: @color-primary;

  svg path {
    fill: @color-primary;
  }

  svg g {
    fill: @color-primary;
  }
}

.color-info {
  color: @color-info;

  svg path {
    fill: @color-info;
  }
}

.color-black {
  color: @color-black;

  svg path {
    fill: @color-black;
  }
}

.color-normal {
  color: @color-normal;

  svg path {
    fill: @color-normal;
  }
}

.color-error {
  color: @color-error;

  svg path {
    fill: @color-error;
  }
}

.color-warning {
  color: @color-warning;

  svg path {
    fill: @color-warning;
  }
}
//TODO лучше использовать mixins, имея стили для switch className,
// но не засорять в остальных случаях разметку компонентов стилями длинной в простыню, создавая изолированный стиль и применяя mixin

// BORDERS
// border-colors
.border-success,
.border-success:hover {
  border-color: @color-success;
}

.border-warning,
.border-warning:hover {
  border-color: @color-warning;
}

.border-error,
.border-error:hover {
  border-color: @color-error;
}

// border-radius
.circle {
  border-radius: 50%;
}

.rounded {
  border-radius: 5px;
}

.bordered {
  border: 1px solid @color-normal;
}

//font
.font-10 {
  font-size: 10px;
}

.font-11 {
  font-size: 11px;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-24 {
  font-size: 24px;
}

.font-28 {
  font-size: 28px;
}

.font-32 {
  font-size: 32px;
}

.font-500 {
  font-weight: 500;
}

.font-600 {
  font-weight: 600;
}

// CUSTOM STYLE CLASSES
.loading-spinner {
  width: 80vw;
  height: 80vh;
  .flex-layout-vertical(center, center)
}

.hide {
  display: none !important;
}

// ILS CUSTOM COMPONENTS STYLE

.ils-tooltip {
  .ant-tooltip {
    &-content, &-arrow-content, &-inner {
      .ils-tooltip();
    }

    &-inner {
      ul {
        .reset-full()
      }

      li {
        .reset-full();
        .flex-vertical();
        line-height: 14px;
      }

      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }
}

