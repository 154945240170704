@import '@styles/default.less';

@column-width: 270px;
@card-size: 100px;
@card-horizontal-padding: 5px;
@icon-width: 50px;
@icon-height: 35px;
@card-box-shadow: 0px 0px 10px -6px @color-darkgrey;
@card-box-shadow-active: 0px 0px 10px -4px @color-darkedgrey;

.back-link {
  padding: 0 0 0 15px !important;
}

.register-form {
  .register__form-block {
    padding: 0 1rem;
  }
}

.recovery-form {
  .recovery {
    .flex-layout-vertical(center, center, @gap-rem);
    padding: @gap-rem;

    &-form-item {
      margin: 0;
      width: 80%;
    }
  }
}

.logistic-services {
  width: 100%;
  height: calc(100vh - 130px);
  display: flex;
  flex-direction: column;
  gap: @gap-em;
  padding: @gap-em;

  &__header {
    padding: @gap-em;
    border-radius: @gap-primary;
    background-color: @bg-body;
    box-shadow: 0px 0px 10px -6px #2a3d4f;

    h1 {
      margin: 0;
      text-align: center;
      font-size: @fz-big;
      font-weight: @fw-500;
      color: @color-logo-black;

      p {
        font-weight: @fw-700;
      }
    }
  }

  &__list {
    width: 100%;
    flex: 1;
    display: grid;
    grid-template-columns: repeat(5, minmax(@column-width, 1fr));
    gap: calc(2 * @gap-em);
  }

  /** группа сервисов */
  &__group {
    display: flex;
    flex-direction: column;
    gap: @gap-em;
    padding: @gap-em;
    border-radius: calc(2 * @gap-primary);
    background-color: @bg-body;
    box-shadow: 0px 0px 10px -6px #2a3d4f;
  }

  &__group-title,
  &__column {
    width: 100%;
  }

  /* заголовок группы */
  &__group-title {
    min-height: 62px;
    text-align: center;
    border-bottom: 1px solid @color-gray;
    p {
      margin: 0;
      text-transform: uppercase;
      font-size: @fz-title_5;
      line-height: 1.2;
      font-weight: @fw-600;
      color: @color-darkedgrey;
    }
    p.subtitle {
      padding-top: @gap-primary;
      font-size: @fz-small;
    }
  }

  /* вертикальная колонка с карточками */
  &__column {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    row-gap: 1.5em;
    margin: 0;
    padding: 0;
    li {
      list-style-type: none;
    }
  }

  /* карточка */
  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: @card-horizontal-padding;
    padding: 0 @card-horizontal-padding;

    p {
      margin: 0 !important;
      font-size: @fz-text;
      font-weight: @fw-600;
      line-height: 1.1;
      text-align: center;
      transition: color 0.2s;
      color: @color-darkedgrey;
    }
    /* контейнер иконки */
    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-radius: 2.5em;
      width: @card-size;
      height: @card-size;
      background-color: @color-white;
      border: 3px solid @color-darkgrey;
      transition: 0.3s all;
    }

    svg {
      width: @icon-width;
      height: @icon-height;
      path,
      circle {
        fill: @color-darkgrey;
      }
    }

    &.active {
      .logistic-services__card-icon {
        border: 3px solid @color-info;
      }
    }

    &.fast-delivery {
      .logistic-services__card-icon {
        background-color: tint(@color-primary, 80%);
      }
    }

    &:hover {
      .logistic-services__card-icon {
        background-color: @color-info;
        box-shadow: @card-box-shadow-active;
        svg,
        path {
          fill: @color-white;
        }
      }
    }

    &:not(.active) {
      pointer-events: none;
    }
  }
}


@primary-color: #4C91FF;