@import '@styles/default.less';

@logo-gaps: 20px;
@header-height: 65px;

.logo {
    .flex-layout-horizontal(center, center, @logo-gaps);

  &-image {
    height: 40px;
  }

  &-text {
    .flex-layout-horizontal(center, center);
    flex-wrap: wrap;
    width: fit-content;
    max-width: 220px;
    padding: 0 @logo-gaps;
  }
  &-divider.ant-divider-vertical {
    height: @header-height;
  }
}
@primary-color: #4C91FF;