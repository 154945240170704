@import '@styles/default.less';

@error: #fff2f0;
@info: @bg-primary;
@warning: #fffbe6;

.open-reference {
  position: absolute !important;
  bottom: 10px;
  left: 0;
}

.show-all {
  top: 0;
  right: 0;
  //text-align: right;
  margin-top: -5px;
  margin-bottom: 10px;
}

.validate-container {
  .flex-vertical(@gap-rem);

  .ant-alert.active {
    background: @bg-primary;
  }

  .ant-alert {
    padding-left: 10px !important;
  }

  .error-list {
    .list {
      height: calc(((100vh - 300px) / 3) - 50px);
      max-height: calc(((100vh - 300px) / 3) - 50px);

      > div {
        border-color: @bg-component;
      }
    }

    .ant-alert {
      padding-left: 10px !important;
    }

    .error-item {
      line-height: 18px;
    }

    .error-item.active {
      color: #fff;
      background: @color-primary !important;
    }

    .category.active {
      background: @bg-primary !important;

      .ant-alert {
        background: @bg-primary !important;
      }
    }

    .ils-error-collapse {
      .ant-collapse-content {
      }

      &-Wrn {
        background: @warning;

        & > div {
          border: 2px solid @color-table-highlight-primary;
        }
      }

      &-Inf {
        background: @info;

        & > div {
          border: 2px solid #c7e2ff;
        }
      }

      &-Err {
        background: @error;

        & > div {
          border: 2px solid #ffccc7;
        }
      }
    }
  }

  .open-reference {
    left: 10px;
  }

  .common {
    .open-reference {
      left: 0;
      //bottom: -5px;
    }
  }

  .error-header {
    width: calc(100% - 20px);
  }
}

.validate-container {
  .alerts {
    .flex-layout-horizontal(flex-start, center, @gap-rem);
    //todo временное повторение до слияния 2489
    gap: @gap-rem;
    flex-wrap: wrap;
  }

  .error-list {
    h3 {
      min-width: 120px;
      margin-top: 5px;
    }

    > div {
      .flex-justify-align(flex-start, flex-start);
      flex-wrap: wrap;

      .mr-3.mb-3.bg-white {
        width: 250px;
      }
    }

    .ant-collapse {
      .flex-justify-align(flex-start, flex-start);

      .ant-collapse-item {
        margin-right: 10px;
      }
    }
  }
}

.validate-errors-catalog {
  width: 90vw;

  .table-config {
    width: fit-content !important;
  }
}

@primary-color: #4C91FF;