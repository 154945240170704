@import '@styles/default.less';

@tender-tab-header-height: 46px;
@tender-tab-header-content-gap-height: 16px;
@tender-tab-content-height: calc(@main-height - @tender-tab-header-height - @tender-tab-header-content-gap-height);

&-list {
  .disabled-form-info();

  &-title {
    grid-area: title;
  }

  &-button {
    grid-area: left;
    align-self: center;
    width: fit-content;
  }

  &-content {
    grid-area: content;
  }
}

.tender-table {
  .m-0-auto {
    margin: 0 auto;
  }

  .ant-table-body > table {
    width: 100% !important;
  }
}

.tender-offer-modal {
  .ant-modal-body {
    .flex-layout-vertical(flex-start, flex-start, @gap-rem);

    .tender-offer-modal-item {
      .flex-layout-horizontal(flex-start, flex-start, @gap-em);
    }
  }
}

.tender-filter-common {
  color: @color-gray;
  font-size: 12px;
  height: 28px;
}

.tender-filter {
  &:extend(.tender-filter-common);
  position: relative;
  margin-left: 10px;
  margin-right: 10px;

  &:first-child {
    margin-left: 0;
  }
}

.tender-filter-tooltip {
  position: absolute;
  margin-left: 6px;
  min-width: max-content;
}

.tender-filter-input {
  &:extend(.tender-filter-common);
  border-radius: 6px;
  border-width: 2px;
  margin-top: 20px;
  max-width: 138px;

  input {
    font-size: 12px;
  }
}

.tender-filter-error {
  background-color: @color-error !important;
  border-color: @color-error;
  color: @color-white !important;
}

.tender-filter-autocomplete {
  &:extend(.tender-filter-input);
  width: 138px !important;
  max-width: 138px !important;

  div {
    border-radius: 6px !important;
    border-width: 2px !important;
    height: 28px !important;

    .ant-select-selection-placeholder {
      margin-top: -3px !important;
    }

    .ant-select-selection-search-input {
      height: 26px !important;
    }

    .ant-select-selection-search input {
      margin-left: 0 !important;
    }
  }
}

.tender-filter-select {
  width: 148px !important;

  .ant-select-selector {
    height: 28px;
    border-radius: 6px !important;
    border-width: 2px !important;

    div {
      margin-top: -3px;
    }
  }

  .ant-select-selection-placeholder {
    font-size: 12px;
  }

  margin-top: 20px;
}

.tender-list .ant-select .ant-select-selector span { // autocomplete placeholder color
  color: @color-gray !important;
}

.tender-filter {
  &-wrapper {
    .flex-layout-horizontal(space-between, center, @gap-em);
    .ils-content-scroll();
    padding-bottom: @gap-em;
    width: 85%;
    & + .ils-scroll-header-buttons {
      min-width: 10px;
    }
  }

  &-dash {
    &:extend(.tender-filter-common);
    margin-top: 50px;
  }
}

@media (max-width: 1919px) {
  .tender-filter-input {
    max-width: 128px;
    margin-left: 0 !important;
  }

  .tender-filter-select {
    width: 128px !important;
  }

  .tender-filter-tooltip {
    margin-left: 0 !important;
  }
}

@media (max-width: 1826px) {
  .tender-filter-input {
    max-width: 100px;
  }

  .tender-filter-select {
    width: 100px !important;
  }
}

@media (max-width: 1658px) {
  .tender-filter-input {
    max-width: 90px;
  }

  .tender-filter-select {
    width: 90px !important;
  }

  .tender-filter-select {
    .ant-select-selector div div {
      margin-top: 0;
    }
  }
}

@media (max-width: 1486px) {
  .tender-filter {
    margin: 0 5px;
  }
}

@media (max-width: 1378px) {
  .tender-filter-input {
    max-width: 84px;
  }

  .tender-filter-select {
    width: 84px !important;
  }

  .tender-filter {
    margin: 0 3px;
  }
}
@primary-color: #4C91FF;