@import '@styles/default.less';

.reference {
  .article {
    img {
      width: 100%;
      height: auto;
      max-height: 300px;

      object-fit: contain;
    }
  }

  .delimiter {
    width: 100%;
    padding: 6px 0;

    text-align: center;
    line-height: 1.6em;
  }

  .delimiter:before {
    content: '***';

    display: inline-block;
    height: 30px;

    font-size: 30px;
    line-height: 65px;
    letter-spacing: 0.2em;
  }

  .hint {
    border: 1px solid @color-primary;

    ul {
      margin-bottom: 0;
      padding-inline-start: 15px;

      list-style-type: auto;
    }
  }

  .video {
    min-height: 300px;
  }
}

@primary-color: #4C91FF;