@import '@styles/default.less';

.ils-calendar() {
  color: @color-white;
  background: @color-primary;
  box-shadow: none;
  border-radius: 2px;
}

.ils-calendar-picker {
  .rmdp-day, .rmdp-week-day {
    color: @color-black;
  }

  .rmdp-day.rmdp-selected span:not(.highlight) {
    .ils-calendar();
  }

  .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
    .ils-calendar();
    background: lighten(@color-primary, 10%);
  }

  .rmdp-day.rmdp-today span {
    color: @color-primary;
    background: @color-white;
    box-shadow: none;
    border: 1px solid @color-darkgrey;
    border-radius: 2px;
  }
}
@primary-color: #4C91FF;