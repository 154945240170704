@import '@styles/default.less';

.road-graph-content.editor {
  .summary-info {
    //top: 50px;
    height: 300px;
    position: relative;
    right: 0;
    bottom: 0;

    .ant-col.ant-form-item-label {
      padding-bottom: 4px;
    }

    .ant-row.ant-form-item {
      margin-bottom: 10px;
    }
  }

  .checkbox-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    .ant-col {
      padding-bottom: 0 !important;
    }

    .ant-col:first-child {
      margin-right: 10px;
    }
  }
}

@primary-color: #4C91FF;