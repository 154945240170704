@import '@styles/default.less';

.settings {
  .content-section-padding();

  .scrollable-collapse {
    height: calc(100vh - 200px);
    max-height: fit-content;
  }

  .mobile-application {
    &-forwarder-point-statuses {
      &-table {
        &-config {
          .config {
            color: red;
            &-container {
              height: 100%;
              justify-content: space-between;
            }
          }
        }
      }
    }
  }
}
@primary-color: #4C91FF;