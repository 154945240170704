@import '@styles/default.less';

/*
* Стили модуля "Мониторинг" прописаны в одном файле в корневой папке
* src\modules\monitoring\styles.less 
* Используются основные обёртки - классы - ".ils-summary",
* ".ils-monitoring-details-top-layout",
* ".monitoring.editable-table"
* Все остальные классы находятся внутри этих обёрток.
*/

.ils-summary {
  background: @color-white;
  padding: 5px 15px;
  width: 100%;
  max-width: 500px;
  .flex-layout-horizontal(space-between, center, @gap-em);

  .item {
    width: fit-content;
    max-height: 45px;
  }

  div {
    cursor: pointer;
    .flex-justify-align(center, center);
    font-size: 24px;

    svg {
      width: 30px;
      height: 30px;
      margin-right: 5px;
    }
  }
}

.monitoring.editable-table {
  min-height: 350px;
}

.ant-modal.table-modal {
  .monitoring.editable-table {
    height: calc(85vh - 300px);
  }
}

.delivery-info {
  line-height: 20px;
}

.delivery-square {
  min-width: 20px;
  width: 20px;
  height: 20px;
  background-color: @color-normal;
  margin: 2px;
  display: block;
}

//"Нет данных" таблицы на всю ширину/посередине
.ils-monitoring-details-top-layout {
  .ils-monitoring-table-wrapper {
    .row {
      .ant-table-cell > .ant-empty {
        transform: translate(60%);
        width: 100%;
        min-width: 200px;
      }
    }
  }
}

.ils-monitoring-table-top-layout {
  .ant-table-wrapper {
    .row {
      .ant-table-cell > .ant-empty {
        transform: translate(47%);
        width: 100%;
        min-width: 42vw;
      }
    }
  }
}
// Более контрасная заливка для лучшей читаемости
.row.history-comment {
  background-color: rgba(0, 0, 0, 0.3);
  text-shadow: 0 0 2px black;
  box-shadow: 0 0 3px 8px #0004;
  color: white
}
