@import '@styles/default.less';

@tender-content-width: calc(100vw - @menu-width - (@padding-primary * 2));
@tender-column-width: 300px;
@tender-menu-width: 215px;
@tab-space-width: 7px;
@lot-cargo-column-width: 280px;
@table-header-height: 56px;
//
@tender-button-list-action-height: @button-icon-width;
@tender-tab-header-height: 46px;
@tender-tab-header-content-gap-height: 16px;
@tender-tab-content-height: calc(
  @main-height - @size-around-content - @tender-button-list-action-height - @gap-em * 2 - @tender-tab-header-height -
    @tender-tab-header-content-gap-height
);

// NOTE: 100px main menu width
@tender-card-width: calc(100vw - (@tender-menu-width + @gap-rem + @padding-primary + 100px));
@tender-card-height: calc(@wrapper-height - (@header-height + 35px + @gap-rem * 2 + @size-around-content));

//form-table
@form-table-header-height: 40px;

.counter() {
  width: fit-content;
  color: @color-primary;
  font-size: @fz-big;
  font-weight: @fw-500;
}

.tender-flow() {
  width: 100%;
  position: relative;
  padding: @size-around-content 0;
  display: grid;
  grid-template-areas:
    'title title role'
    'divider divider role'
    'menu content content';
  gap: @gap-em;
  min-width: 520px;
  box-sizing: border-box;
}

.tender-platform {
  .tender-flow();

  .tender-table-select-wrapper:not(th) {
    overflow: visible;
  }

  .tender-roles {
    position: absolute;
    top: 0;
    right: 0;
    z-index: @z-index-content-above;

    .ant-card-body {
      .reset-full();
      .flex-layout-vertical(start, start);
      padding: 0 @gap-primary;

      .roles {
        .reset-full();
        .flex-horizontal(@gap-primary);
      }
    }

    @media screen and (max-width: 1380px) {
      .ant-card-body {
        flex-direction: column;
      }
    }
  }

  .tender-platform-list .table-config,
  .ils-table {
    max-width: 100%;
  }

  .ant-form {
    max-width: @tender-card-width;
  }

  &-divider {
    grid-area: divider;
    width: 100%;
    max-width: @tender-card-width;
    min-width: @tender-card-width;

    &-title {
      grid-area: title;
      .margin-reset();
    }

    &.horizontal-title {
      .margin-reset();
    }
  }

  .menu-button {
    min-width: 100%;
    white-space: break-spaces;
    height: auto;

    &s {
      .flex-layout-horizontal(start, center, @gap-rem);

      .my-carriers {
        padding: 4px;
      }
    }
  }

  &-my-carriers {
    grid-area: menu;
    width: @tender-content-width;
  }

  &-list {
    &-content {
      grid-area: menu;
      width: @tender-content-width;

      &-tab-header {
        &-content {
          color: @color-primary;
        }
      }

      // for badge
      .ant-tabs-nav-list {
        gap: @tab-space-width * 2;
      }

      .ant-tabs-content,
      .ant-tabs-content-top,
      .ant-tabs-tabpane {
        .flex-layout-vertical(flex-start, flex-start, @no-gap);
        height: @tender-tab-content-height;

        .tender-platform-list-config {
          .ils-pagination.ant-pagination {
            margin: 0;
            padding: 10px;
          }

          .ant-tabs-content,
          .ant-tabs-content-top,
          .ant-tabs-tabpane {
            .flex-layout-vertical(flex-start, flex-start, @no-gap);
          }
        }
      }
    }
  }

  &-menu {
    grid-area: menu;
    box-sizing: border-box;
    min-width: 200px;
    width: @tender-menu-width;
    .flex-layout-vertical(flex-start, flex-start, 0);
    background-color: @bg-body;
    z-index: @z-index-fixed-menu;
    height: 100vh;

    &-navigation {
      width: @tender-menu-width;

      &-item {
        width: 180px;
        .flex-layout-horizontal();
        justify-content: space-between;

        &-title {
          margin: 0;
          color: @color-black;
        }

        .svg-container {
          width: 15px;
          height: 15px;
        }

        svg path {
          fill: @color-black;
        }
      }
    }

    &.menu-fixed {
      background-color: @bg-body;
      z-index: @z-index-fixed;

      .tender-platform-card-buttons {
        .react-customscroll {
          max-height: 95px !important;
        }
      }
    }

    &-item {
      width: 180px;
      .flex-layout-horizontal();
      justify-content: space-between;

      &-title {
        margin: 0;
        color: @color-black;
      }

      .svg-container {
        width: 15px;
        height: 15px;
      }

      svg path {
        fill: @color-black;
      }
    }
  }

  &-info-box {
    .flex-layout-vertical(center, center, @gap-em);
    transform: translateY(50vh);
  }

  &-card {
    grid-area: content;
    .flex-layout-vertical(space-between, flex-start, @gap-rem);
    padding-bottom: @gap-rem;
    overflow-y: scroll;
    height: @tender-card-height;
    width: 100%;
    max-width: 100%;

    .offers {
      .flex-layout-vertical(space-between, flex-start, @gap-rem);
      width: 100%;
    }

    .ant-spin-nested-loading {
      width: 100%;
    }

    &-item {
      max-width: 200px;
      width: 200px;
      background: transparent;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &-form {
      .flex-vertical(@gap-em);
      max-width: 100%;

      &-fieldset {
        .flex-horizontal(@gap-rem);

        &-tender {
          max-width: 100%;
          padding: @gap-rem;
          .flex-vertical(@gap-rem);
          .disabled-form-info();
          min-width: @tender-column-width;
          width: @tender-column-width;

          .description {
            resize: vertical;
            max-height: 400px;
          }

          .ant-form-item {
            margin: 0;
            width: 100%;
          }
        }

        &-lot {
          max-width: 100%;
          padding: @gap-rem;
          .flex-vertical(@gap-rem);
          .disabled-form-info();

          .tender-form-fieldset {
            .flex-layout-vertical(start, start, @gap-rem);
            max-width: 100%;
            padding: @gap-rem;
            .disabled-form-info();

            .ant-select-selection-search {
              min-width: max-content;
            }
            .ant-tabs {
              min-height: fit-content;
            }

            &-cargo {
              min-width: @lot-cargo-column-width;
            }

            &-item,
            .tender-from-planner-form-table-fieldset-row {
              min-width: @tender-column-width;
              width: 100%;

              &.Distance {
                width: fit-content;
              }
            }

            .block-item {
              min-width: @tender-column-width;
              .margin-reset();

              &.Cargo {
                .disabled-form-info();
                .fieldset();
                display: grid;
                grid-template-areas:
                  'RestrictionID RestrictionID RestrictionID '
                  'Mass Amount Amount'
                  'position position position '
                  'Length Height Width '
                  'Volume Volume Volume '
                  'Comment Comment Comment ';
                gap: @gap-em;
                min-width: 520px;
                box-sizing: border-box;

                .block-item {
                  .disabled-form-info();

                  &-RestrictionID {
                    grid-area: RestrictionID;
                  }

                  &-position {
                    grid-area: position;
                  }

                  &-Mass {
                    grid-area: Mass;
                  }

                  &-Height {
                    grid-area: Height;
                  }

                  &-Amount {
                    grid-area: Amount;
                  }

                  &-Length {
                    grid-area: Length;
                  }

                  &-Width {
                    grid-area: Width;
                  }

                  &-Volume {
                    grid-area: Volume;
                  }

                  &-Comment {
                    grid-area: Comment;
                  }
                }
              }
            }

            .ant-tabs-content {
              max-width: 50vw;
              gap: @gap-rem;
            }
          }
        }

        .ant-form-item {
          margin: 0;
          width: 100%;
        }
      }

      &-tender,
      &-auction,
      &-offer {
        .flex-vertical(@gap-em);
        width: @tender-column-width;
        .disabled-form-info();

        .ant-form-item {
          margin: 0;
          width: 100%;
        }
      }

      &-auction {
        width: min-content;
        min-width: 35%;
        max-width: @tender-column-width;

        &-buttons {
          .flex-horizontal(@gap-em);
        }

        .countdown {
          .flex-layout-horizontal(flex-start, center, @gap-primary);
          .counter();
        }

        .auction-info > .ant-card-body {
          .flex-horizontal(@gap-em);

          .ant-select-selector {
            border: none;
          }
        }
      }

      .lot-shipping-counter {
        display: block;
        background: @color-white;
        padding: 8px 16px;
        border: 1px solid @border-color-input;

        &.disabled {
          background: @bg-input-disabled-info;
        }
      }
    }

    &-buttons {
      .flex-vertical(@gap-em);
      width: @tender-menu-width;

      &-accept {
        width: fit-content;
      }
    }

    .ant-table-body,
    .ant-table-body > table {
      width: 100% !important;
    }

    .ant-statistic-content {
      font-size: 32px;
      color: @color-primary;
    }

    .ant-tabs {
      min-height: fit-content;
    }

    .ils-tender-form {
      .lot-menu {
        .ant-tabs-nav {
          .ant-tabs-tab {
            margin: 0;
            border: none;
            background: @color-white;

            &-active {
              border: 0.2px solid @border-color-primary;
            }
          }
        }
      }

      li.ant-menu-item {
        .svg-container svg path {
          fill: @color-black;
        }
      }

      li.ant-menu-item-selected {
        background: @color-primary !important;
        color: @color-white !important;

        .svg-container svg path {
          fill: @color-white;
        }
      }
    }

    .ant-col.ant-form-item-label {
      padding-bottom: 3px;
    }

    .ant-picker.ant-picker-range,
    .lot-radio {
      width: 100%;
    }

    .ant-form-item-control-input {
      min-height: 35px;
    }

    .ant-input,
    .ant-select .ant-select-selector,
    .ant-picker {
      border-color: @bg-component !important;
    }

    .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
      background: @bg-primary !important;
    }

    .ant-checkbox-disabled.ant-checkbox-checked span.ant-checkbox-inner {
      background: @color-primary !important;
    }

    .users {
      width: 500px;
    }

    .lot-radio {
      display: flex;

      label {
        width: 33.3%;
      }
    }

    label.ant-radio-button-wrapper {
      min-width: 150px;
      text-align: center;
    }

    li.ant-menu-item.template {
      margin-top: 30px;

      .svg-container {
        width: 16px;
        height: 18px;
      }
    }

    .template-list {
      height: fit-content;
      .flex-layout-vertical(flex-start, flex-start, @gap-rem);

      label.ant-radio-button-wrapper {
        width: fit-content;
        max-width: @tender-column-width;
      }
      .ant-upload-list-picture-card-container {
        width: 250px;
      }

      .ant-upload-list-item-actions {
        a {
          display: none;
        }
      }
    }
  }

  .tender-from-planner {
    &-form-table {
      height: 84vh;
      grid-area: content;
      overflow: scroll;
      .flex-layout-vertical(start, start, @gap-rem);

      display: flex;
      grid-gap: 1rem;
      gap: 1rem;
      justify-content: start;
      align-items: start;
      width: 100%;

      &-header {
        .flex-layout-horizontal(start, center);
        flex-wrap: nowrap;
        position: sticky;
        z-index: @z-index-context-menu;
        background-color: @bg-body;
        max-height: @form-table-header-height;
        top: 0;
        &-info {
          min-width: @tender-column-width;
        }
      }

      &-body {
        .flex-layout-vertical(start, start, @gap-rem);
        padding-top: 1rem;
      }

      &-row {
        .flex-layout-horizontal(center, start);
      }

      &-fieldset-row {
        .flex-layout-horizontal(center, start, @gap-primary);
        min-height: @form-table-header-height;
        min-width: @tender-column-width;

        &.description {
          resize: vertical;
          max-height: 400px;
        }

        .input {
          min-width: @tender-column-width;
          width: @tender-column-width;
        }

        &.head {
          justify-content: start;
        }

        &.required {
          border-color: @color-error;
        }

        &.Description {
          justify-content: flex-start;
          max-width: @tender-column-width;
          min-height: 52px;
          height: fit-content;
          max-height: 400px;
          overflow: scroll;
        }

        &.route,
        span.route {
          .flex-layout-vertical(start, start, @gap-primary);
        }

        span.route {
          max-width: @tender-column-width;
        }

        &-title {
          width: 230px;
        }
        &-shedule {
          display: flex;
          flex-direction: column;

          .shedual-options {
            max-width: 300px;
            margin-bottom: 10px;

            .ant-radio-button-wrapper-checked {
              background: @color-primary;
              color: #fff;
            }
          }
        }

        .ant-progress {
          width: 15px;

          &-outer {
            display: none;
          }
        }
      }

      &-filelist {
        max-width: @tender-column-width;
        justify-content: start;
        flex-wrap: wrap;
        cursor: pointer;
        padding: 10px 5px;

        p {
          max-width: 120px;
          max-height: 85px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          line-height: 1.1;
          font-weight: bold;
          color: #4c91ff;
        }

        &-empty {
          min-width: @tender-column-width;
          padding: 10px;
          text-align: center;
          color: #4c91ff;
          border: 1px dotted #4c91ff;
        }
      }
    }
  }
}

// TODO решение не очень, но popupClassName (className for more dropdown) – с версии	4.21.0
.ant-tabs-dropdown-menu {
  width: 200px;
  &-item {
    padding: 12px;
  }
}


@primary-color: #4C91FF;