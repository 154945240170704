@import '@styles/default.less';

.track-content {
  width: calc(100vw - 65px);
  height: calc(100vh - 30px);
  .leaflet-bar{
    display: none;
  }
  .map-buttons {
    right: 12px;
    position: absolute;
    top: 12px;
    z-index: 1000;
  }
}

@primary-color: #4C91FF;