@import '@styles/default.less';

.cell-wrapper {
  .reg-number {
    position: relative;
    margin: 0;

    .ant-form-item {
      margin: 0;
    }
  }
}
@primary-color: #4C91FF;