@import '@styles/default.less';

.leaflet-ruler {
  height: 35px;
  width: 35px;
  background-image: url('../../ruler.svg'); /* <div>Icons made by <a href="http://www.freepik.com" title="Freepik">Freepik</a> from <a href="http://www.flaticon.com" title="Flaticon">www.flaticon.com</a> is licensed by <a href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank">CC 3.0 BY</a></div> */
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25px 25px;
}

.leaflet-ruler:hover {
  /*background-image: url("../dist/icon-colored.png"); !* <div>Icons made by <a href="http://www.freepik.com" title="Freepik">Freepik</a> from <a href="http://www.flaticon.com" title="Flaticon">www.flaticon.com</a> is licensed by <a href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank">CC 3.0 BY</a></div> *!*/
}

.leaflet-control-container .leaflet-ruler-clicked {
  height: 35px;
  width: 35px;
  background-repeat: no-repeat;
  background-position: center;
  /*background-image: url("../dist/icon-colored.png");*/
  border-color: @color-primary !important;
}

.leaflet-bar {
  background-color: #ffffff;
}

.leaflet-control {
  cursor: pointer;
}

.result-tooltip {
  font-size: 14px;
  background-color: white;
  border: 1px solid @color-normal;
}

.moving-tooltip {
  background-color: rgba(255, 255, 255, 0.7);
  background-clip: padding-box;
  opacity: 0.5;
  border: 1px solid @color-normal;
  font-size: 14px;
}

.plus-length {
  color: @color-darkgrey;
}
