@import '@styles/default.less';

@select-arrow-size: 12px;

.ant-tag.select-multiple-tag {
  margin-left: @select-arrow-size;
  background: transparent;
  border: none;
  font-size: @fz-primary;

  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@primary-color: #4C91FF;